import React, {Component} from "react";
import { Container } from "shards-react";
import {connect} from 'react-redux';
import TableGrid from "../../table-grid/TableGrid";
import * as actionCreators from '../../../store/actions/index';

class Lowstock extends Component {

	render() {	
		
			return (
				<Container fluid className="main-content-container detail-content px-4">
					<div className="detail-title">
						Low Stock Products
					</div>	
					<TableGrid 
						table_name="stock_sku" 
						key_column="sku"
						table_title="Low Stock Products" 
						detail_title="Low Stock Products details" 
						table_subtitle="Low Stock Products items" 
						table_columns={
							{
								sku: 'SKU',
								sku_description: 'Product'
							}
						} 
						no_title
						order_columns={true} 
						column_filters={true} 
						action_columns={["V"]} 
						allow_create_new={false} 
						csv_export={true}
						user_mandants={this.props.user_mandants}
						per_page={30} 
						table_theme="bg-dark" 
					/>
				</Container>
			)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lowstock);
