import React, {Component} from "react";
import { Container, Row} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class ShippingIssues extends Component {
	
	render() {	
		
			return (
				<Container fluid className="main-content-container inventory-page">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Shipping Issues" subtitle="Shipping Issue" />
					</Row>
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							<TableGrid 
								table_name="tracking_issues" 
								key_column="delivery_id"
								table_title="tracking_issues" 
								detail_title="tracking_issues" 
								table_subtitle="tracking_issues" 
								table_columns={
									{ 
										delivery_id: 'Delivery ID',
                                        delivery_created_at: 'Delivery created at',
                                        ship_date: 'Ship date',
                                        shipper: 'Shipper',
                                        country: 'Country',
										states: 'States',
									}
								} 
                                special_filters={
									[
										{
											color: '#3bc265',
											textColor: '#000',
											title: 'No activity at mh',
											column: 'states',
											value: 'no-activity-at-mh'
										},
										{
											color: '#f1cb31',
											textColor: '#000',
											title: 'No activity at shipper',
											column: 'states',
											value: 'no-activity-at-shipper'
										},
										{
											color: '#ff575b',
											textColor: '#000',
											title: 'Not delivered by shipper',
											column: 'states',
											value: 'not-delivered-by-shipper'
										}
									]
								}
								order_column="delivery_created_at"
								order_dir="desc"
								no_title
								order_columns={true} 
								column_filters={true} 
								action_columns={[]} 
								csv_export={true}
								allow_create_new={false} 
								user_mandants={this.props.user_mandants}
								per_page={30} 
								table_theme="bg-dark" 
							/>
						</Container>
					</Row>
					
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingIssues);