import React, {Component} from "react";
import { Container, Row } from "shards-react";

import {connect} from 'react-redux';
import * as actionCreators from '../../../../../store/actions/index';

import Chart from "chart.js";
import { set } from "react-ga";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class Sales extends Component {

    state = {
		sales_data: null,
		fetchingsSalesData: true,
		selected_year: new Date().getFullYear(),
		current_year: new Date().getFullYear(),
		display: 'table'
	}

	salesDataChart = React.createRef();


    componentDidMount() {
		/* Charts configuration */
        const salesDataChart = this.salesDataChart.current.getContext("2d");
		let mapChartDataColors = ["#1b4f72","#CD5C5C","#40E0D0","#a9cce3","#CCCCFF","#008000","#808080"];		
		/* Self object */
		let self = this;
        /* Return monthly overview */
		axios.post(apiUrl + '/itemSales', {
			user_mandants: this.props.user_mandants,
			item_id: this.props.id,
			selected_year: this.state.selected_year
		}).then(function (response) {
			self.setState({sales_data: response.data, fetchingsSalesData:false});
			
			let setsData = [];
			Object.entries(response.data.sales_item).map(([key,value],i) =>
			setsData.push(
				{
					label: "Sales per months - " + key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColors[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			)

			new Chart(salesDataChart, {
				type: "bar",
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'],
					datasets: setsData
				},
				options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    },
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	handleChangeDisplayData(display) {
		this.setState({display: display})
	}

    render() {
        return(
			   <div className="page-detail-block">
					<Row className="row-title">
						<Container className="col-sm-12 title-icons-block">
							<h5>Sales</h5>
						</Container>
					</Row>
					<div className="detail-table-changer-block">
							<div className={this.state.display ==='table' ? "show-icons show-table" : 'show-icons show-chart'}>
								<i onClick={() => this.handleChangeDisplayData('table')} className="material-icons table-show">table_chart</i>
								<i onClick={() => this.handleChangeDisplayData('chart')} className="material-icons chart-show">bar_chart</i>
							</div>
					<Row className={this.state.display ==='table' ? "show-display" : 'not-show-display'}>
						{((this.state.fetchingsSalesData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
							<Container className="detail-info-table">
									<Row>
									{this.state.sales_data ? 
										(Object.keys(this.state.sales_data.sales_item).length !== 0 ? 
										<Container className="col-sm-12">
											<div className="dashboard-totals-content table-with-sum">
												<table>
													<thead>
														<tr>
															<th>Year</th>
															<th>January</th>
															<th>February</th>
															<th>March</th>
															<th>April</th>
															<th>May</th>
															<th>June</th>
															<th>July</th>
															<th>August</th>
															<th>September</th>
															<th>October</th>
															<th>November</th>
															<th>December</th>
															<th>Sum</th>
														</tr>
													</thead>
													<tbody>
													{this.state.sales_data ? 
														
														Object.entries(this.state.sales_data.sales_item).map(([key,value],i) =>
														<tr>
															<td>{key}</td>
															{Object.entries(value).map(([key,value],i) =>
																<td key={key}>{value}</td>
																)}
																{Object.entries(this.state.sales_data.sales_sum_year).map(([keySum,valueSum],i) =>
																	(key === keySum ? <td key={key}>{valueSum['count']}</td> : null )
																)}
														</tr>
														)  
														: null }
													</tbody>
												</table>
											</div>
										</Container>
										: 'No data') 
									: null}
									</Row>
							</Container> 
						)}
					</Row>
					</div>
					<Row className={this.state.display ==='chart' ? "show-display" : 'not-show-display'}>
						<Container className="col-sm-10">
							{((this.state.fetchingsSalesData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
							<Container id="chart_stockturnover" className="col-sm-12 px-12" style={{marginLeft: 0}}>
								<canvas height="120" ref={this.salesDataChart} style={{ maxWidth: "100% !important" }} />
							</Container>
						</Container>
					</Row>
			   </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);