import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Order extends Component {

	state = {
		loading_data: false,
		detail_data: null,
		detail_id: this.props.id,
		detail_history_data: null,
	}
	
	componentDidMount() {
		// Load data
		if(this.state.detail_data === null) {	
			let self = this;
			axios.post(apiUrl + '/loadOrderData', {
				table_name: 'order_data',
				order_id: this.state.detail_id
			}).then(function (response) {
				self.setState({detail_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
		if(this.state.detail_history_data === null) {	
			let self = this;
			axios.post(apiUrl + '/loadOrderDataHistory', {
				order_id: this.state.detail_id
			}).then(function (response) {
				self.setState({detail_history_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}
	
	render() {	
		if(this.state.detail_data === null) {
			
			return (
				<Card small>
					<CardBody>
						<table id={this.props.table_name} className="table mb-0">
							<tbody>	
								<Container fluid className="main-content-container px-4">
								Loading...
								</Container>
							</tbody>
						</table>
					</CardBody>
				</Card>
			)
			
		} else {
			
			// Create order items output
			let items = this.state.detail_data.order_items.map((row, index) => {
				return (
					<tr>
						<td>
							{row.name}
						</td>
						<td>
							{row.sku}
						</td>
						<td>
							{row.qty}
						</td>
						<td>
							{row.item_price + " " + this.state.detail_data.order_currency}
						</td>
					</tr>
				);
			});
			if(this.state.detail_data.order_id) {
				var orderId = this.state.detail_data.order_id;
				if(orderId.includes('_')) {
					var order = orderId.split("_"); 
					orderId = order[1];
				}
			}
			return (
				<div>
					<Card small>
						<CardBody>
							<table id={this.props.table_name} className="table mb-0">
								<tbody>		
									<Container fluid className="main-content-container detail-content px-4">
										
										<div className="detail-title">
											Order # {orderId}
										</div>		
											
										<div className="detail-table">
											<table className="detail-table-primary" width="49%">
												<tbody>
													<tr>
														<th>Order Date</th>
														<td>{this.state.detail_data.order_date_created}</td>
													</tr>
													<tr>
														<th>Order Status</th>
														<td>{this.state.detail_data.status}</td>
													</tr>
													<tr>
														<th>Purchased From</th>
														<td>{this.state.detail_data.location}</td>
													</tr>
													<tr>
														<th>Company</th>
														<td>{this.state.detail_data.company}</td>
													</tr>
												</tbody>
											</table>
											<table className="detail-table-secondary" width="49%">
												<tbody>
													<tr>
														<th>Order Amount</th>
														<td>{this.state.detail_data.order_amount_with_taxes_order_currency + " " + this.state.detail_data.order_currency}</td>
													</tr>
													<tr>
														<th>Purchased From</th>
														<td>{this.state.detail_data.location}</td>
													</tr>
													<tr>
														<th>Tracking ID</th>
														<td>{this.state.detail_data.tracking_id}</td>
													</tr>
													<tr>
														<th>Tracking URL</th>
														<td><a href={this.state.detail_data.tracking_url} target="_blank">{this.state.detail_data.tracking_url}</a></td>
													</tr>
												</tbody>
											</table>
										</div>
											
										<div className="items-title">
											Ordered Items
										</div>		
										
										<div className="items-table">
											<table className="item-table-secondary" width="100%">
												<thead>
													<tr>
														<th>Product</th>
														<th>SKU</th>
														<th>Qty</th>
														<th>Price</th>
													</tr>
												</thead>
												<tbody>
													{items}
												</tbody>
											</table>
										</div>
										
										
									</Container>
								</tbody>
							</table>
						</CardBody>
					</Card>
					{this.state.detail_history_data === null ? 
						<Card className="mt-4">
								<CardBody>
									<table className="table mb-0">
										<tbody>	
											<Container fluid className="main-content-container px-4">
											Loading...
											</Container>
										</tbody>
									</table>
								</CardBody>
							</Card>
							:
					<Card className="mt-4">
						<CardBody>
							<table className="table mb-0">
								<tbody>		
									<Container fluid className="main-content-container detail-content px-4">
										<div className="items-title">
										Delivery status history
										</div>	
										<div className="row-info-history">
											{this.state.detail_history_data.provided_via_interface !== '' ? <span class="material-icons">task_alt</span> : ''}
											<span className="row-info-history-title">Provided via Interface</span>
											<span>{this.state.detail_history_data.provided_via_interface}</span>
										</div>
										<div className="row-info-history">
											{this.state.detail_history_data.imported_in_x3 !== '' ? <span class="material-icons">task_alt</span> : ''}
											<span className="row-info-history-title">Deliveries in X3</span>
											<span>{this.state.detail_history_data.imported_in_x3}</span>
										</div>
										<div className="row-info-history">
											{this.state.detail_history_data.label_generation !== '' && this.state.detail_history_data.label_generation !== 'Label could not be generated, please try to correct label' ? <span class="material-icons">task_alt</span> : (this.state.detail_history_data.label_generation === 'Label could not be generated, please try to correct label') ? <span class="material-icons">block</span> : ''}
											<span className="row-info-history-title">Label Generation</span>
											<span>{this.state.detail_history_data.label_generation}</span>
										</div>
										<div className="row-info-history">
											{this.state.detail_history_data.warehouse !== '' && this.state.detail_history_data.warehouse !== 'Order has not yet been provided to warehouse' ? <span class="material-icons">task_alt</span> : (this.state.detail_history_data.warehouse == 'Order has not yet been provided to warehouse') ? <span class="material-icons">warning_amber</span> : ''}
											<span className="row-info-history-title">Warehouse</span>
											<span>{this.state.detail_history_data.warehouse}</span>
										</div>
										<div className="row-info-history">
											{(this.state.detail_history_data.dispatched === '' || this.state.detail_history_data.dispatched === 'Order has not yet been dispatch') ? <span class="material-icons">warning_amber</span> : <span class="material-icons">task_alt</span>}
											<span className="row-info-history-title">Dispatched</span>
											<span>{this.state.detail_history_data.dispatched}</span>
										</div>
									</Container>
								</tbody>
							</table>
						</CardBody>
					</Card>
				}
				</div>
			)
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
