import React, {Component} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Alert } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";

class Logout extends Component {
	
	componentDidMount() {
		this.props.logout({});
	}
	
	render() {		
	
        return (
			((this.props.user_id === 0) ?
				<Redirect to="/login" />
			: 
				<Container fluid className="main-content-container px-12">
									
					<Row>
						<Col lg="12" md="12" sm="12" className="mb-12">
							&nbsp;
						</Col>
						<Col lg="12" md="12" sm="12" className="mb-12">
							<Alert theme="success">
								You have been logout from dashboard
							</Alert>
						</Col>  
					</Row>
					
				</Container>
			)
		);
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);