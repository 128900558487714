import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import * as actionCreators from '../../../store/actions/index';
import {connect} from 'react-redux';
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "shards-react";

class SidebarNavItem extends React.Component {
	
	state = {
		update: false
	}
	
	constructor(props) {
		super(props);
		this.toggleDropdown = this.toggleDropdown.bind(this);		
	}

	toggleDropdown(item) {
		this.props.toggleSubmenu(item);
		this.setState({update: !this.state.update});
	}

	render() {
		const { item } = this.props;
		const hasSubItems = item.items && item.items.length;
		let user_rights = this.props.user_data.user_rights;
		let showClass = ((item.open) ? 'show' : '');
		
		/* Check current URL because of keeping open submenu */
		let url = window.location.pathname;

		if(((url === '/neworder' || url === '/returns' || url === '/orders' || url === '/backorders'|| url === '/open-shipments' || url === '/deliveries' || url === '/address-check') && item.id === 1) || ((url === '/receive-goods' || url === '/inventory' || url === '/bestsellers' || url === '/incoming-goods' || url === '/stock-movements' || url === '/batch') && item.id === 2) || ((url === '/service-quality' || url === '/service-carrier' || url === '/service-returns' || url === '/revenue-report' || url === '/orderserror' || url === '/shippingissues' || url === '/skurevenuereport' || url == '/itemsbasketreport') && item.id === 7)) {
			showClass = 'show';
		}
		
		return (
			<div>
				{((user_rights.includes(item.perm) || item.perm == '0') ?
					<NavItem style={{ position: "relative" }}>
						<NavLink className={hasSubItems && "dropdown-toggle"} tag={hasSubItems ? "a" : RouteNavLink} to={hasSubItems ? "#" : item.to} onClick={() => this.toggleDropdown(item)}>
							{item.htmlBefore && (
								<div className="d-inline-block item-icon-wrapper" dangerouslySetInnerHTML={{ __html: item.htmlBefore }} />
							)}
						  
							{item.title && <span>{item.title}</span>}
						  
							{item.htmlAfter && (
								<div className="d-inline-block item-icon-wrapper" dangerouslySetInnerHTML={{ __html: item.htmlAfter }} />
							)}
						</NavLink>
						
						{hasSubItems && (
							<Collapse className={showClass} tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
								{item.items.map((subItem, idx) => (
									user_rights.includes(subItem.perm) || item.perm == '0' ? 
									<DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
										{subItem.title}
									</DropdownItem>
									: null
								))}
						  </Collapse>
						)}
					</NavItem>
				: null)}
			</div>
		);
	}
	
}

const mapStateToProps = state => {
    return {
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

SidebarNavItem.propTypes = {
  item: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItem);
