import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Items extends Component {
	
	render() {	
		return (
			<Container fluid className="main-content-container px-4">
				
				<TableGrid 
					table_name="t_item_data_grid" 
					key_column="itmref"
					table_title="Items" 
					detail_title="Item data" 
					table_subtitle="Items data" 
					table_columns={
						{
							itmref: 'Item Reference',
							zsku: 'SKU',
							description: 'Name',
							eancod: 'EAN',
							tsicod: 'TSICOD'
						}
					} 
					order_columns={true} 
					column_filters={true} 
					action_columns={["V"]} 
					csv_export={true}
					allow_create_new={false} 
					user_mandants={this.props.user_mandants}
					per_page={30} 
					table_theme="bg-dark" 
				/>
				
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
