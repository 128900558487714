import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// CMS Components
import Login from "./components/login/Login";
import AboutProject from "./components/cms-pages/about-project/AboutProject";
import Services from "./components/cms-pages/services/Services";
import ForgotPassword from "./components/cms-pages/forgot-password/ForgotPassword";
import Contact from "./components/cms-pages/contact/Contact";
import Logout from "./components/cms-pages/logout/Logout";

// Dashboard components
import Buyings from "./components/buyings/Buyings";
import Returns from "./components/returns/Returns";
import Backorders from "./components/backorders/Backorders";
import Orders from "./components/orders/Orders";
import OpenShipments from "./components/open-shipments/OpenShipments";
import Items from "./components/items/Items";
import Bestsellers from "./components/bestsellers/Bestsellers";
import Stock from "./components/stock/Stock";
import Users from "./components/users/Users";
import Roles from "./components/roles/Roles";
import Dashboard from "./components/dashboard/Dashboard";
import Settings from "./components/settings/Settings";
import Profile from "./components/profile/Profile";
import Inventory from "./components/inventory/Inventory";
import ReturnForm from "./components/return-form/ReturnForm";
import NewOrder from "./components/orders/NewOrder";
import ReceiveGoods from "./components/buyings/ReceiveGoods";
import Batch from "./components/inventory/Batch";
import ServiceQuality from "./components/service-quality/ServiceQuality";
import ServiceCarrier from "./components/service-carrier/ServiceCarrier";
import Deliveries from "./components/deliveries/Deliveries";
import Addresscheck from "./components/address-check/Addresscheck";
import ServiceReturns from "./components/service-returns/ServiceReturns";
import RevenueReport from "./components/revenue-report/RevenueReport";
import Timeofgoodsrecepeipt from "./components/timeofgoodsreceipt/Timeofgoodsreceipt";
import AvgPositionqtyPerorder from "./components/avg-positionqty-perorder/AvgPositionqtyPerorder";
import OrdersError from "./components/orders/OrdersError";
import ShippingIssues from "./components/shipping-issues/ShippingIssues";
import SkuRevenueReport from "./components/items/SkuRevenueReport";
import ItemsBasketReport from "./components/items/ItemsBasketReport";
import Errors from "./components/errors/Errors";

export default [
  {
    path: "/",
    exact: true,
    layout: LoginLayout,
    component: () => <Redirect to="/login" />
  },  
  {
    path: "/login",
    layout: LoginLayout,
    component: Login
  },
  {
    path: "/logout",
    layout: LoginLayout,
    component: Logout
  },
  {
    path: "/about-project",
    layout: LoginLayout,
    component: AboutProject
  },  
  {
    path: "/services",
    layout: LoginLayout,
    component: Services
  },  
  {
    path: "/contact",
    layout: LoginLayout,
    component: Contact
  },  
  {
    path: "/forgot-password",
    layout: LoginLayout,
    component: ForgotPassword
  },  
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/items",
    layout: DefaultLayout,
    component: Items
  },
  {
    path: "/inventory",
    layout: DefaultLayout,
    component: Inventory
  },
  {
    path: "/stock-movements",
    layout: DefaultLayout,
    component: Stock
  },
  {
    path: "/bestsellers",
    layout: DefaultLayout,
    component: Bestsellers
  },
  {
    path: "/orders",
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/open-shipments",
    layout: DefaultLayout,
    component: OpenShipments
  },
  {
    path: "/incoming-goods",
    layout: DefaultLayout,
    component: Buyings
  },
  {
    path: "/return-form",
    layout: DefaultLayout,
    component: ReturnForm
  },
  {
    path: "/returns",
    layout: DefaultLayout,
    component: Returns
  },
  {
    path: "/backorders",
    layout: DefaultLayout,
    component: Backorders
  },
  {
    path: "/settings",
    layout: DefaultLayout,
    component: Settings
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/roles",
    layout: DefaultLayout,
    component: Roles
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/neworder",
    layout: DefaultLayout,
    component: NewOrder
  },
  {
    path: "/receive-goods",
    layout: DefaultLayout,
    component: ReceiveGoods
  },
  {
    path: "/batch",
    layout: DefaultLayout,
    component: Batch
  },
  {
    path: "/service-quality",
    layout: DefaultLayout,
    component: ServiceQuality
  },
  {
    path: "/service-carrier",
    layout: DefaultLayout,
    component: ServiceCarrier
  },
  {
    path: "/deliveries",
    layout: DefaultLayout,
    component: Deliveries
  },
  {
    path: "/address-check",
    layout: DefaultLayout,
    component: Addresscheck
  },
  {
    path: "/service-returns",
    layout: DefaultLayout,
    component: ServiceReturns
  },
  {
    path: "/revenue-report",
    layout: DefaultLayout,
    component: RevenueReport
  },
  {
    path: "/timeofgoodsrecepeipt",
    layout: DefaultLayout,
    component: Timeofgoodsrecepeipt
  },
  {
    path: "/avgpositionqtyperorder",
    layout: DefaultLayout,
    component: AvgPositionqtyPerorder
  },
  {
    path: "/orderserror",
    layout: DefaultLayout,
    component: OrdersError
  },
  {
    path: "/shippingissues",
    layout: DefaultLayout,
    component: ShippingIssues
  },
  {
    path: "/skurevenuereport",
    layout: DefaultLayout,
    component: SkuRevenueReport
  },
  {
    path: "/itemsbasketreport",
    layout: DefaultLayout,
    component: ItemsBasketReport
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
];
