import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
const axios = require("axios");

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class RolesRights extends Component {
	
	state = {
		selected_rights: null
	}
	
	componentDidMount() {
		if(this.state.selected_rights === null) {
			let self = this;
			axios.post(apiUrl + '/loadRolesRights', {
				table_name: 't_dashboard_rights',
				id_role: this.props.id
			}).then(function (response) {
				self.setState({selected_rights: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}
	
	render() {
		
		let selectedRigths = null;
		
		if(this.state.selected_rights !== null) {
			selectedRigths = this.state.selected_rights.map((row, index) => {				
				return (
					<tr className="table-grid-data">
						<td colspan="2">
							<span>{row.name}</span>
						</td>
					</tr>
				)
			});
		}
		
		return (
			<tfoot>
				<tr>
					<td>
						<br />
						<h5>Associate rights</h5>																
					</td>
				</tr>
				{selectedRigths}
			</tfoot>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesRights);
