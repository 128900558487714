import React, { Component } from "react";
import { Nav, NavItem, NavLink,Collapse,DropdownMenu,DropdownItem } from "shards-react";
import { NavLink as RouteNavLink } from "react-router-dom";
import {connect} from 'react-redux';
import SidebarNavItem from "./SidebarNavItem";
import * as actionCreators from '../../../store/actions/index';

class SidebarNavItems extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
				menuItems: [
					{
						title: 'Menu',
						items: [
							{
								id: 0,
								perm: 1,
								title: 'Dashboard',
								to: '/dashboard',
								htmlBefore: '<i class="material-icons">&#xE8D1;</i>',
								htmlAfter: '',
							},
							{
								id: 1,
								perm: 2,
								title: 'Orders',
								htmlBefore: '<i class="material-icons">receipt</i>',
								htmlAfter: '',
								open: false,
								items: [
									{
										title: 'Deliveries',
										to: '/deliveries',
										perm: 2,
									},
									{
										title: 'Orders',
										to: '/orders',
										perm: 13,
									}, 
									// {
									// 	title: 'Open Orders',
									// 	to: '/open-shipments',
									// 	perm: 13,
									// }, 
									{
										title: 'Returns',
										to: '/returns',
										perm: 3,
									}, 
									{
										title: 'Backorders',
										to: '/backorders',
										perm: 4,
									},
									{
										title: 'New Order',
										to: '/neworder',
										perm: 14,
									},
								]
							},
							{
								id: 2,
								perm: 6,
								title: 'Products',
								htmlBefore: '<i class="material-icons">local_convenience_store</i>',
								htmlAfter: '',
								open: false,
								items: [{
										title: 'Inventory',
										to: '/inventory',
										perm: 7,
									}, 
									{
										title: 'Bestsellers',
										to: '/bestsellers',
										perm: 10,
									}, 
									{
										title: 'Incoming Goods',
										to: '/incoming-goods',
										perm: 5,
									}, 
									{
										title: 'Stock Movements',
										to: '/stock-movements',
										perm: 11,
									},
									{
										title: 'Receive goods',
										to: '/receive-goods',
										perm: 15,
									},
									{
										title: 'Batch overview',
										to: '/batch',
										perm: 16,
									}
								]
							},
							{
								id: 7,
								perm: 18,
								title: 'Reports',
								htmlBefore: '<i class="material-icons">analytics</i>',
								htmlAfter: '',
								open: false,
								items: [{
									title: 'Service Quality',
									to: '/service-quality',
									perm: 19,
									},
									{
										title: 'Service Carrier',
										to: '/service-carrier',
										perm: 20,
									}
									,
									{
										title: 'Service Returns',
										to: '/service-returns',
										perm: 23,
									},
									{
										title: 'Revenue report',
										to: '/revenue-report',
										perm: 24,
									},
									{
										title: 'Time of goods receipt',
										to: '/timeofgoodsrecepeipt',
										perm: 25,
									},
									{
										title: 'Orders Errors',
										to: '/orderserror',
										perm: 27,
									},
									{
										title: 'Shipping issues',
										to: '/shippingissues',
										perm: 28,
									},
									{
										title: 'SKU revenue report',
										to: '/skurevenuereport',
										perm: 29,
									},
									// {
									// 	title: 'Items Basket',
									// 	to: '/itemsbasketreport',
									// 	perm: 30,
									// }
									// {
									// 	title: 'Average Positions & qty per order',
									// 	to: '/avgpositionqtyperorder',
									// 	perm: 26,
									// }
								]
							},
							{
								id: 8,
								perm: 31,
								title: 'Errors',
								to: '/errors',
								htmlBefore: '<i class="material-icons">error</i>',
								htmlAfter: '',
							},
							{
								id: 6,
								perm: 12,
								title: 'Return Form',
								to: '/return-form',
								htmlBefore: '<i class="material-icons">assignment_return</i>',
								htmlAfter: '',
							},
							{
								id: 3,
								perm: 8,
								title: 'Users',
								to: '/users',
								htmlBefore: '<i class="material-icons">supervisor_account</i>',
								htmlAfter: '',
							},
							{
								id: 4,
								perm: 9,
								title: 'Roles',
								to: '/roles',
								htmlBefore: '<i class="material-icons">policy</i>',
								htmlAfter: '',
							},
							{
								id: 5,
								perm: 0,
								title: 'Logout',
								to: '/logout',
								htmlBefore: '<i class="material-icons">logout</i>',
								htmlAfter: '',
							}
						],
					}
				]
		};
		this.toggleSubmenu = this.toggleSubmenu.bind(this)		
	}
	
	toggleSubmenu(item) {
		let menuItemId = item.id;
		let menuItems = this.state.menuItems;
		
		for(let i = 0; i < menuItems[0].items.length; i++) {
			if(menuItems[0].items[i].id === menuItemId) {
				menuItemId = i;
				break;
			}
		}
		let menuItem = this.state.menuItems[0].items[menuItemId];
		let openStatus = menuItem.open;
		let menuCopy = this.state.menuItems;
		menuCopy[0].items[menuItemId].open = !openStatus;
		this.setState({menuItems: menuCopy});
	}
	
	render() {		
		return (
			<div className="nav-wrapper">
				{this.state.menuItems.map((nav, idx) => (
					
					<div key={idx}>
						<h6 className="main-sidebar__nav-title">{nav.title}</h6>
						{typeof nav.items !== "undefined" && nav.items.length && (
							<Nav className="nav--no-borders flex-column">
								{nav.items.map((item, idx) => (
									<SidebarNavItem toggleSubmenu={this.toggleSubmenu} key={idx} item={item} />
								))}
							</Nav>
						)}
					</div>
				))}
				
			</div>
		)
	}
}

const mapStateToProps = state => {
    return {
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);