import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import PageTitle from "../common/PageTitle";
import TableGrid from "../table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Chart from "chart.js";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class Timeofgoodsreceipt extends Component {
	
    state = {
        timeofgoods_data: null, 
		fetchingsTimeofgoodsData:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
	}

    timeOfGoodsReceiptDataChart = React.createRef();

    componentDidMount() {
        /* Self object */
        const timeOfGoodsReceiptDataChart = this.timeOfGoodsReceiptDataChart.current.getContext("2d");
		let self = this;
        axios.post(apiUrl + '/timeOfGoodsReceipt', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({timeofgoods_data: response.data, fetchingsTimeofgoodsData:false});
            new Chart(timeOfGoodsReceiptDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: [
						{
							label: "Time of goods receipt in hours",
							fill: "start",
							data: response.data.chart_data,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

	render() {	
		
			return (
				<Container fluid className="main-content-container px-4 time-of-goods-report">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Time of goods receipt" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                                <CardBody>
                                    <Row noGutters className="page-header pt-4 pb-0 px-2">
                                        
                                    </Row>
                                        <Row>
									        <Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block service-quality-block-page">
										        <Row>
                                                    <Container className="col-sm-9 px-2 service-quality-block-page" style={{marginLeft: 0}}>
                                                        {((this.state.fetchingsTimeofgoodsData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
                                                        <Container id="chart-orders" className="col-sm-12 px-12" style={{marginLeft: 0}}>
                                                            <canvas height="120" ref={this.timeOfGoodsReceiptDataChart} style={{ maxWidth: "100% !important" }} />
                                                        </Container>
                                                    </Container>
                                                </Row>
                                            </Container>
                                        </Row>
                                </CardBody>
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeofgoodsreceipt);