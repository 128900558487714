import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import Chart from "chart.js";
import Backordersskus from './Backordersskus';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Backorders extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		backorders_data: null,
		show_detail_skus: false,
		user_data: this.props.user_data ? this.props.user_data.user_data.id_role : 0,
	}

	/* Reasons Pie Chart */
    reasonsPieChart = React.createRef();
	
	componentDidMount() {
		/* Charts configuration */
		Chart.defaults.global.legend.display = true;
		const reasonsPieChart = this.reasonsPieChart.current.getContext("2d");
		
		let self = this;
		axios.post(apiUrl + '/loadBackordersHeaderData', {
			user_mandants: self.state.user_mandants,
			id_role: self.state.user_data
		}).then(function (response) {			
			self.setState({backorders_data: response.data});
			let mapChartDataColors = ["#1b4f72","#1a5276","#1f618d","#2471a3","#2980b9","#5499c7","#7fb3d5","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3"];		
			let chartData = [];
			let chartLabels = [];
			
			response.data.backordered_reason.forEach(function (arrayItem) {
				chartData.push(arrayItem.count);
				chartLabels.push(arrayItem.label);
			});

			new Chart(reasonsPieChart, {
				type: "doughnut",
				data: {
					datasets: [{
						data: chartData,
						backgroundColor: [
							'#007BFF',
							'#00B8D8',
							'#FFB400',
							'#C4183C',
							'#212529',
							'#E9ECEF',
							'#5A6169'
						 ]
					}],
					labels: chartLabels
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 0,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  }
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	showDetailSkus = (type) => {
		this.setState({show_detail_skus: true});
	}
	hideDetailSkus = (type) => {
		this.setState({show_detail_skus: false});
	}
	
	render() {	
		let topReturned = '';
		let topReasons = '';
		let totalBackorderedQty = '';
		
		if(this.state.backorders_data !== null) {		
			totalBackorderedQty = this.state.backorders_data.total_backordered_qty;
			
			topReturned = this.state.backorders_data.backordered_items.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
			
			let topReasons = this.state.backorders_data.backordered_reason.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.label}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
		}
						
		return (
			<div>
			{((this.state.show_detail_skus) ? 
				<div className={this.state.show_detail_skus ? "table-grid-detail" : "table-grid-detail no-display" }>
						<div className="table-grid-detail-actions">
							<a href="javascript://" onClick={() => this.hideDetailSkus()}><i className="material-icons">clear</i></a>
						</div>
						<Row>
							<Col>
								<Card small>
									<CardBody>
										<Backordersskus />
									</CardBody>
								</Card>
							</Col>
						</Row>
						<div className="table-grid-detail-footer">
							<a href="#" onClick={() => this.hideDetailSkus()}>&larr; Back to list</a>
						</div>
				</div>
		: null )}
			<Container fluid className={this.state.show_detail_skus ? "main-content-container inventory-page no-display" : "main-content-container inventory-page" }>
					
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Backorders" subtitle="Backordered Orders" />
				</Row>
					
				<Row noGutters className="inventory-header">
					<Container fluid className="col-sm-12 px-2">
						<Row noGutters className="inventory-header-block">
							<Container fluid className="col-sm-2 px-2 no-display">										
								<h4>Backordered Quantity:</h4>
								<Row noGutters className="total-inventory-value py-2">
									<Button outline theme="warning">
										{totalBackorderedQty}
									</Button>
								</Row>
							</Container>
							<Container fluid className="col-sm-5 px-2">
								<Row noGutters>
									<Container fluid className="col-sm-12 top-title-link-backorders">
										<h4 className="col-sm-6 title-top-items-backorders">Top Backordered Items:</h4>
										<span onClick={() => this.showDetailSkus() } className="col-sm-6 show-all-items">Show all items</span>
									</Container>
								</Row>
								<Row noGutters className="top-5-products">
									{topReturned}
								</Row>
							</Container>
							<Container fluid className="col-sm-5 px-2">
								<Row noGutters>
									<Container fluid className="col-sm-12">
										<h4>Top Backordered Reasons:</h4>
									</Container>
								</Row>
								<Row noGutters className="top-5-products top-reasons-chart">
									<canvas	height="120" id="reasonsPieChart" ref={this.reasonsPieChart} />
								</Row>
							</Container>
						</Row>
					</Container>
				</Row>
				<Row noGutters className="inventory-header py-3 px-2">
					<Container fluid className="col-sm-12">
						<TableGrid 
							table_name="sperr_status_grid_new" 
							key_column="order_id"
							table_title="Backorders" 
							detail_title="Backorder detail" 
							table_subtitle="Backorders items" 
							table_columns={
								{
									order_id: 'Order ID',
									order_zsperrsta_info: 'Info',
									skus_on_backorders: 'Skus',
									order_date_created: 'Created At',
									delivery_adr_customer_name: 'Customer name',
									has_history_and_comment: 'Note',
									note_on_backorder: 'Note on backorder',
									action_comment: 'Customer comment'
								}
							} 
							order_column="order_date_created"
							order_dir="asc"
							no_title
							order_columns={true} 
							column_filters={true} 
							action_columns={["V","AN"]} 
							allow_create_new={false} 
							csv_export={true}
							user_mandants={this.props.user_mandants}
							per_page={20} 
							table_theme="bg-dark" 
							user_data={this.props.user_data}
						/>
					
					</Container>
				</Row>
			</Container>
			
			</div>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Backorders);
