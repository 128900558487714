import React, {Component} from "react";
import { Container, Row, Col, Card, FormSelect, FormInput,CardFooter, CardBody, Button } from "shards-react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PageTitle from "../common/PageTitle";
import RolesRights from "./additional-info/RolesRights";
import OrderDetail from "./details/Order";
import ReturnDetail from "./details/Return";
import BackorderDetail from "./details/Backorder";
import ItemDetail from "./details/Item";
import BuyingDetail from "./details/Buying";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../components-overview/Pagination";
import InventoryDetail from "./details/InventoryDetail/InventoryDetail";
import Addresscheckform from "../address-check/Addresscheckform";
import AddCommentsCustomer from './details/AddCommentsCustomer';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;
const newServiceUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class TableGrid extends Component {

	state = {
		key_column: this.props.key_column,
		loading_data: false,
		filters: {},
		grid_data: null,
		detail_skip_fields: ((this.props.detail_skip_fields) ? this.props.detail_skip_fields : []),
		new_form_fields: this.props.new_form_fields,
		edit_form_fields: this.props.edit_form_fields,
		user_mandants: this.props.user_mandants,
		id_user: this.props.id_user,
		per_page: this.props.per_page,
		page: 1,
		order_column: '',
		order_dir: '',
		show_detail: false,
		show_new_form: false,
		show_edit_form: false,
		detail_id: 0,
		edit_id: 0,
		csv_export: 0,
		show_overlay: false,
		current_page: 1,
		show_big_detail: false,
		special_filter: this.props.active_filter ? this.props.active_filter.column : '',
		special_filter_value: this.props.active_filter ? this.props.active_filter.value : '',
		show_edit_delivery_form: '',
		edit_delivery_form_data: null,
		show_add_note_form: '',
		active_color: 'blue',
		customer_comment: null,
		customer_comment_comment: null,
		sage_comment_backorders: null,
		show_sage_comment_backorders: false,
		user_data: this.props.user_data ? this.props.user_data.user_data.id_role : 0,
		special_param: this.props.special_param,
		special_param2: this.props.special_param2
	}

	componentDidMount() {

		var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
		let filters_url = {};
		// Load data
		if(this.props.active_filter) {
			filters_url[this.props.active_filter.column] = this.props.active_filter.value;
		}

		if(this.state.grid_data === null) {
			let self = this;
      if ('deliveries' === this.props.table_name) {
        axios.post(newServiceUrl + '/loadData', {
          table_name: this.props.table_name,
          special_param: this.props.special_param,
          special_param2: this.props.special_param2,
          filters: filters_url ? filters_url : self.state.filters,
          per_page: self.state.per_page,
          page: self.state.page,
          order_column: order_column_sort,
          order_dir: order_dir_sort,
          user_mandants: self.state.user_mandants,
          id_user: self.state.id_user,
          id_role: self.state.user_data
        }).then(function (response) {
          self.setState({grid_data: response.data});
          if(self.props.action_columns.includes("AN")) {
            axios.post(apiUrl + '/selectAllCustomerComment', {
              user_mandants: self.props.user_mandants,
            }).then(function (response) {
              self.setState({customer_comment: response.data.comment,customer_comment_comment: response.data.customer_comment}, () => {
                if(Object.keys(filters_url).length!==0){
                  document.getElementById('active-filter').click();
                }
              });

            }).catch(function (error) {
              console.log('ERROR:');
              console.log(error);
            });
          }
        }).catch(function (error) {
          console.log('ERROR:');
          console.log(error);
        });
      } else {
        axios.post(apiUrl + '/loadData', {
          table_name: this.props.table_name,
          special_param: this.props.special_param,
          special_param2: this.props.special_param2,
          filters: filters_url ? filters_url : self.state.filters,
          per_page: self.state.per_page,
          page: self.state.page,
          order_column: order_column_sort,
          order_dir: order_dir_sort,
          user_mandants: self.state.user_mandants,
          id_user: self.state.id_user,
          id_role: self.state.user_data
        }).then(function (response) {
          self.setState({grid_data: response.data});
          if(self.props.action_columns.includes("AN")) {
            axios.post(apiUrl + '/selectAllCustomerComment', {
              user_mandants: self.props.user_mandants,
            }).then(function (response) {
              self.setState({customer_comment: response.data.comment,customer_comment_comment: response.data.customer_comment}, () => {
                if(Object.keys(filters_url).length!==0){
                  document.getElementById('active-filter').click();
                }
              });

            }).catch(function (error) {
              console.log('ERROR:');
              console.log(error);
            });
          }
        }).catch(function (error) {
          console.log('ERROR:');
          console.log(error);
        });
      }
		}
	}

	prevPage = (e) => {
		let newPage = this.state.page - 1;
		var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
		this.setState({page: newPage, grid_data: null}, () => {
			// Send request
			let self = this;
			axios.post(apiUrl + '/loadData', {
				table_name: this.props.table_name,
				filters: self.state.filters,
				per_page: self.state.per_page,
				page: self.state.page,
				order_column: order_column_sort,
				order_dir: order_dir_sort,
				user_mandants: self.state.user_mandants,
				special_param: self.state.special_param,
				special_param2: self.state.special_param2
			}).then(function (response) {
				self.setState({grid_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		})
	}

	nextPage = (e) => {
		let newPage = this.state.page + 1;
		var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
		this.setState({page: newPage, grid_data: null}, () => {
			// Send request
			let self = this;
			axios.post(apiUrl + '/loadData', {
				table_name: this.props.table_name,
				filters: self.state.filters,
				per_page: self.state.per_page,
				page: self.state.page,
				order_column: order_column_sort,
				order_dir: order_dir_sort,
				user_mandants: self.state.user_mandants,
				special_param: self.state.special_param,
				special_param2: self.state.special_param2
			}).then(function (response) {
				self.setState({grid_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		})
	}

	changeLimiter = (new_limiter) => {
		this.setState({grid_data: null, filters: {}, page: 1, order_column: '', order_dir: '',per_page:parseInt(new_limiter)});

		var order_column_sort = this.props.order_column === 'undefined' ? '' : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? '' : this.props.order_dir;
		// Send request
		let self = this;
		axios.post(apiUrl + '/loadData', {
			table_name: this.props.table_name,
			filters: {},
			per_page: new_limiter,
			page: 1,
			order_column: order_column_sort,
			order_dir: order_dir_sort,
			user_mandants: self.state.user_mandants,
			special_param: self.state.special_param,
			special_param2: self.state.special_param2
		}).then(function (response) {
			self.setState({grid_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	onPageChanged = data => {
		const { currentPage } = data;
			var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
			var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
			this.setState({page: currentPage, grid_data: null}, () => {
				// Send request
				let self = this;
				axios.post(apiUrl + '/loadData', {
					table_name: this.props.table_name,
					filters: self.state.filters,
					per_page: self.state.per_page,
					page: self.state.page,
					order_column: order_column_sort,
					order_dir: order_dir_sort,
					user_mandants: self.state.user_mandants,
					special_param: self.state.special_param,
					special_param2: self.state.special_param2
				}).then(function (response) {
					self.setState({grid_data: response.data});
				}).catch(function (error) {
					console.log('ERROR:');
					console.log(error);
				});
			})
	  }

	clearFilters = (e) => {
		// First reset data
		this.setState({grid_data: null, filters: {}, page: 1, order_column: '', order_dir: '',special_filter: '',special_filter_value: ''});

		var order_column_sort = this.props.order_column === 'undefined' ? '' : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? '' : this.props.order_dir;
		// Send request
		let self = this;
		axios.post(apiUrl + '/loadData', {
			table_name: this.props.table_name,
			filters: {},
			per_page: self.state.per_page,
			page: 1,
			order_column: order_column_sort,
			order_dir: order_dir_sort,
			user_mandants: self.state.user_mandants,
			special_param: self.state.special_param,
			special_param2: self.state.special_param2
		}).then(function (response) {
			self.setState({grid_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	clearSorting = (e) => {
		// First reset data
		this.setState({grid_data: null, page: 1, order_column: '', order_dir: ''});
		var order_column_sort = this.props.order_column === 'undefined' ? '' : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? '' : this.props.order_dir;
		// Send request
		let self = this;
		axios.post(apiUrl + '/loadData', {
			table_name: this.props.table_name,
			filters: {},
			per_page: self.state.per_page,
			page: 1,
			order_column: order_column_sort,
			order_dir: order_dir_sort,
			user_mandants: self.state.user_mandants,
			special_param: self.state.special_param,
			special_param2: self.state.special_param2
		}).then(function (response) {
			self.setState({grid_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	submitFilter = (csv_export) => {

		// First reset data
		if(!csv_export) {
			this.setState({grid_data: null, page: 1});
		} else {
			this.setState({csv_export: 1});
		}

		// Send request
		let self = this;
		axios.post(apiUrl + '/loadData', {
			table_name: this.props.table_name,
			filters: self.state.filters,
			per_page: self.state.per_page,
			page: 1,
			order_column: self.state.order_column === '' ? this.props.order_column : self.state.order_column,
			order_dir: self.state.order_dir === '' ? this.props.order_dir : self.state.order_dir,
			csv_export: csv_export,
			user_mandants: self.state.user_mandants,
			special_param: self.state.special_param,
			special_param2: self.state.special_param2
		}).then(function (response) {
			if(!csv_export) {
				self.setState({grid_data: response.data});
			} else {
				self.setState({csv_export: 0});
				window.location.replace(apiUrl + '/' + response.data);
			}
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

    updateFilter = (e) => {
		let filters = this.state.filters;
		filters[e.target.attributes.data_column.nodeValue] = e.target.value;
        this.setState({
            filters: filters
        })
    }

	applyDefaultFilter = (column, value) => {
		let filters = this.state.filters;
		filters[column] = value;
        this.setState({
            filters: filters
        }, this.submitFilter());
	}

	applySpecialFilterAdd = (column, value) => {
		let filters = this.state.filters;
		filters[column] = value;
        this.setState({
            filters: filters,
			special_filter: column,
			special_filter_value: value
        }, this.submitFilter());
	}

	applySpecialFilter = (column, value) => {
		this.setState({filters: {}}, () => this.applySpecialFilterAdd(column, value));

	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  this.submitFilter();
		}
	  }

    updateFilterDate = (date, e) => {
		let filters = this.state.filters;
		filters[e] = date;
        this.setState({
            filters: filters
        })
    }

	focusElement = (e) => {
		document.getElementById(e).click()
	}

	updateNewForm = (e) => {
		if(e.target.attributes.type.nodeValue == 'text' || e.target.attributes.type.nodeValue == 'password' || e.target.attributes.type.nodeValue == 'select') {
			let new_form_fields = this.state.new_form_fields;
			new_form_fields[e.target.attributes.name.nodeValue]['value'] = e.target.value;
			this.setState({
				new_form_fields: new_form_fields
			});
		} else if(e.target.attributes.type.nodeValue == 'checkbox') {
			let new_form_fields = this.state.new_form_fields;

			/* Check if element is already in array */
			if(new_form_fields[e.target.attributes.name.nodeValue]['value'].includes(e.target.value)) {
				let index = new_form_fields[e.target.attributes.name.nodeValue]['value'].indexOf(e.target.value);
				if (index > -1) {
				  new_form_fields[e.target.attributes.name.nodeValue]['value'].splice(index, 1);
				}
			} else {
				new_form_fields[e.target.attributes.name.nodeValue]['value'].push(e.target.value);
			}

			this.setState({
				new_form_fields: new_form_fields
			});
		}
	}

	updateEditForm = (e) => {
		if(e.target.attributes.type.nodeValue == 'text' || e.target.attributes.type.nodeValue == 'password' || e.target.attributes.type.nodeValue == 'select') {
			let edit_form_fields = this.state.edit_form_fields;
			edit_form_fields[e.target.attributes.name.nodeValue]['value'] = e.target.value;
			this.setState({
				edit_form_fields: edit_form_fields
			});
		} else if(e.target.attributes.type.nodeValue == 'checkbox') {
			let edit_form_fields = this.state.edit_form_fields;

			/* Check if element is already in array */
			if(edit_form_fields[e.target.attributes.name.nodeValue]['value'].includes(e.target.value)) {
				let index = edit_form_fields[e.target.attributes.name.nodeValue]['value'].indexOf(e.target.value);
				if (index > -1) {
				  edit_form_fields[e.target.attributes.name.nodeValue]['value'].splice(index, 1);
				}
			} else {
				edit_form_fields[e.target.attributes.name.nodeValue]['value'].push(e.target.value);
			}

			this.setState({
				edit_form_fields: edit_form_fields
			});
		}
	}

	orderColumn = (e, column, dir) => {

		// First reset data
		this.setState({grid_data: null});

		// Send request
		this.setState({order_column: e.target.attributes['data-column'].value, order_dir: dir}, () => {
			// Send request
			let self = this;
			axios.post(apiUrl + '/loadData', {
				table_name: this.props.table_name,
				filters: self.state.filters,
				per_page: self.state.per_page,
				page: self.state.page,
				order_column: self.state.order_column,
				order_dir: self.state.order_dir,
				user_mandants: self.state.user_mandants,
				special_param: self.state.special_param,
				special_param2: self.state.special_param2
			}).then(function (response) {
				self.setState({grid_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		})
	}

	showDetail = (id) => {
		this.setState({show_detail: true, detail_id: id});
	}

	showBigDetail = (id) => {
		this.props.show_header_detail(false)
		this.setState({show_big_detail: true, detail_id: id});
	}

	hideDetail = () => {
		this.setState({show_detail: false, detail_id: 0});
	}

	hideBigDetail = () => {
		this.props.show_header_detail(true)
		this.setState({show_big_detail: false, detail_id: 0});
	}

	showNewForm = () => {
		this.setState({show_new_form: true, show_edit_form: false});
	}

	hideNewForm = () => {
		this.setState({show_new_form: false});
	}

	showEditDeliveryForm = (id) => {
		console.log(id);
		this.setState({show_edit_delivery_form: id})
	}
	disableEditDeliveryForm = () => {
		this.setState({show_edit_delivery_form: ''})
	}

	showAddNoteForm = (id) => {
		this.setState({show_add_note_form: id})
	}

	showComments = (id) => {
		let self = this;
		axios.post(apiUrl + '/showComments', {
			orderid: id
		}).then(function (response) {
			console.log(self.state.show_sage_comment_backorders);
			self.setState({sage_comment_backorders: response.data,show_sage_comment_backorders:true})
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	hideComment = () => {
		this.setState({sage_comment_backorders: null,show_sage_comment_backorders:false})
	}

	sdisableAddNoteForm = (id) => {
		this.setState({show_add_note_form: ''})
	}

	refreshAddNoteForm = (value) => {
		this.setState({customer_comment: value});
	}
	refreshAddNoteFormComment = (value) => {
		this.setState({customer_comment_comment: value});
	}

	showEditForm = (id) => {
		let self = this;
		let table_name = this.props.table_name;
		let grid_data = this.state.grid_data[table_name];
		let edit_form_fields = this.state.edit_form_fields;

		/* For some tables load info from additional table */
		if(table_name === 't_dashboard_roles') {

			axios.post(apiUrl + '/loadRolesRightsIds', {
				table_name: 't_dashboard_rights',
				id_role: id
			}).then(function (response) {
				grid_data.data.map((row, index) => {

					console.log(id);
					if(row[self.state.key_column] == id) {
						let itemAttributes = Object.keys(row);
						for(let i = 0; i < itemAttributes.length; i++) {
							edit_form_fields[itemAttributes[i]]['value'] = row[itemAttributes[i]];
						}
					}
				});

				/* Set permissions */
				edit_form_fields['permissions'].value = Object.values(response.data);
				self.setState({show_new_form: false, show_edit_form: true, edit_id: id, edit_form_fields: edit_form_fields});

			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});

		} else if(table_name === 't_dashboard_users') {

			axios.post(apiUrl + '/loadUserMandantsIds', {
				table_name: 'total_overview',
				id_user: id
			}).then(function (response) {
				grid_data.data.map((row, index) => {
					if(row[self.state.key_column] == id) {
						let itemAttributes = Object.keys(row);
						for(let i = 0; i < itemAttributes.length; i++) {
							if(itemAttributes[i] === 'password') {
								edit_form_fields[itemAttributes[i]]['value'] = '';
							} else {
								edit_form_fields[itemAttributes[i]]['value'] = row[itemAttributes[i]];
							}
						}
					}
				});

				/* Set mandants */
				edit_form_fields['mandants'].value = Object.values(response.data);
				self.setState({show_new_form: false, show_edit_form: true, edit_id: id, edit_form_fields: edit_form_fields});

			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});

		} else {
			grid_data.data.map((row, index) => {
				if(row[this.state.key_column] == id) {
					let itemAttributes = Object.keys(row);
					for(let i = 0; i < itemAttributes.length; i++) {
						edit_form_fields[itemAttributes[i]]['value'] = row[itemAttributes[i]];
					}
				}
			});

			this.setState({show_new_form: false, show_edit_form: true, edit_id: id, edit_form_fields: edit_form_fields});
		}
	}

	hideEditForm = () => {
		this.setState({show_edit_form: false});
	}

	saveItem = () => {
		// Send save request
		let self = this;
		axios.post(apiUrl + '/saveItem', {
			table_name: this.props.table_name,
			data: JSON.stringify(this.state.new_form_fields)
		}).then(function (response) {
			window.location.reload(false);
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	updateItem = () => {
		// Send save request
		let self = this;
		axios.post(apiUrl + '/updateItem', {
			table_name: this.props.table_name,
			data: JSON.stringify(this.state.edit_form_fields),
			key_column: this.state.key_column
		}).then(function (response) {
			window.location.reload(false);
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	deleteItem = (e, id) => {
		confirmAlert({
		  title: 'Delete item',
		  message: 'Are you sure you want to delete this item?',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => {
				axios.post(apiUrl + '/deleteItem', {
					table_name: this.props.table_name,
					id: id
				}).then(function (response) {
					window.location.reload(false);
				}).catch(function (error) {
					console.log('ERROR:');
					console.log(error);
				});
			  }
			},
			{
			  label: 'No',
			  onClick: () => {
			  }
			}
		  ]
		});
	}

	markAsRefund = (e, id) => {
		confirmAlert({
		  title: 'Mark return as refunded',
		  message: 'Are you sure you want to mark this return as refunded?',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => {
				axios.post(apiUrl + '/returnRefund', {
					table_name: this.props.table_name,
					id: id
				}).then(function (response) {
					window.location.reload(false);
				}).catch(function (error) {
					console.log('ERROR:');
					console.log(error);
				});
			  }
			},
			{
			  label: 'No',
			  onClick: () => {
			  }
			}
		  ]
		});
	}

	removeMarkAsRefund = (e, id) => {
		confirmAlert({
		  title: 'Set refund status back',
		  message: 'Do you really want to mark this return as not refunded?',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => {
				axios.post(apiUrl + '/returnRefundRemove', {
					table_name: this.props.table_name,
					id: id
				}).then(function (response) {
					window.location.reload(false);
				}).catch(function (error) {
					console.log('ERROR:');
					console.log(error);
				});
			  }
			},
			{
			  label: 'No',
			  onClick: () => {
			  }
			}
		  ]
		});
	}

	handleChangeDateTime (e) {
		let dateFormat =  e.getFullYear() + '-' + (("0"+(e.getMonth()+1)).slice(-2));

		var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
		this.setState({special_param: dateFormat, grid_data: null}, () => {
			// Send request
			let self = this;
			axios.post(apiUrl + '/loadData', {
				table_name: this.props.table_name,
				filters: self.state.filters,
				per_page: self.state.per_page,
				page: self.state.page,
				order_column: order_column_sort,
				order_dir: order_dir_sort,
				user_mandants: self.state.user_mandants,
				special_param: self.state.special_param,
				special_param2: self.state.special_param2,
				test: '1'
			}).then(function (response) {
				self.setState({grid_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		})
	}
	
	handleChangeDateTime2 (e) {
		let dateFormat =  e.getFullYear() + '-' + (("0"+(e.getMonth()+1)).slice(-2));

		var order_column_sort = this.props.order_column === 'undefined' ? this.state.order_column : this.props.order_column;
		var order_dir_sort = this.props.order_dir === 'undefined' ? this.state.order_dir : this.props.order_dir;
		this.setState({special_param2: dateFormat, grid_data: null}, () => {
			// Send request
			let self = this;
			axios.post(apiUrl + '/loadData', {
				table_name: this.props.table_name,
				filters: self.state.filters,
				per_page: self.state.per_page,
				page: self.state.page,
				order_column: order_column_sort,
				order_dir: order_dir_sort,
				user_mandants: self.state.user_mandants,
				special_param: self.state.special_param,
				special_param2: self.state.special_param2,
				test: '1'
			}).then(function (response) {
				self.setState({grid_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		})
	}
	
	changeErrorTopic = (e, column) => {
		console.log(column);
		let filters = this.state.filters;
		filters[column] = e.target.value;
        this.setState({
            filters: filters
        })
	}

	render() {
		if(this.state.grid_data === null) {
			return (
				<Row noGutters className="page-header py-4">
					{((!this.props.no_title) ?
						<PageTitle title={this.props.table_title} subtitle={this.props.table_subtitle} />
					: null)}

					<div className="clearfix"></div>
					<div className="grid-spinner"></div>
				</Row>
			)
		} else {

			let table_name = this.props.table_name;
			let grid_data = this.state.grid_data[table_name];
			let tableHeader = [];
			let tableFilters = [];
			let newFormFields = [];
			let editFormFields = [];
			let defaultFilters = [];
			let defaultSpecialFilters = [];
			let allInfo = [];

			/* Default filters processing */
			if (typeof(this.props.default_filters) != "undefined") {
				for(let d = 0; d < this.props.default_filters.length; d++) {
					let defaultFilter = this.props.default_filters[d];
					console.log(defaultFilter);

					defaultFilters.push(
						<Button theme="success" onClick={e => this.applyDefaultFilter(defaultFilter.column, defaultFilter.value)}>
							{defaultFilter.value}
						</Button>
					)
				}
			}
			/* Special colored filters processing */
			if (typeof(this.props.special_filters) != "undefined") {
				console.log(this.state.special_filter);
				for(let d = 0; d < this.props.special_filters.length; d++) {
					let defaultFilter = this.props.special_filters[d];
					let activeClass='';


					if(this.props.table_name==="t_inventory_data" ) {
						if(this.state.special_filter_value === this.props.special_filters[d].value) {
							activeClass = 'active-filter';
						} else {
							activeClass = '';
						}
					} else if(this.props.table_name==="sku_lots"){
						if(this.state.special_filter_value === this.props.special_filters[d].value) {
							activeClass = 'active-filter';
						} else {
							activeClass = '';
						}
					} else if(this.props.table_name==="tracking_issues"){
						if(this.state.special_filter_value === this.props.special_filters[d].value) {
							activeClass = 'active-filter';
						} else {
							activeClass = '';
						}
					} else {
						if (this.state.special_filter === defaultFilter.column) {
							activeClass = 'active-filter';
						}
						if(this.props.active_filter) {
							if(this.state.special_filter === defaultFilter.column){
								activeClass = 'active-filter';
							}
						}
					}

					defaultSpecialFilters.push(
							<Button id={activeClass} className={activeClass} style={{backgroundColor:defaultFilter.color,color:defaultFilter.textColor,border:0,}} theme="success" onClick={e => this.applySpecialFilter(defaultFilter.column, defaultFilter.value)}>
								{defaultFilter.title}
							</Button>
					)
				}
			}

			/* New form fields */
			for (var prop in this.props.new_form_fields) {
				if (Object.prototype.hasOwnProperty.call(this.props.new_form_fields, prop)) {
					let field = this.props.new_form_fields[prop];

					newFormFields.push(
						<tr>
							<th className="form-label">
								{field.label}:
							</th>
							<td className="form-value">

								{((field.type === 'varchar') ?
									<input type="text" name={prop} value={this.state.new_form_fields[prop]['value']} onChange={e => this.updateNewForm(e)} placeholder={field.label} />
								: null)}

								{((prop === 'form_style') ?
									<div className="radio-buttons">
										<input type="radio" name="style" value="1" id="style-1" /> <label for="style-1">Style 1</label>
										<input type="radio" name="style" value="2" id="style-2" /> <label for="style-2">Style 2</label>
										<input type="radio" name="style" value="3" id="style-3" /> <label for="style-3">Style 3</label>
									</div>
								: null)}

								{((field.type === 'select') ?
									<select name={prop} className="select-option" onChange={e => this.updateNewForm(e)} type="select">
										<option value="">-- Please select option --</option>
										{Object.keys(this.props.new_form_fields[prop].options).map((keyName, i) => (
											<option value={keyName}>{this.props.new_form_fields[prop].options[keyName]}</option>
										))}
									</select>
								: null)}

								{((field.type === 'checkboxes') ?
									<div className="checkboxes-options">
										{Object.keys(this.props.new_form_fields[prop].options).map((keyName, i) => (
											<div className="checkboxes-options-option">
												<input type="checkbox" id={"option-" + keyName} name={prop} value={keyName} onChange={e => this.updateNewForm(e)} />
												<label htmlFor={"option-" + keyName}>{this.props.new_form_fields[prop].options[keyName]}</label>
											</div>
										))}
									</div>
								: null)}

								{((field.comment) ?
									<div className="new-field-comment"><span>{field.comment}</span></div>
								: null)}

								{((prop === 'form_preview') ?
									<div className="return-form-preview">
										return form preview
									</div>
								: null)}
							</td>
						</tr>
					)
				}
			}

			/* Column names */
			for (var prop in this.props.table_columns) {
				if (Object.prototype.hasOwnProperty.call(this.props.table_columns, prop)) {
					if(this.state.special_filter_value === 'DL,VL,DE' && prop === 'stk_available_quantity'){
					} else {
					tableHeader.push(
						<th scope="col" className={((prop == 'skus') ? "width200 border-0": "border-0")}>
							{this.props.table_columns[prop]}

							{((this.props.order_columns && (prop != 'action_refund')) ?
								<a href="javascript://" data-column={prop} onClick={(e) => this.orderColumn(e, prop, ((this.state.order_dir == '') ? 'DESC' : ((this.state.order_dir == 'DESC') ? 'ASC' : 'DESC')))} className={"sort-arrow material-icons sort-arrow-" + ((this.state.order_dir == '') ? 'up' : ((this.state.order_dir == 'DESC') ? 'down' : 'up')) + ((prop === this.state.order_column && this.state.order_dir !== '') ? " sort-active" : "")}>sort</a>
							: null)}
						</th>
					)
				}
				}
			}

			/* Filters */
			for (var prop in this.props.table_columns) {
				if (Object.prototype.hasOwnProperty.call(this.props.table_columns, prop)) {
					let d = prop;
					if(this.state.special_filter_value === 'DL,VL,DE' && prop === 'stk_available_quantity'){
					} else {
					tableFilters.push(
						<td scope="col" className="border-0">
							{((grid_data.columns[d] === 'STRING' || grid_data.columns[d] === 'varchar' || grid_data.columns[d] === 'nvarchar') ?
							<div className={"column-filter column-filter-" + grid_data.columns[d]}>
									{prop ==='TOPIC' ?
									<FormSelect name={d} value={this.state.filters[d]} onChange={e => this.changeErrorTopic(e, 'TOPIC')}>
												<option value="0">Select Topic</option>
												<option value="Order Export">Order Export</option>
												<option value="Order Status Update">Order Status Update</option>
												<option value="Order Tracking Info">Order Tracking Info</option>
												<option value="Order Returns">Order Returns</option>
												<option value="Product Export">Product Export</option>
												<option value="Product Data Import">Product Data Import</option>
												<option value="Product Price Import">Product Price Import</option>
												<option value="Product Stock Import">Product Stock Import</option>
										</FormSelect>
									 :
									 	prop === 'SEVERITY' ?
										 	<FormSelect name={d} value={this.state.filters[d]} onChange={e => this.changeErrorTopic(e, 'SEVERITY')}>
										 		<option value="0">Select Severity</option>
										 		<option value="INFO">Info</option>
												 <option value="WARNING">Warning</option>
												 <option value="ERROR">Error</option>
								 			</FormSelect> :
										<input data_column={d} data_column_type="string" type="text" onChange={e => this.updateFilter(e)} placeholder="Search text.." name={d} value={this.state.filters[d]} onKeyDown={this._handleKeyDown} />
									}
								</div>
							: null)}
							{((grid_data.columns[d] === 'INT64' || grid_data.columns[d] === 'NUMERIC' || grid_data.columns[d] === 'int' || grid_data.columns[d] === 'decimal' || grid_data.columns[d] === 'numeric') ?
								<div className={"column-filter column-filter-" + grid_data.columns[d]}>
									<input data_column={"filter-min-" + d} data_column_type="int" className="filter-min" onChange={e => this.updateFilter(e)} type="text" placeholder="From" name={"filter-min-" + d} value={this.state.filters["filter-min-" + d]} onKeyDown={this._handleKeyDown} />
									<input data_column={"filter-max-" + d} data_column_type="int" className="filter-max" onChange={e => this.updateFilter(e)} type="text" placeholder="To" name={"filter-max-" + d} value={this.state.filters["filter-max-" + d]} onKeyDown={this._handleKeyDown} />
								</div>
							: null)}
							{((grid_data.columns[d] === 'datetime') ?
								<div className={"column-filter column-filter-" + grid_data.columns[d]}>
									<DatePicker id={"filter-from-" + d} data_column={"filter-from-" + d} data_column_type="datetime" dateFormat="dd.MM.yyyy" className="filter-from" placeholderText="From" selected={this.state.filters["filter-from-" + d]} onChange={e => this.updateFilterDate(e, "filter-from-" + d)} onKeyDown={this._handleKeyDown} />
									<a href="javascript://" onClick={e => this.focusElement("filter-from-" + d)} className="datetime-icon"><i className="material-icons">date_range</i></a>

									<DatePicker id={"filter-to-" + d} data_column={"filter-to-" + d} data_column_type="datetime" dateFormat="dd.MM.yyyy" className="filter-from" placeholderText="To" selected={this.state.filters["filter-to-" + d]} onChange={e => this.updateFilterDate(e, "filter-to-" + d)} onKeyDown={this._handleKeyDown} />
									<a href="javascript://" onClick={e => this.focusElement("filter-to-" + d)} className="datetime-icon"><i className="material-icons">date_range</i></a>
								</div>
							: null)}
							{((grid_data.columns[d] === 'date') ?
								<div className={"column-filter column-filter-" + grid_data.columns[d]}>
									<DatePicker id={"filter-from-" + d} data_column={"filter-from-" + d} data_column_type="date" dateFormat="dd.MM.yyyy" className="filter-from" placeholderText="From" selected={this.state.filters["filter-from-" + d]} onChange={e => this.updateFilterDate(e, "filter-from-" + d)} onKeyDown={this._handleKeyDown} />
									<a href="javascript://" onClick={e => this.focusElement("filter-from-" + d)} className="datetime-icon"><i className="material-icons">date_range</i></a>

									<DatePicker id={"filter-to-" + d} data_column={"filter-to-" + d} data_column_type="date" dateFormat="dd.MM.yyyy" className="filter-from" placeholderText="To" selected={this.state.filters["filter-to-" + d]} onChange={e => this.updateFilterDate(e, "filter-to-" + d)} onKeyDown={this._handleKeyDown} />
									<a href="javascript://" onClick={e => this.focusElement("filter-to-" + d)} className="datetime-icon"><i className="material-icons">date_range</i></a>
								</div>
							: null)}
						</td>
					)
					}
				}
			}

			/* Data */
			let tableColumns = Object.keys(this.props.table_columns);
			let alreadyRefunded = [];

			if(this.state.grid_data.already_refunded) {
				alreadyRefunded = Object.keys(this.state.grid_data.already_refunded);
			}

			let tableData = grid_data.data.length ? grid_data.data.map((row, index) => {
				var orderIdTop = '';
				return (
					<tbody>
						<tr className="table-grid-data">
							{ /* Action column */ }
							{((this.props.action_columns.length > 0) ?
								<td className="table-grid-actions" scope="col" align="center">
									{((this.props.action_columns.includes("V")) ?
										<a href="#" onClick={() => this.showDetail(row[this.state.key_column])}><i className="material-icons">search</i></a>
									: null)}
									{((this.props.action_columns.includes("E")) ?
										<a href="#" onClick={() => this.showEditForm(row[this.state.key_column])} className="edit-icon"><i className="material-icons">create</i></a>
									: null)}
									{((this.props.action_columns.includes("D")) ?
										<a href="javascript://" onClick={(e) => this.deleteItem(e, row[this.state.key_column])} className="delete-icon"><i className="material-icons">delete</i></a>
									: null)}
									{((this.props.action_columns.includes("BV")) ?
										<a href="#" onClick={() => this.showBigDetail(row[this.state.key_column])}><i className="material-icons">search</i></a>
									: null)}
									{((this.props.action_columns.includes("ED") && this.state.special_filter == 'no-label' && (this.props.user_id_info === '29' || this.props.user_id_info === '1')) ?
										<span className="edit-button" onClick={() => this.showEditDeliveryForm(row[this.state.key_column])}><i className="material-icons">create</i></span>
									: null)}
								</td>
							: <td className="table-grid-actions" scope="col" align="center"></td>)}

							{tableColumns.map(function (column) {
								if(column == 'stk_available_quantity' && (row['location_type_desc'] === 'Defective stock' || row['location_type_desc'] === 'Return stock')) {
									row[column] = parseFloat(0).toFixed(2);
								}
								if(column === 'order_id'){
									orderIdTop = row[column];
									var orderId = row[column];
									if(orderId.includes('_')) {
										var order = orderId.split("_");
										orderId = order[1];
									}
								}
								var sku_new = '';
								if(column === 'sku' || column === 'master_item' || column === 'contained_item' || column === 'lift_item' || column === 'SKU'){
									var sku = row[column];
									if(sku.includes('_')) {
										var skuItem = sku.split("_");
										sku_new = skuItem[1];
									}
								}
								if(column === 'skus_on_backorders'){
									var sku_s = row[column];
									var sku_new = '';
									if(sku_s) {
										if(sku_s.includes(', ')) {
											if(sku_s.includes(',')) {
												var skus = sku_s.split(", ");
												skus.forEach(myFunction);
											}
											function myFunction(item) {
												if(item.includes('_')) {
													var skuItemS = item.split("_");
													sku_new += skuItemS[1] + ', ';
												}
											}
										} else {
											if(sku_s.includes('_')) {
												var skuItemM = sku_s.split("_");
												sku_new = skuItemM[1];
											}
										}
									}
								}
								if((this.state.special_filter_value === 'DL,VL,DE' && column === 'stk_available_quantity')){

								} else {
								return (
									((column === 'tracking_url') ?
										<td scope="col"><a className="width150" href={row[column]} title={row[column]} target="_blank">{row[column]}</a></td>
									: ((column === 'action_refund') ?
										<td className="table-grid-actions" scope="col" align="center">
											{((alreadyRefunded.includes(row[this.state.key_column])) ?
												<a href="javascript://" title="Already refunded" className="refunded" onClick={(e) => this.removeMarkAsRefund(e, row[this.state.key_column])}>
													<i className="material-icons">check_circle</i>
												</a>
											:
												<a className="mark-as-refund" href="javascript://" title="Mark as refunded" onClick={(e) => this.markAsRefund(e, row[this.state.key_column])}>
													<i className="material-icons">check_circle_outline</i>
												</a>
											)}
										</td>
									: ((column === 'sku' || column === 'master_item' || column === 'contained_item' || column === 'lift_item'|| column === 'SKU') ?
										<td scope="col"><div className="width200">{sku_new}</div></td>
									: ((column === 'order_id') ?
									<td scope="col">{orderId}</td>
									: ((column === 'skus_on_backorders') ?
										<td scope="col" className="skus-big-row"><div className="width200">{sku_new.includes(', ') ? sku_new.slice(0, -2) : sku_new}</div></td>
									: ((column === 'has_history_and_comment') ?
										<td className="note-small table-grid-actions" scope="col" align="center">{(this.props.table_name === 'sperr_status_grid_new') ?
										<span className="edit-button" onClick={() => this.showComments(row[this.state.key_column])}>{row[column] === "1" ? 'Show comment' : ''}</span>
									: null}</td>
									: ((column === 'action_comment') ?
										<td className="note-small table-grid-actions" scope="col" align="center">{(this.props.action_columns.includes("AN")) || (this.props.action_columns.includes("AN") && this.props.table_name === 'sperr_status_grid_new') ?
										<span className="edit-button" onClick={() => this.showAddNoteForm(row[this.state.key_column])}>{this.state.customer_comment ? Object.values(this.state.customer_comment).includes(row[this.state.key_column]) ? <span className="comment-info">{this.state.customer_comment_comment[row[this.state.key_column]]}<i className="material-icons red">note_add</i></span> : <i className="material-icons">note_add</i> : ''}</span>
									: null}</td>
									: ((column === 'note_on_delivery') ?
									<td scope="col" class="note-small">{row[column]}</td>
									: ((column === 'note_on_backorder') ?
									<td scope="col" class="note-small-width">{row[column]}</td>
									: ((column === 'Final_Revenue') ?
									<td scope="col" class="note-small">{Number(row[column]).toFixed(2)}</td>
									: ((column === 'ship_date' || column === 'created_datetime_order') ?
                  <td scope="col">{row[column].includes('1753') ? 'N/A' : row[column]}</td>
                  : <td scope="col">{row[column] + ((column == 'confidence' || column == 'lift') ? '%' : '')}</td>)))))))))))
								);
								}
							}, this)}
						</tr>
						{ /* Show edit form for Delivery  */ }
						{this.props.table_name === 'deliveries' && this.state.show_edit_delivery_form === orderIdTop ?
							<Addresscheckform delivery_id={this.state.show_edit_delivery_form} edit_delivery_form_data={this.state.edit_delivery_form_data} access_token={this.props.access_token} onClickFun={() => this.disableEditDeliveryForm()} />
						: null}
						{(this.props.table_name === 'deliveries' && this.state.show_add_note_form === orderIdTop) || (this.props.table_name === 'sperr_status_grid_new' && this.state.show_add_note_form === orderIdTop) ?
							<AddCommentsCustomer refreshAddNoteFormComment={(e) => this.refreshAddNoteFormComment(e)} refreshAddNoteForm={(e) => this.refreshAddNoteForm(e)} delivery_id={this.state.show_add_note_form} onClickFun={() => this.sdisableAddNoteForm()} />
						: null}
					</tbody>
				)
			}) : '';

			/* Edit form fields */
			if(this.state.show_edit_form) {
				grid_data.data.map((row, index) => {
					if(row[this.state.key_column] == this.state.edit_id) {

						/* Edit form title */
						if(this.props.table_name == 't_dashboard_roles') {
							editFormFields.push(<tr><td colspan="2" className="edit-form-title"><h2>{row['name']}</h2></td></tr>);
						}

						for (var prop in this.props.edit_form_fields) {
							if (Object.prototype.hasOwnProperty.call(this.props.edit_form_fields, prop)) {
								let field = this.props.edit_form_fields[prop];

								editFormFields.push(
									<tr>
										<th className="form-label">
											{field.label}:
										</th>
										<td className="form-value">

											{((field.type === 'static') ?
												<div>
													{this.state.edit_form_fields[prop]['value']}
													<input type="hidden" name={prop} value={this.state.edit_form_fields[prop]['value']} />
												</div>
											: null)}

											{((field.type === 'varchar' && prop !== 'password') ?
												<input type="text" name={prop} value={this.state.edit_form_fields[prop]['value']} onChange={e => this.updateEditForm(e)} placeholder={field.label} />
											: null)}

											{((prop === 'password') ?
												<div className="password-field">
													<input type="text" name={prop} value={this.state.edit_form_fields[prop]['value']} onChange={e => this.updateEditForm(e)} placeholder={field.label} />
													<p>
														Leave empty to keep old password
													</p>
												</div>
											: null)}

											{((field.type === 'select') ?
												<select name={prop} className="select-option" onChange={e => this.updateEditForm(e)} type="select" value={this.state.edit_form_fields[prop]['value']}>
													{Object.keys(this.props.new_form_fields[prop].options).map((keyName, i) => (
														<option
															value={keyName}
														>{this.props.new_form_fields[prop].options[keyName]}</option>
													))}
												</select>
											: null)}

											{((field.type === 'checkboxes') ?
												<div className="checkboxes-options">
													{Object.keys(this.props.edit_form_fields[prop].options).map((keyName, i) => (
														<div className="checkboxes-options-option">
															<input
																type="checkbox"
																id={"option-" + keyName}
																name={prop} value={keyName}
																onChange={e => this.updateEditForm(e)}
																defaultChecked={((this.state.edit_form_fields[prop].value.includes(keyName)) ? true : false)}
															/>

															<label htmlFor={"option-" + keyName}>{this.props.edit_form_fields[prop].options[keyName]}</label>
														</div>
													))}
												</div>
											: null)}
										</td>
									</tr>
								)
							}
						}
					}
				});
			}

			/* Detail data */
			if(this.state.show_detail) {
				grid_data.data.map((row, index) => {
					if(row[this.state.key_column] == this.state.detail_id) {

						/* Detail data title */
						if(this.props.table_name == 't_dashboard_roles') {
							allInfo.push(<tr><td colspan="2"><h2>{row['name']}</h2></td></tr>);
						} else if(this.props.table_name == 't_dashboard_users') {
							allInfo.push(<tr><td colspan="2"><h2>{row['firstname'] + " " + row['lastname']}</h2></td></tr>);
						} else if(this.props.table_name == 't_purchase_order') {
							allInfo.push(<tr><td colspan="2"><h2>{row['order_id']}</h2></td></tr>);
						} else if(this.props.table_name == 't_return_data') {
							allInfo.push(<tr><td colspan="2"><h2>{row['return_id']}</h2></td></tr>);
						} else if(this.props.table_name == 't_sperr_status') {
							allInfo.push(<tr><td colspan="2"><h2>{row['order_id'] + " - " + row['sku']}</h2></td></tr>);
						} else if(this.props.table_name == 't_item_data') {
							allInfo.push(<tr><td colspan="2"><h2>{row['description']}</h2></td></tr>);
						} else if(this.props.table_name == 'stock_sku') {
							allInfo.push(<tr><td colspan="2"><h2>{row['sku_description']}</h2></td></tr>);
						}

						/* Detail data content */
						Object.keys(row).forEach((key) => {
							if(!this.state.detail_skip_fields.includes(key)) {
								if(key === 'order_id'){
									var orderId = row[key];
									if(orderId.includes('_')) {
										var order = orderId.split("_");
										orderId = order[1];
									}
									allInfo.push(<tr><td><strong>{key}</strong></td><td>{orderId}</td></tr>);
								} else {
									allInfo.push(<tr><td><strong>{key}</strong></td><td>{row[key]}</td></tr>);
								}
							}
						});
					}
				});
			}


			return (


				<div className="table-grid-container">

					{ /* DETAIL */ }
					{((this.state.show_detail) ?
						<div className="table-grid-detail">
							<div className="table-grid-detail-actions">
								<a href="javascript://" onClick={() => this.hideDetail()}><i className="material-icons">clear</i></a>
							</div>
							<Row>
								<Col>
									{((this.props.table_name == 'deliveries' || this.props.table_name == 'orders') ?
										<OrderDetail id={this.state.detail_id} />
									:
									<Card small>
										<CardBody>
											<table id={this.props.table_name} className="table mb-0">
												<tbody>
													{((this.props.table_name == 't_return_data_grid') ?
														<ReturnDetail id={this.state.detail_id} />
													: ((this.props.table_name == 'sperr_status_grid_new') ?
														<BackorderDetail id={this.state.detail_id} />
													: ((this.props.table_name == 't_purchase_order_grid') ?
														<BuyingDetail id={this.state.detail_id} />
													: ((this.props.table_name == 't_item_data_grid') ?
														<ItemDetail id={this.state.detail_id} />
													:  allInfo))))}
												</tbody>

												{ /* Additional info */ }
												{((this.props.table_name == 't_dashboard_roles') ?
													<RolesRights id={this.state.detail_id} />
												: null)}

											</table>
										</CardBody>
									</Card>
									)}
								</Col>
							</Row>
							<div className="table-grid-detail-footer">
								<a href="#" onClick={() => this.hideDetail()}>&larr; Back to list</a>
							</div>
						</div>
					:
					(this.state.show_big_detail) ?
						<div className="inventory-big-detail table-grid-detail">
							<div className="table-grid-detail-actions">
								<a href="javascript://" onClick={() => this.hideBigDetail()}><i className="material-icons">clear</i></a>
							</div>
							<Row>
								<Col>
									<Card small>
										<CardBody>
											<InventoryDetail user_data={this.state.user_mandants} id={this.state.detail_id} />
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					:
						<div className="table-grid">

								{((this.state.show_sage_comment_backorders) ?
									<div className="custom-popup">
										<div className="popup-content">
										<div onClick={() => this.hideComment()} className="popup-close"><span class="material-icons">close</span></div>
										<span className="popup-title">Comments</span>
										{Object.entries(this.state.sage_comment_backorders.comment).map(([key,value],i) =>
											<div>
												<span>{key}</span>
												<span>{value}</span>
											</div>
										)}
										</div>
									</div>
								: null)}
							{((this.state.csv_export) ?
								<div className="export-overlay">
									<div className="grid-spinner"></div>
								</div>
							: null)}

							{((!this.props.no_title) ?
								<Row noGutters className="page-header py-1">
									<PageTitle title={this.props.table_title} subtitle={this.props.table_subtitle} />
								</Row>
							: null)}

							{ /* EDIT FORM */ }
							{((this.state.show_edit_form) ?
								<Row className="edit-form">
									<Col>
										<Card small>
											<CardBody>
												<table className="edit-form-content" id={this.props.table_name}>
													<tbody>
														{editFormFields}
													</tbody>
												</table>

												<div className="edit-form-buttons">
													<Button onClick={() => this.updateItem()} theme="success">Save</Button>
													<Button onClick={() => this.hideEditForm()} theme="secondary">Back</Button>
												</div>
											</CardBody>
										</Card>
									</Col>
								</Row>
							: null )}

							{ /* NEW FORM */ }
							{((this.props.allow_create_new && !this.state.show_edit_form) ?
								<Row noGutters className="table-grid-new-button">
									{((!this.state.show_new_form) ?
										<Button onClick={() => this.showNewForm()} theme="success"> [+] Create New</Button>
									:
										<Button onClick={() => this.hideNewForm()} theme="dark">Hide Form</Button>
									)}
								</Row>
							: null)}

							{((this.state.show_new_form) ?
								<Row className="new-item-form">
									<Col>
										<Card small>
											<CardBody>
												<form method="post" action="#" id="new-item-form">
													<table id={this.props.table_name} className="new-form">
														<tbody>

															{newFormFields}

															<tr>
																<td colspan="2">
																	<Button onClick={() => this.saveItem()} theme="success">Save</Button>
																</td>
															</tr>
														</tbody>
													</table>
												</form>
											</CardBody>
										</Card>
									</Col>
								</Row>
							: null)}
							{ /* DATA TABLE */ }
							{((!this.state.show_edit_form) ?
								<Row>
									<Col>
									{((this.props.table_name == 'invoice_revenue_bypositions_range_f') ?

									<Container className="flex col-sm-12 special-month-date-picker">
									<div className="">Month: </div>
									<div className="special-month-date-picker-picker"><DatePicker
									dateFormat="yyyy-MM"
									showMonthYearPicker
									value={this.state.special_param}
									onChange={e => this.handleChangeDateTime(e)}
								/></div> 
								<div className="special-month-date-picker-picker"><DatePicker
									dateFormat="yyyy-MM"
									showMonthYearPicker
									value={this.state.special_param2}
									onChange={e => this.handleChangeDateTime2(e)}
								/></div></Container> 
								: null)}
										<table id={this.props.table_name} className="table mb-0">

													{ /* Column names */ }
													{((this.props.table_columns) ?
														<thead className={this.props.table_theme}>
															<tr>
																<th colspan="99999">

																	{((typeof(this.props.default_filters) != "undefined") ?
																		<div className="default-filters">
																			{defaultFilters}
																		</div>
																	: null)}
																	{((typeof(this.props.special_filters) != "undefined") ?
																		<div className="default-filters special-filters">
																			{defaultSpecialFilters}
																		</div>
																	: null)}


																	{((this.props.action_columns) ?
																		<a href="#" title="Filter" onClick={() => this.submitFilter(0)} className="start-filter"><i className="material-icons">search</i> Filter</a>
																	: null)}

																	{ /* If some filter is active */ }
																		{((Object.values(this.state.filters).join() != '') ?
																		<a href="#" title="Clear filters" onClick={this.clearFilters} className="clear-filters"><i className="material-icons">block</i> Clear filters</a>
																	: null)}

																	{ /* If sorting is active */ }
																		{((this.state.order_column != '') ?
																		<a href="#" title="Clear sorting" onClick={this.clearSorting} className="clear-filters"><i className="material-icons">block</i> Clear Sorting</a>
																	: null)}

																	{ /* CSV export */ }
																	{((this.props.csv_export) ?
																		<a href="#" title="Export to Excel" onClick={() => this.submitFilter(1)} className="clear-filters export-excel"><i className="material-icons">table_chart</i> Export to Excel</a>
																	: null)}
																	<div className="limiter-header">
																		<FormSelect value={this.state.per_page} style={{ maxWidth: "230px" }} onChange={(e) => this.changeLimiter(e.target.value)}>
																			<option value="20">20</option>
																			<option value="30">30</option>
																			<option value="50">50</option>
																			<option value="100">100</option>
																		</FormSelect>
																	</div>
																</th>
															</tr>
															<tr>
																{((this.props.action_columns) ?
																  <th scope="col" className="border-0"></th>
																: null)}

																{tableHeader}

															</tr>
														</thead>
													: null)}

														<tbody>
														{ /* Column filters */ }
														{((this.props.column_filters) ?
															<tr className="filter-row">
																<td scope="col" className="border-0" align="center"></td>
																{tableFilters}
															</tr>
														: null)}
														</tbody>

														{ /* Table Data */ }
														{((tableData != '') ?
														tableData : <tr className="nodata"><td colspan="1000">No Data</td></tr>)}


													<tfoot>
														<tr>
															<td colspan="1000">
																Total rows: <strong>{this.state.grid_data[table_name].total_count}</strong> / Page: <strong>{this.state.page}</strong>


																<div className="d-flex flex-row py-4 align-items-center table-grid-pagination-right">
																	<Pagination currentPage={this.state.page} totalRecords={this.state.grid_data[table_name].total_count} pageLimit={this.state.per_page} pageNeighbours={1} onPageChanged={this.onPageChanged} />
																</div>
																{ /* <span className="table-grid-pagination">
																	{((this.state.page > 1) ?
																		<a href="#" onClick={this.prevPage}>&larr; Prev page</a>
																	: null)}
																	{((this.state.page < this.state.grid_data[table_name].pages) ?
																		<a href="#" onClick={this.nextPage}>Next page &rarr;</a>
																	: null)}
																	</span> */ }
															</td>
														</tr>
													</tfoot>
												</table>
									</Col>
								</Row>
							: null )}

						</div>
					)}
				</div>
			)
		}
	}
}

export default TableGrid;
