import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Item extends Component {

	state = {
		loading_data: false,
		detail_data: null,
		detail_id: this.props.id
	}
	
	componentDidMount() {
		// Load data
		if(this.state.detail_data === null) {	
			let self = this;
		
			/* Collect mandants names */
			let userData = this.props.user_data;
			
			axios.post(apiUrl + '/loadItemData', {
				table_name: 't_item_data',
				itmref: this.state.detail_id,
				user_mandants: this.props.user_mandants
			}).then(function (response) {
				self.setState({detail_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}
	
	render() {	
		if(this.state.detail_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					Loading...
				</Container>
			)
			
		} else {
			
			// Stock data
			let stock =  this.state.detail_data.stock_data.map((row, index) => {
				return (
					<table className="detail-table detail-table-secondary detail-table-stock" width="100%">
						<tbody>
							<tr className="detail-table-title">
								<th width="50%">Location</th>
								<td width="50%">{row.location}</td>
							</tr>
							<tr>
								<th width="50%">Location Type</th>
								<td width="50%">{row.location_type}</td>
							</tr>
							<tr>
								<th width="50%">Location Description</th>
								<td width="50%">{row.location_type_desc}</td>
							</tr>
							<tr>
								<th width="50%">Quantity</th>
								<td width="50%">{row.stk_quantity}</td>
							</tr>
							<tr>
								<th width="50%">Active Quantity</th>
								<td width="50%">{row.stk_active_quantity}</td>
							</tr>
						</tbody>
					</table>
					
				);
			});
			
			// Bestsellers data
			let bestsellers = this.state.detail_data.bestseller_data.map((row, index) => {
				return (
					<tr>
						<td>
							{row.date}
						</td>
						<td>
							{row.qty_ordered}
						</td>
					</tr>
				);
			});
			
			// Stock movements data
			let stock_movements = this.state.detail_data.stock_movements_data.map((row, index) => {
				return (
					<tr>
						<td>
							{row.date}
						</td>
						<td>
							{row.qty}
						</td>
					</tr>
				);
			});
			
			// Purchase data
			let purchases = this.state.detail_data.purchased_info.map((row, index) => {
				return (
					<tr>
						<td>
							{row.order_id}
						</td>
						<td>
							{row.order_date}
						</td>
						<td>
							{row.expected_rcp_date}
						</td>
						<td>
							{row.supplier}
						</td>
						<td>
							{row.order_qty}
						</td>
						<td>
							{row.remains}
						</td>
						<td>
							{row.gross_price + " " + row.currency}
						</td>
					</tr>
				);
			});
			
			// Orders data
			let orders = this.state.detail_data.orders_info.map((row, index) => {
				return (
					<tr>
						<td>
							{row.order_id}
						</td>
						<td>
							{row.order_date_created}
						</td>
						<td>
							{row.status}
						</td>
						<td>
							{row.qty}
						</td>
						<td>
							{row.order_amount_with_taxes_order_currency + " " + row.order_currency}
						</td>
					</tr>
				);
			});
			
			return (
				<Container fluid className="main-content-container detail-content px-4">
					
					<div className="detail-title">
						{this.state.detail_data.description}
                    </div>		
					
					<div className="detail-content items-tables">
						<div className="items-tables-left">
							<div className="items-title">
								Item Info
							</div>		
							
							<table className="detail-table detail-table-primary" width="100%">
								<tbody>
									<tr>
										<th>Item Reference</th>
										<td>{this.state.detail_data.itmref}</td>
									</tr>
									<tr>
										<th>SKU</th>
										<td>{this.state.detail_data.zsku}</td>
									</tr>
									<tr>
										<th>Name</th>
										<td>{this.state.detail_data.description}</td>
									</tr>
									<tr>
										<th>EAN</th>
										<td>{this.state.detail_data.eancod	}</td>
									</tr>
									<tr>
										<th>TSICOD</th>
										<td>{this.state.detail_data.tsicod}</td>
									</tr>
								</tbody>
							</table>
						</div>
						
						<div className="items-tables-right">
							<div className="items-title">
								Stock Info
							</div>		
							
							{stock}
						</div>						
					</div>	
						
					<div className="items-title">
						Orders
                    </div>		
					
					<div className="items-table">
						<table className="item-table-secondary" width="100%">
							<thead>
								<tr>
									<th>Ordered ID</th>
									<th>Order Date</th>
									<th>Status</th>
									<th>Ordered Qty</th>
									<th>Order Amount</th>
								</tr>
							</thead>
							<tbody>
								{orders}
							</tbody>
						</table>
					</div>
						
					<div className="items-title">
						Current Purchases
                    </div>		
					
					<div className="items-table">
						<table className="item-table-secondary" width="100%">
							<thead>
								<tr>
									<th>Ordered ID</th>
									<th>Order Date</th>
									<th>Expected Recieve Date</th>
									<th>Supplier</th>
									<th>Ordered Qty</th>
									<th>Remains</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								{purchases}
							</tbody>
						</table>
					</div>
					
					<div className="items-tables">
						<div className="items-tables-left">
							<div className="items-title">
								Stock Movements
							</div>		
							<div className="items-table">
								<table className="item-table-secondary" width="100%">
									<thead>
										<tr>
											<th>Date</th>
											<th>Qty</th>
										</tr>
									</thead>
									<tbody>
										{stock_movements}
									</tbody>
								</table>
							</div>
						</div>
						
						<div className="items-tables-right">
							<div className="items-title">
								Bestseller Info
							</div>									
							<div className="items-table">
								<table className="item-table-secondary" width="100%">
									<thead>
										<tr>
											<th>Date</th>
											<th>Ordered Qty</th>
										</tr>
									</thead>
									<tbody>
										{bestsellers}
									</tbody>
								</table>
							</div>
						</div>						
					</div>					
					
				</Container>
			)
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
