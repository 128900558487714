import React, {Component} from "react";
import { Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class Batch extends Component {
	
    state = {
		user_mandants: this.props.user_mandants
	}

	render() {	
		
			return (
				<Container fluid className="main-content-container inventory-page">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Batch Overview" subtitle="Products data" />
                    </Row>
                    <Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							<TableGrid 
								table_name="sku_lots" 
								key_column="sku"
								table_title="Items" 
								detail_title="Item data" 
								table_subtitle="Items data" 
								table_columns={
									{
										sku: 'SKU',
										sku_long_description: 'Name',
										expiration_date: 'Expiration date',
										lot: 'Batch ID',
										qty: 'Qty',
										loctype: 'Stock location',
									}
								}
								special_filters={
									[
										{
											color: '#3bc265',
											textColor: '#000',
											title: 'Selling stock',
											column: 'loctype',
											value: 'KS,NS,MK'
										},
										{
											color: '#f1cb31',
											textColor: '#000',
											title: 'Return stock',
											column: 'loctype',
											value: 'RT'
										},
										{
											color: '#ff575b',
											textColor: '#000',
											title: 'Defective stock',
											column: 'loctype',
											value: 'DL,VL,DE'
										}
									]
								}
								order_column="expiration_date"
								order_dir="asc"
								order_columns={true}
								no_title
								column_filters={true} 
								csv_export={true}
								allow_create_new={false} 
								user_mandants={this.props.user_mandants}
								action_columns={[]}
								per_page={30} 
								table_theme="bg-dark" 
							/>
						</Container>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Batch);
