import React, {Component} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../store/actions/index';
import { Redirect } from "react-router-dom";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

class DefaultLayout extends Component {
	
	state = {
		noNavbar: false,
        noFooter: false
    };
	
	render() {	
		
		let user_rights = [];
		/* Check if user has permission to see given section */
		if(this.props.user_data != null) {
			if(this.props.user_data.user_rights != null) {
				if(Array.isArray(this.props.user_data.user_rights)) {
					user_rights = this.props.user_data.user_rights;
				}
				
				/* Mapping sections */
				let section_mapping = {
					'Connect(Profile)': 	0,
					'Connect(Dashboard)': 	1,
					'Connect(Orders)': 		2,
					'Connect(Returns)': 	3,
					'Connect(Backorders)': 	4,
					'Connect(Buyings)': 	5,
					'Connect(Items)': 		6,
					'Connect(Stock)': 		7,
					'Connect(Users)': 		8,
					'Connect(Roles)': 		9,
					'Connect(Bestsellers)': 10,
					'Connect(Inventory)': 11,
					'Connect(ReturnForm)': 12,
					'Connect(OpenShipments)': 13,
					'Connect(NewOrder)': 14,
					'Connect(Receivegoods)': 15,
					'Connect(Batch)': 16,
					'Connect(ServiceQuality)': 18,
					'Connect(ServiceCarrier)': 20,
					'Connect(Deliveries)': 21,
					'Connect(OrdersError)': 27,
					'Connect(ShippingIssues)': 28,
					'Connect(SkuRevenueReport)': 29,
					'Connect(ItemsBasketReport)': 30,
					'Connect(Errors)': 31,
				};
				
				/* Mapping sections URLs */
				let section_urls = {
					0: 	'/profile',
					1: 	'/dashboard',
					2:	'/orders',
					3: 	'/returns',
					4: 	'/backorders',
					5: 	'/buyings',
					6:	'/items',
					7:	'/stock',
					8:	'/users',
					9:	'/roles',
					10: '/bestsellers',
					11: '/inventory',
					12: '/return-form',
					13: '/open-shipments',
					14: '/neworder',
					15: '/receive-goods',
					16: '/batch',
					18: '/service-quality',
					20: '/service-carrier',
					21: '/deliveries',
					27: '/orderserror',
					28: '/shippingissues',
					29: '/skurevenuereport',
					30: '/itemsbasketreport',
					31: '/errors',
				};
				
				/* Current section */
				let currentSection = section_mapping[this.props.children.type.displayName];
				currentSection = 0;
		
				/* Check if section if available and if not redirect to first available section */
				let targetSection = '';
				if(!user_rights.includes(currentSection)) {
					targetSection = section_urls[user_rights[0]];
				}
				
				return (
					((currentSection !== 0 && (this.props.user_id === 0 || !user_rights.includes(currentSection))) ?
						((!user_rights.includes(currentSection)) ?
							<Redirect to={targetSection} /> 
						: 
							<Redirect to="/login" /> 
						)				
					: 
						<Container fluid>
							<Row>
								<MainSidebar />
									<Col
										className="main-content p-0"
										lg={{ size: 10, offset: 2 }}
										md={{ size: 9, offset: 3 }}
										sm="12"
										tag="main"
									>
									{!this.state.noNavbar && <MainNavbar />}
									{this.props.children}
									{!this.state.noFooter && <MainFooter />}
								</Col>
							</Row>
						</Container>
					)
				)
			} else {
				return (
					<Redirect to="/login" /> 
				)
			}
		} else {
			return (
				<Redirect to="/login" /> 
			)
		}
	}  
}


DefaultLayout.propTypes = {
	noNavbar: PropTypes.bool,
	noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
	noNavbar: false,
	noFooter: false
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
		user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

