import React, {Component} from "react";
import {Container,Row,Col,Button,FormSelect} from "shards-react";
import { Link } from "react-router-dom";
import PageTitle from "../common/PageTitle";
import DatePicker from "react-datepicker";
import backordersIcon from '../../assets/images/backorders.svg';
import incomingGoodsIcon from '../../assets/images/incoming_goods.svg';
import outOfStockIcon from '../../assets/images/out_of_stock.svg';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import Chart from "chart.js";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Dashboard extends Component {
	state = {
		fetchingData: true,
		dateFilter: 1000,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
		dashboard_data: null,
		ordersTotals: 0,
		returnsTotals: 0,
		buyingsTotals: 0,
		bestsellersData: [],
		backorders_data: null,
		fetchingBackorders: true,
		outofstock_data: null,
		fetchingOutofstock: true,
		incominggoods_data: null,
		fetchingIncominggoods: true,
		openShipmentsPerDay_data: null,
		fetchingOpenShipmentsPerDay: true,
		orders_data:null,
		fetchingOrderData: true,
		openshipments_data:null,
		fetchingOpenShipmentsData: true,
		returns_data:null, 
		fetchingReturnsData:true, 
		shipments_data:null,
		fetchingShipmentsData:true,
		avgDispatchTime: null,
		fetchingAvgDispatchTime: true,
		oderflow_data: null,
		oderflow_data_total: null,
		fetchingoderflow_data: true,
		fetchingoderflow_data_total: true,
		oderflow_backorders_data: null,
		fetchingoderflowbackorders_data: true,
		selectedMandants: this.props.user_mandants.includes("20") ? '01' : this.props.user_mandants,
		openReturnsPerDay_data: null, 
		fetchingReturnsPerDay: true,
		all_mandants: null,
		fetch_all_mandants: true,
	}

	/* Graphs refs */
    ordersCompareChart = React.createRef();
	returnsCompareChart = React.createRef();
    
    componentDidMount() {	
		/* Charts configuration */
        const ordersCompareChart = this.ordersCompareChart.current.getContext("2d");
		const returnsCompareChart = this.returnsCompareChart.current.getContext("2d");
		console.log(this.state.selectedMandants);
		/* Self object */
		let self = this;

		axios.all([
			axios.post(apiUrl + '/getAllMandants', {
				user_mandants: this.props.user_mandants,
			}), 
			axios.post(apiUrl + '/averageDispatchTime', {
				user_mandants: this.props.user_mandants,
				date_filter: self.state.dateFilter,
				date_filter_from: self.state.dateFilterFrom,
				date_filter_to: self.state.dateFilterTo
			}),
			axios.post(apiUrl + '/loadOrderFlow', {
				user_mandants: this.props.user_mandants,
				date_filter: self.state.dateFilter,
				date_filter_from: self.state.dateFilterFrom,
				date_filter_to: self.state.dateFilterTo
			}),
			axios.post(apiUrl + '/loadOrderFlowTotal', {
				user_mandants: this.props.user_mandants,
				date_filter: self.state.dateFilter,
				date_filter_from: self.state.dateFilterFrom,
				date_filter_to: self.state.dateFilterTo
			}),
			axios.post(apiUrl + '/loadOutofstockDashboard', {
				user_mandants: this.props.user_mandants,
			}),
			axios.post(apiUrl + '/loadIncomingGoodsDashboard', {
				user_mandants: this.props.user_mandants,
			}),
			axios.post(apiUrl + '/dashboardData?t=123456', {
				user_mandants: this.props.user_mandants,
				date_filter: self.state.dateFilter,
				date_filter_from: self.state.dateFilterFrom,
				date_filter_to: self.state.dateFilterTo
			}),
			axios.post(apiUrl + '/loadReturnsPerDayDashboard', {
				user_mandants: this.props.user_mandants,
				date_filter: self.state.dateFilter,
				date_filter_from: self.state.dateFilterFrom,
				date_filter_to: self.state.dateFilterTo
			}),
		  ])
		  .then(axios.spread((allmandans, avgdispatchtime, oderflowdata,loadOrderFlowTotal,loadOutofstockDashboard,loadIncomingGoodsDashboard,dashboardData,loadReturnsPerDayDashboard) => {
			// output of req.
			self.setState({all_mandants: allmandans.data,fetch_all_mandants:false,avgDispatchTime: avgdispatchtime.data, fetchingAvgDispatchTime:false,oderflow_data: oderflowdata.data, fetchingoderflow_data:false,oderflow_data_total: loadOrderFlowTotal.data, fetchingoderflow_data_total:false,outofstock_data: loadOutofstockDashboard.data,fetchingOutofstock:false,incominggoods_data: loadIncomingGoodsDashboard.data,fetchingIncominggoods:false,dashboard_data: dashboardData.data, fetchingData: false,openReturnsPerDay_data: loadReturnsPerDayDashboard.data, fetchingReturnsPerDay:false})

			let chartLabels = dashboardData.data.chart_labels;
			let ordersPerDay = dashboardData.data.chart_data_orders;
			let shipmentsPerDay = dashboardData.data.chart_data_shipments;
			
			new Chart(ordersCompareChart, {
				type: "line",
				data: {
					labels: chartLabels,
					datasets: [
						{
							label: "Orders imported",
							fill: "start",
							data: ordersPerDay,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
						{
							label: "Shipments",
							fill: "start",
							data: shipmentsPerDay,
							backgroundColor: "rgba(255,65,105,0.1)",
							borderColor: "rgba(255,65,105,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgba(255,65,105,1)",
							borderDash: [3, 3],
							borderWidth: 2,
							pointRadius: 5,
							pointBorderColor: "rgba(255,65,105,1)"
						}
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});

			let chartLabelsLoad = loadReturnsPerDayDashboard.data.returns_per_day_labels;
			let returnsPerDayLoad = loadReturnsPerDayDashboard.data.returns_per_day_data;
			
			new Chart(returnsCompareChart, {
				type: 'bar',
				data: {
					labels: chartLabelsLoad.reverse(),
					datasets: [
						{
							label: "Returns",
							fill: "start",
							data: returnsPerDayLoad.reverse(),
							backgroundColor: "rgba(202, 230, 115,0.5)",
							borderColor: "rgba(202, 230, 115,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 2,
							pointRadius: 5,
							borderRadius:5,
							borderSkipped: false,
						}
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		  })).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});

		       
    }
	
	updateDashboardData() {		
		/* Start fetching data */
		this.setState({dashboard_data: null, fetchingData: true,avgDispatchTime: null, fetchingAvgDispatchTime:true,oderflow_data:null,fetchingoderflow_data:true,oderflow_data_total: null, fetchingoderflow_data_total:true});	
		
		/* Self object */
		let self = this;
		
		/* Oder Process flow */
		axios.post(apiUrl + '/loadOrderFlow', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({oderflow_data: response.data, fetchingoderflow_data:false})
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
		axios.post(apiUrl + '/loadOrderFlowTotal', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({oderflow_data_total: response.data, fetchingoderflow_data_total:false})
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
		
		/* Load data for dashboard */
		axios.post(apiUrl + '/dashboardData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {			
			// Store response into state
			self.setState({dashboard_data: response.data, fetchingData: false});			
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});  
		/* Average dispatch time */
		axios.post(apiUrl + '/averageDispatchTime', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgDispatchTime: response.data, fetchingAvgDispatchTime:false})
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});      
	}
	
	updateFilterRange(e) {
		if(e.target.value === 'custom') {
			this.setState({dateFilter: e.target.value, customDateFilter: true});
		} else if(e.target.value === 'today') {
			const today = new Date()
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: today, dateFilterTo: today}, this.updateDashboardData);
		} else if(e.target.value == 'yesterday') {
			const today = new Date()
			const yesterday = new Date(today)
			yesterday.setDate(yesterday.getDate() - 1)
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: yesterday, dateFilterTo: yesterday}, this.updateDashboardData);
		} else {
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: "", dateFilterTo: ""}, this.updateDashboardData);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({dateFilterFrom: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		} else {
			this.setState({dateFilterTo: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		}
	}

	changeMandants = (e) => {
		if(e.target.value === "01") {
			// this.props.setMandant(["01","20"]);
			console.log('set');
			this.props.setMandant(["01","20"], function() {
				console.log(this.props.user_mandants);
			  });
		} else {
			this.props.setMandant([e.target.value], function() {
				console.log(this.props.user_mandants);
			  });
		}
		setTimeout(()=>{
			window.location.reload(false);
		}, 500);
	}
		
    render() {
	
		/* Process sales by country */
		let backorders = '';
		let outOfStock = '';
		let incomingGoods = '';
		let salesByCountry = '';
		let openShipments = '';
		let mapChartData = [];
		let mapChartDataColors = ["#1b4f72","#1a5276","#1f618d","#2471a3","#2980b9","#5499c7","#7fb3d5","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3","#a9cce3"];		

		if(this.state.backorders_data !== null) {
			let self = this;
			backorders = self.state.backorders_data.backorders.length !== 0 ? self.state.backorders_data.backorders.map(function(item, i) {
				return <tr key={i}>
							<td>
								<div className="width100 align-left" title={item.sku}>{item.sku}</div>
							</td>
							<td>
								<div className="width100 align-left">{item.name}</div>
							</td>
							<td>
								<div className="width100">{item.qty}</div>
							</td>
						</tr>;
			}) : '';
		}
		if(this.state.outofstock_data !== null) {
			let self = this;
			outOfStock = self.state.outofstock_data.out_of_stock.length !== 0 ? self.state.outofstock_data.out_of_stock.map(function(item, i) {
				return <tr key={i}>
					<td>
						<div className="width100 align-left" title={item.sku}>{item.sku}</div>
					</td>
					<td>
						<div className="width200 align-left">{item.name}</div>
					</td>
				</tr>;
			}) : '';
		}
		if(this.state.incominggoods_data !== null) {
			let self = this;
			incomingGoods = self.state.incominggoods_data.incoming_goods.length !== 0 ? self.state.incominggoods_data.incoming_goods.map(function(item, i) {
				return <tr key={i}>
					<td>
						<div className="width200 align-left" title={item.skus}>{item.skus}</div>
					</td>
					<td>
						<div className="width250 align-left" title={item.names}>{item.names}</div>
					</td>
					<td><div className="width70">{item.qtys}</div></td>
					<td>{item.supplier}</td>
					<td>{item.delivery_date}</td>
				</tr>;
			}) : '';
		}
		if(this.state.openShipmentsPerDay_data !== null) {
			let self = this;
			openShipments = self.state.openShipmentsPerDay_data.open_shipments.map(function(item, i) {
				return <tr key={i}><td>{item.date}</td><td>{item.qty}test</td></tr>;
			});
		}
		if(this.state.dashboard_data !== null) {
			let self = this;
			salesByCountry = self.state.dashboard_data.sales_by_country.map(function(item, i) {
				mapChartData.push({
				  "id": item.country,
				  "value": item.count,
				  "fill": am4core.color(mapChartDataColors[i])
				});
				return <tr key={i}><td>{item.country}</td><td>{item.count}</td><td>{item.percent}%</td></tr>;
			});
		}									  
									  
		/* Map Chart */
		var chart = am4core.create("chartdiv", am4maps.MapChart);
		chart.logo.disabled = true;
		chart.geodata = am4geodata_worldLow;
		chart.projection = new am4maps.projections.Miller();	
		chart.homeZoomLevel = 6;
		/* Central Europe */
		chart.homeGeoPoint = {latitude: 52, longitude: 10};		
		var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
		polygonSeries.useGeodata = true;
		var polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.tooltipText = "{name}: {value}";
		polygonTemplate.fill = am4core.color("#18c571");
		polygonTemplate.propertyFields.fill = "fill";
		var hs = polygonTemplate.states.create("hover");
		hs.properties.fill = am4core.color("#367B25");
		polygonSeries.data = mapChartData;
			
		
		return (
				<Container fluid className="main-content-container dashboard-page">
						<Row noGutters className="page-header py-4 px-2">
							<Col className="col-lg-8">
								<PageTitle title="Dashboard" subtitle="global-overview" />
							</Col>
							<Col className="col-lg-4">
								<Row noGutters className="py-3">
									<Container fluid className="col-sm-4 px-1">
										<FormSelect onChange={e => this.updateFilterRange(e)}>
											<option value="today">Today</option>
											<option value="yesterday">Yesterday</option>
											<option value="30">Last 30 days</option>
											<option value="7">Last 7 days</option>
											<option value="365">Last year</option>
											<option value="custom">Custom...</option>
										</FormSelect>
									</Container>
									<Container fluid className="col-sm-4 px-1">
										{((this.state.customDateFilter) ?
											<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
										:
											<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
										)}
									</Container>
									<Container fluid className="col-sm-4 px-1">
										{((this.state.customDateFilter) ?
											<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
										:
											<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
										)}
									</Container>
								</Row>
							</Col>
						</Row>
						<Container fluid className='col-sm-12 px-2 dashboard-filter-oder-flow'>
						{((this.state.fetchingoderflow_data) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
							: this.state.oderflow_data !== null ?  
							<Row>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div fluid className="dashboard-orders-provided dashboard-orders-label">
										<div className="dashboard-filter-block">
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Orders provided
												</span>
												<span className="dashboard-filter-oder-flow-value">
													{this.state.oderflow_data.orders_provided}
												</span>
											</div>
										</div>
									</div>	
									{this.state.selectedMandants == '01' || this.state.selectedMandants == '49' || this.state.selectedMandants == '06' || this.state.selectedMandants == '31'
									|| this.state.selectedMandants == '40' || this.state.selectedMandants == '42' || this.state.selectedMandants == '48' ? 
									<div className="dashboard-orders-detail">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/backorders"></Link>
											<div className="dashboard-orders-detail-title">Orders not provided</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data.orders_not_provided}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>	  : ''}
								</Container>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div fluid className="dashboard-orders-imported dashboard-orders-label">
										<div className="dashboard-filter-block">
											<Link className="block-link" to="/backorders"></Link>
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Orders imported
												</span>
												<span className="dashboard-filter-oder-flow-value">
													{this.state.oderflow_data.orders_imported}
												</span>
											</div>
										</div>
									</div>
									<div className="dashboard-orders-detail">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/backorders"></Link>
											<div className="dashboard-orders-detail-title">Backorders</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">New</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.backorders_1}</span>
														</div>
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.backorders_2}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>	
								</Container>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div fluid className="dashboard-deliveries dashboard-orders-label">
										<div className="dashboard-filter-block">
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Deliveries
												</span>
												<span className="dashboard-filter-oder-flow-value">
													{this.state.oderflow_data.deliveries}
												</span>
											</div>
										</div>		
									</div>
									<div className="dashboard-orders-detail dashboard-orders-detail-no-label-no-delivery">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/deliveries?filter=no-label"></Link>
											<div className="dashboard-orders-detail-title">No label</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">New</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.no_label_1}</span>
														</div>
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.no_label_2}</span>
														</div>
													</div>
												: null )}
										</div>
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/deliveries?filter=no-label"></Link>
											<div className="dashboard-orders-detail-title">No delivery</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">New</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.no_delivery_1}</span>
														</div>
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.no_delivery_2}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>	
								</Container>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div fluid className="dashboard-deliveries-with-shipping-label dashboard-orders-label">
										<div className="dashboard-filter-block">
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Deliveries with shipping label
												</span>
												<span className="dashboard-filter-oder-flow-value">
													{this.state.oderflow_data.deliveries_with_label}
												</span>
											</div>
										</div>	
									</div>
									<div className="dashboard-orders-detail">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/deliveries?filter=not-printed"></Link>
											<div className="dashboard-orders-detail-title">Not printed</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">New</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.not_printed_1}</span>
														</div>
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.not_printed_2}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>		
								</Container>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div className="dashboard-shipments-printed dashboard-orders-label">
										<div className="dashboard-filter-block">
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Shipments printed
												</span>
												<span className="dashboard-filter-oder-flow-value">
												{this.state.oderflow_data.deliveries_with_label_printed}
												</span>
											</div>
										</div>		
									</div>
									<div className="dashboard-orders-detail">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/deliveries?filter=not-shipped-yet"></Link>
											<div className="dashboard-orders-detail-title">Not shipped yet</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">New</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.not_shipped_1}</span>
														</div>
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.not_shipped_2}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>		
								</Container>
								<Container fluid className="col-sm-2 px-2 order-flow-block">
									<div className="dashboard-shipments-dispatch dashboard-orders-label">
										<div className="dashboard-filter-block">
											<Link className="block-link" to="/deliveries?filter=shipments-dispatch"></Link>
											<div className="dashboard-filter-oder-flow-label">
												<span className="dashboard-filter-oder-flow-title">
													Shipments dispatched
												</span>
												<span className="dashboard-filter-oder-flow-value">
												{this.state.oderflow_data.shipped}
												</span>
											</div>
										</div>			
									</div> 
									<div className="dashboard-orders-detail">
										<div className="dashboard-orders-wrapper">
											<Link className="block-link" to="/deliveries?filter=no-progress-in-tracking"></Link>
											<div className="dashboard-orders-detail-title">No progress in tracking</div>
											{((this.state.fetchingoderflow_data_total) ? <div className="dashboard-filter-block lightui1-shimmer"><div className="grid-spinner"></div></div>
												: this.state.oderflow_data_total !== null ?
													<div className="dashboard-orders-detail-info-wrapper">
														<div className="dashboard-orders-detail-info">
															<span className="dashboard-orders-detail-info-title">Total</span>
															<span className="dashboard-orders-detail-info-value">{this.state.oderflow_data_total.no_progress_in_tracking}</span>
														</div>
													</div>
												: null )}
										</div>
									</div>	
								</Container> 
							</Row>
							: null )}
						</Container>
						
						<Container fluid className="col-sm-12 px-2 dashboard-filter-map-totals">
							<Row>
								<Container fluid className="col-sm-9 chart-dashboard-block">
								{((this.state.fetchingData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null)}
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											Sales by country
										</div>
										<Row>
											<Container fluid className="col-sm-5 dashboard-filter-map-table">
												<table width="100%">
													<thead>
														<tr><th>Country</th><th># Orders</th><th>% of Orders</th></tr>
													</thead>
													<tbody>
														{salesByCountry}								
													</tbody>
												</table>
											</Container>
											<Container fluid className="col-sm-7 states-map-dashboard">
												<div id="chartdiv" style={{ width: "100%", height: "300px" }}></div>
											</Container>
										</Row>
									</div>
								</Container>
								<Container fluid className="col-sm-3">
											{((this.state.fetchingAvgDispatchTime) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
									
										<div className="dashboard-filter-totals">
										<div className="dashboard-filter-map-totals-title dashboard-filter-map-totals-title-center">
										<div className="dashboard-filter-map-totals-title-link">
															<a href="/service-quality">Show All</a>
														</div>
											Average dispatch time
											
											</div>
											<div className="dashboard-filter-totals-content">
												<Row>
													<Col className="dashboard-filter-totals-content-label average-dispatch-block">
														<div className="average-dispatch">Per order</div>
														<Button outline theme="success">
															{this.state.avgDispatchTime.average_dispatch_time} hour(s)
														</Button>
													</Col>
												</Row>
											</div>
										</div>
											)}
								</Container>
							</Row>
						</Container>
							
						<Container fluid className="col-sm-12 px-2 dashboard-filter-map-totals dashboard-filter-map-totals-second">
							<Row>
								<Container fluid className="col-sm-6 dashboard-filter-map-block">
								{((this.state.fetchingData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											Orders imported / Shipments per day
										</div>
										  <canvas
											height="120"
											ref={this.ordersCompareChart}
											style={{ maxWidth: "100% !important" }}
										  />
									</div>
								
								</Container>
								<Container fluid className="col-sm-6 dashboard-filter-map-block">
								{((this.state.fetchingReturnsPerDay) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											Returns per day
										</div>
											<canvas
											height="120"
											ref={this.returnsCompareChart}
											style={{ maxWidth: "100% !important" }}
										  />
									</div>
								
								</Container>
								
							</Row>
						</Container>
						
							
						<Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block">
							<Row>
								<Container fluid className="col-sm-3 no-display">
								{((this.state.fetchingBackorders) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											<div className="dashboard-filter-map-totals-title-link">
											{((backorders !== '') ? <a href="/backorders">Show All</a> : null )}
											</div>
											<img className="d-inline-block align-top mr-1" style={{ width: "35px" }} src={backordersIcon} />
											Backorders 
										</div>
										<div className="dashboard-totals-block-content">
											<Row>
												<Container fluid className="col-sm-12">
													<div className="dashboard-totals-content">
													{((backorders !== '') ?
														<table width="100%">
															<thead>
																<tr><th width="100">SKU</th><th width="150">Name</th><th width="100">Back. Qty</th></tr>
															</thead>
															<tbody>
																{backorders}										
															</tbody>
														</table>
														: <div className="nodata">No Data</div>)}
													</div>
												</Container>
											</Row>
										</div>
									</div>
									)}
								</Container>
								<Container fluid className="col-sm-4">
								{((this.state.fetchingOutofstock) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											<div className="dashboard-filter-map-totals-title-link">
											{((outOfStock !== '') ? <a href="/inventory">Show All</a>: null)}
											</div>
											<img
												className="d-inline-block align-top mr-1"
												style={{ width: "35px" }}
												src={outOfStockIcon}
											/>
											Out of stock
										</div>
										<div className="dashboard-totals-block-content">
											<Row>
												<Container fluid className="col-sm-12">
													<div className="dashboard-totals-content">
													{((outOfStock !== '') ?
														<table width="100%">
															<thead>
																<tr><th>SKU</th><th>Name</th></tr>
															</thead>
															{outOfStock}									
														</table>
														: <div className="nodata">No Data</div>)}
													</div>
												</Container>
											</Row>
										</div>
									</div>
									)}
								</Container>
								<Container fluid className="col-sm-8">
								{((this.state.fetchingIncominggoods) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
									<div className="dashboard-filter-map">
										<div className="dashboard-filter-map-totals-title">
											<div className="dashboard-filter-map-totals-title-link">
											{((incomingGoods !== '') ? <a href="/incoming-goods">Show All</a>: null)}
											</div>
											<img
												className="d-inline-block align-top mr-1"
												style={{ width: "35px" }}
												src={incomingGoodsIcon}
											/>
											Incoming Goods
										</div>
										<div className="dashboard-totals-block-content">
											<Row>
												<Container fluid className="col-sm-12">
													<div className="dashboard-totals-content">
													{((incomingGoods !== '') ?
														<table width="100%">
															<thead>
															<tr><th width="100">SKU</th><th width="250">Name</th><th width="70">Qty</th><th width="200">Supplier</th><th width="100">Delivery date</th></tr>
															</thead>
															<tbody>
																{incomingGoods}	
															</tbody>
														</table>
													: <div className="nodata">No Data</div>)}
													</div>
												</Container>
											</Row>
										</div>
									</div>
									)}
								</Container>
							</Row>
						</Container>
			  </Container>
			)
	}
}

const mapStateToProps = state => {
    return {
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants,
		user_id: state.glb.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
		setMandant: (user_mandants) =>  dispatch(actionCreators.setMandant(user_mandants)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
