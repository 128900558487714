import React, {Component} from "react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';

import ReturnItem from './ReturnItem/ReturnItem';
import OutOfStockItem from './OutOfStockItem/OutOfStockItem';
import StockTurnOver from './StockTurnOver/StockTurnOver';
import ItemDetail from './ItemDetail/ItemDetail';
import Sales from './Sales/Sales';

import './InventoryDetail-styles.css'

class InventoryDetail extends Component {

	state = {
		item_id: this.props.id,
        user_data: this.props.user_mandants,
	}

    render() {
        return(
            <div className="detail-item-information">
				<ItemDetail user_data={this.state.user_data} id={this.state.item_id} />
                <Sales user_data={this.state.user_data} id={this.state.item_id} />
                <ReturnItem user_data={this.state.user_data} id={this.state.item_id} />
                <StockTurnOver user_data={this.state.user_data} id={this.state.item_id} />
			   	<OutOfStockItem user_data={this.state.user_data} id={this.state.item_id} />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetail);