import React, {Component} from "react";
import { Container, Row } from "shards-react";
import Chart from "chart.js";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class OutOfStockItem extends Component {

    state = {
		outofstock_data: null,
		fetchingsOutofstockData: true,
		selected_year: new Date().getFullYear(),
		current_year: new Date().getFullYear(),
		display: 'table'
	}

    outOfStockDataChart = React.createRef();

    componentDidMount() {
        /* Charts configuration */
        const outOfStockDataChart = this.outOfStockDataChart.current.getContext("2d");
		let mapChartDataColors = ["#1b4f72","#CD5C5C","#40E0D0","#a9cce3","#CCCCFF","#008000","#808080"];		
		/* Self object */
		let self = this;
		
		/* Collect mandants names */
		let userData = this.props.user_data;

		/* Return monthly overview */
		axios.post(apiUrl + '/outOfStockMonthlyData', {
			user_mandants: userData,
			item_id: this.props.id,
			selected_year: this.state.selected_year
		}).then(function (response) {
			self.setState({outofstock_data: response.data, fetchingsOutofstockData:false});
			let setsData = [];
			Object.entries(response.data.outofstocks_month).map(([key,value],i) =>
			setsData.push(
				{
					label: "Out of Stock - " + key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value['count']),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColors[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			)

            new Chart(outOfStockDataChart, {
				type: "bar",
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'],
					datasets: setsData
				},
				options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    },
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	handleChangeDisplayData(display) {
		this.setState({display: display})
	}


    render() {
        return(
			   <div className="page-detail-block">
					<Row className="row-title">
						<Container className="col-sm-12 title-icons-block">
							<h5>Out of Stock</h5>  
						</Container>
					</Row>
					<div className="detail-table-changer-block">
						<div className={this.state.display ==='table' ? "show-icons show-table" : 'show-icons show-chart'}>
							<i onClick={() => this.handleChangeDisplayData('table')} className="material-icons table-show">table_chart</i>
							<i onClick={() => this.handleChangeDisplayData('chart')} className="material-icons chart-show">bar_chart</i>
						</div>
                    <Row className={this.state.display ==='table' ? "show-display" : 'not-show-display'}>
					{((this.state.fetchingsOutofstockData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
						<Container className="detail-info-table">
							<Row>
							{this.state.outofstock_data ? 
								(Object.keys(this.state.outofstock_data.outofstocks_month).length !== 0 ? 
								<Container className="col-sm-12">
									<div className="dashboard-totals-content table-with-sum">
										<table>
												<thead>
													<tr>
														<th>Year</th>
														<th>January</th>
														<th>February</th>
														<th>March</th>
														<th>April</th>
														<th>May</th>
														<th>June</th>
														<th>July</th>
														<th>August</th>
														<th>September</th>
														<th>October</th>
														<th>November</th>
														<th>December</th>
														<th>Sum</th>
													</tr>
												</thead>
												<tbody>
												{this.state.outofstock_data ? 
													Object.entries(this.state.outofstock_data.outofstocks_month).map(([key,value],i) =>
													<tr>
													<td>{key}</td>
														{Object.entries(value).map(([key,value],i) =>
															<td key={key}>{value['count']}</td>
															)}
														{Object.entries(this.state.outofstock_data.outofstock_sum).map(([keySum,valueSum],i) =>
															(key === keySum ? <td key={key}>{valueSum['count']}</td> : null )
														)}
													</tr>
													)  
													: null }
												</tbody>
											</table>
									</div>
								</Container>
								: 'No data') 
								: null}
							</Row>
						</Container>
						)}
					</Row>
					</div>
					<Row className={this.state.display ==='chart' ? "show-display" : 'not-show-display'}>
						<Container className="col-sm-10">
							{((this.state.fetchingsOutofstockData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
							<Container id="chart_outofstock" className="col-sm-12 px-12" style={{marginLeft: 0}}>
								<canvas height="120" ref={this.outOfStockDataChart} style={{ maxWidth: "100% !important" }} />
							</Container>
						</Container>
					</Row>
			   </div>
        )
    }

}

export default OutOfStockItem;