import React, {Component} from "react";
import { Container, Row} from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Addresscheck extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		access_token: ''
	}

	componentDidMount() {
		/* Self object */
		let self = this;
        axios.post('http://localhost:8888/dashboard_new/dashboard/apicalls/access_token.php', {
		}).then(function (response) {
			self.setState({access_token: response.data.access_token});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {	
						
		return (
			<Container fluid className="main-content-container inventory-page">
				<div></div>
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Deliveries" subtitle="Deliveries" />
				</Row>
				
				<Row noGutters className="inventory-header py-3 px-2">
					<Container fluid className="col-sm-12">
						<TableGrid 
							table_name="deliveries" 
							key_column="order_id"
							table_title="Deliveries" 
							detail_title="Deliveries" 
							table_subtitle="Deliveries" 
							table_columns={
								{
									order_id: 'ID', 
									delivery_id: 'Delivery ID',	
									customer_name: 'Customer Name',
									created_datetime_order: 'Created At',
									order_amount_with_taxes_order_currency: 'Price',
									sum_ek_price: 'EK cost',
									currency: 'Currency',
									tracking_url: 'Tracking url'
								}
							} 
							order_column="delivery_created_at"
							order_dir="desc"
							no_title
							order_columns={true} 
							column_filters={true} 
							action_columns={["V", "ED"]} 
							allow_create_new={false} 
							csv_export={true}
							user_mandants={this.props.user_mandants}
							per_page={20} 
							table_theme="bg-dark"
							access_token={this.state.access_token}
						/>
					
					</Container>
				</Row>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresscheck);
