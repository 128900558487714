import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
import TableGrid from "../../table-grid/TableGrid";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Outofstock extends Component {
	
	render() {	
		return (
			<Container fluid className="main-content-container detail-content px-4">
				<div className="detail-title">
				Out of Stock Products
				</div>	
				<TableGrid 
					table_name="t_stock_sku_outofstock" 
					key_column="sku"
					table_title="Out of Stock Products" 
					detail_title="Out of Stock Products details" 
					table_subtitle="Out of Stock Products items" 
					table_columns={
						{
							sku: 'SKU',
							sku_description: 'Product'
						}
					} 
					no_title
					order_columns={true} 
					column_filters={true} 
					action_columns={["V"]} 
					allow_create_new={false} 
					csv_export={true}
					user_mandants={this.props.user_mandants}
					per_page={30} 
					table_theme="bg-dark" 
				/>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Outofstock);
