import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Buyings extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		buyings_data: null,
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadBuyingsHeaderData', {
			user_mandants: self.state.user_mandants,
		}).then(function (response) {
			self.setState({buyings_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {	
	
		if(this.state.buyings_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<PageTitle title="Incoming Goods" subtitle="Buyings" />
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
				</Container>
			)
			
		} else {
			
			
			let topBuyings = this.state.buyings_data.buying_items.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-8 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-4 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
			
			
			let topSuppliers = this.state.buyings_data.top_suppliers.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.label}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
			
			return (
				<Container fluid className="main-content-container inventory-page">
					
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Incoming Goods" subtitle="Buyings" />
					</Row>
					
					
					{((this.state.buyings_data !== null) ? 
						<Row noGutters className="inventory-header">
							<Container fluid className="col-sm-12 px-2">
								<Row noGutters className="inventory-header-block">
									
									<Container fluid className="col-sm-2 px-2">
										<h4>Total Buyings Amount:</h4>
										<Row noGutters className="total-inventory-value py-2">
											<Button outline theme="success">
												{this.state.buyings_data.total_buyings_amount} &euro;
											</Button>
										</Row>
										
										<br />
										
										<h4>Total Buyings Quantity:</h4>
										<Row noGutters className="total-inventory-value py-2">
											<Button outline theme="info">
												{this.state.buyings_data.total_buyings_qty}
											</Button>
										</Row>
									</Container>
									
									<Container fluid className="col-sm-5 px-2">
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<h4>Top Buying Items:</h4>
											</Container>
										</Row>
										
										<Row noGutters className="top-5-products">
											{topBuyings}
										</Row>
									</Container>
									
									<Container fluid className="col-sm-5 px-2">
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<h4>Top Suppliers:</h4>
											</Container>
										</Row>
										
										<Row noGutters className="top-5-products">
											{topSuppliers}
										</Row>
									</Container>
								</Row>
							</Container>
						</Row>
					: null)}
					
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							
							<TableGrid 
								table_name="t_purchase_order_grid" 
								key_column="order_id"
								table_title="Buyings" 
								detail_title="Buying detail" 
								table_subtitle="Ordered items" 
								table_columns={
									{
										order_id: 'Order ID',
										order_date: 'Order Date',
										supplier_name: 'Supplier',
										sku: 'Ordered SKUs'
									}
								} 
								order_column="order_date"
								order_dir="asc"
								no_title
								order_columns={true} 
								column_filters={true} 
								action_columns={["V"]} 
								allow_create_new={false} 
								csv_export={true}
								user_mandants={this.props.user_mandants}
								per_page={30} 
								table_theme="bg-dark" 
							/>
							
						</Container>
					</Row>
					
				</Container>				
					
			)
			
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buyings);
