
import React, { Component } from "react";
import { Container, Row, FormInput, FormSelect, Button } from "shards-react";
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Addresscheckform extends Component {

    state = {
        user_mandants: this.props.user_mandants,
        openshipments_data: null,
        access_token: this.props.access_token,
        delivery_id: this.props.delivery_id,
		form_data: this.props.edit_delivery_form_data,
        customer_name: '',
        customer_surname: '',
        customer_phone: '',
        customer_email: '',
        customer_address1: '',
        customer_address2: '',
        customer_zip: '',
        customer_city: '',
        customer_country: '',
        customer_carrier: '',
        address_delivery: null
    }

    componentDidMount() {
        let self = this;
		axios.post(apiUrl + '/loadDeliveryAddress', {
			user_mandants: self.state.user_mandants,
            delivery_id: this.props.delivery_id
		}).then(function (response) {
			self.setState({address_delivery: response.data, 
                customer_name: response.data.first_name,
                customer_surname:response.data.last_name,
                customer_address1: response.data.street_adddres1,
                customer_address2: response.data.street_adddres2,
                customer_zip: response.data.pos_code,
                customer_city: response.data.city,
                customer_country: response.data.country,
                customer_phone: response.data.phone,
                customer_email: response.data.email,
                customer_carrier: response.data.carrier,
            });
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    myChangeHandler = (event) => {
        console.log(event.target.value);
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    submitForm(e) {
        console.log(e);
			axios.post('http://localhost:8888/dashboard_new/dashboard/apicalls/address_change.php', {
				token: this.state.access_token,
                user_mandants: this.state.address_delivery.cpy,
                customer_name: this.state.customer_name,
                delivery_id: this.state.address_delivery.delivery_id,
                customer_surname: this.state.customer_surname,
                customer_phone: this.state.customer_phone,
                customer_email: this.state.customer_email,
                customer_carrier: this.state.customer_carrier,
                customer_address1: this.state.customer_address1,
                customer_address2: this.state.customer_address2,
                customer_zip: this.state.customer_zip,
                customer_city: this.state.customer_city,
                customer_country: this.state.customer_country,
			}).then(function (response) {
				console.log(response);
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
    }

    render() {

        return (
			((this.state.form_data) ?
				<tr>
					<td colspan="14">
						<Row className="new-item-form">
							<Container className="px-4">
								<div class="table-grid-detail-actions"><span className="close-edit-form" onClick={this.props.onClickFun}><i class="material-icons">clear</i></span></div>
								{this.state.address_delivery === null ? null : 
								<form method="post" action="#" id="new-item-form">
									<Row noGutters className="py-2">
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="Customer name" value={this.state.customer_name === '' ? '' : this.state.customer_name} name='customer_name' onChange={this.myChangeHandler} />
										</Container>
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="Customer surname" value={this.state.customer_surname === '' ? '' : this.state.customer_surname} name='customer_surname' onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container className="col-sm-12 px-1">
											<FormInput placeholder="Country" value={this.state.customer_country === '' ? '' : this.state.customer_country} name='customer_country' onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="Phone" name='customer_phone' value={this.state.customer_phone === '' ? '' : this.state.customer_phone} onChange={this.myChangeHandler} />
										</Container>
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="E-mail" name='customer_email' value={this.state.customer_email === '' ? '' : this.state.customer_email} onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container className="col-sm-12 px-1">
											<FormInput className="col-sm-12" placeholder="Address 1" value={this.state.customer_address1 === '' ? '' : this.state.customer_address1} name='customer_address1' onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container className="col-sm-12 px-1">
											<FormInput className="col-sm-12" placeholder="Address 2" name='customer_address2' value={this.state.customer_address2 === '' ? '' : this.state.customer_address2} onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="ZIP Code" name='customer_zip' value={this.state.customer_zip === '' ? '' : this.state.customer_zip} onChange={this.myChangeHandler} />
										</Container>
										<Container fluid className="col-sm-6 px-1">
											<FormInput placeholder="City" name='customer_city' value={this.state.customer_city === '' ? '' : this.state.customer_city} onChange={this.myChangeHandler} />
										</Container>
									</Row>
									<Row noGutters className="py-2">
										<Container fluid className="col-sm-12 px-1">
											<Button onClick={e => this.submitForm(e)} outline>Submit</Button>
										</Container>
									</Row>
								</form>
								} 
							</Container>
						</Row>
					</td>
				</tr>
			: 
				<tr>
					<td colspan="14">
						Loading...
					</td>
				</tr>
			)
        )
    }

};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
        user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresscheckform);