import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Errors extends Component {

	state = {
		user_mandants: this.props.user_mandants
	}
	
	render() {	
		
		return (
			
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4">
					<PageTitle title="Errors" subtitle="Errors table" />
				</Row>
								
				<TableGrid 
				    table_name="shop_error_log" 
					key_column="id"
					table_title="Stock Movements" 
				    detail_title="Stock Movements" 
					table_subtitle="Stock Movements" 
					table_columns={
						{
						    ID: 'Id',
						    REFID: 'REFID',
                            SEVERITY: 'SEVERITY',
							TOPIC: 'Topic',
							MESSAGE: 'Message',
                            ENTRYTIME: 'ENTRYTIME'
						}
					} 
					order_column="ENTRYTIME"
					order_dir="desc"
					no_title
					order_columns={true} 
					column_filters={true} 
					action_columns={[]} 
                    allow_create_new={false} 
					csv_export={true}
					user_mandants={this.props.user_mandants}
					per_page={30} 
					table_theme="bg-dark" 
				/>
				
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
