import React, {Component} from "react";
import { Container, Row, Col } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
const axios = require("axios");

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class OrdersError extends Component {
	state = {
        user_mandants: this.props.user_mandants,
        orderserror_data: null, 
        fetchingsOrderserror: true, 
	}

    componentDidMount() {	
        /* Self object */
        let self = this;
        axios.post(apiUrl + '/loadOrdersError', {
			user_mandants: self.state.user_mandants,
			user_role: this.props.user_data.user_roles[0]['id'],
            days_deep_to_check: 7
		}).then(function (response) {
			self.setState({orderserror_data: response.data, fetchingsOrderserror:false});
        });
    }
	render() {	
            let orders_errors = '';
            let self = this;
            
            if(this.state.orderserror_data !== null) {	
                let data = this.state.orderserror_data.error_informations;
                console.log(data);
                orders_errors = Object.entries(data).map(([key,value],i) =>

                    <tr>
                        <td className="table-grid-actions" scope="col" align="center"></td>
                        <td scope="col">
                            {value['order_id']}
                        </td>
                        <td scope="col">
                            {value['error_message']}
                        </td>
                        <td scope="col">
                            {value['entrytime']}
                        </td>
                    </tr>
                );
            }
			return (
				<Container fluid className="main-content-container inventory-page">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Orders Errors" subtitle="Orders error data" />
                    </Row>
                    {((this.state.fetchingsOrderserror) ? 
                        <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
                        <div className="table-grid-container">
                            <div className="table-grid">
                                <Row>
                                    <Col>
                                        <table id="order-errors" className="table mb-0">
                                            <thead className="bg-dark">
                                                <tr>
                                                    <th className="table-grid-actions" scope="col" align="center"></th>
                                                    <th>Order ID</th>
                                                    <th>Error mesage</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>			
                                                 {orders_errors}                                           
                                            </tbody>			
                                        </table>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
        user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersError);
