import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Chart from "chart.js";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class ServiceCarrier extends Component {
	
    state = {
        avgCarrierData_data: null, 
		fetchingsAvgCarrierData:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
		chartType: 0,
		avgCarrierMonthData_data: null, 
		fetchingsAvgCarrierMonthData:true,
		currentYear: new Date().getFullYear(),
		displayType: 0
	}

    avgCarrierDataChart = React.createRef();

    componentDidMount() {
        /* Charts configuration */
        const avgCarrierDataChart = this.avgCarrierDataChart.current.getContext("2d");
        /* Self object */
		let self = this;
		let mapChartDataColors = ["rgba(202, 230, 115,0.1)","rgba(255,65,105,0.1)","rgba(0,123,255,0.1)","rgba(27, 79, 114,0.1)","rgba(205, 92, 92,0.1)","rgba(64, 224, 208,0.1)","rgba(169, 204, 227,0.1)","rgba(204, 204, 255,0.1)","rgba(0, 128, 0,0.1)","rgba(128, 128, 128,0.1)","rgba(250,128,114,0.1)","rgba(178,34,34,0.1)","rgba(255,99,71,0.1)","rgba(255,140,0,0.1)","rgba(124,252,0,0.1)","rgba(34,139,34,0.1)","rgba(0,250,154,0.1)","rgba(128,128,0,0.1)","rgba(0,255,255,0.1)","rgba(0,128,128,0.1)","rgba(0,0,139,0.1)","rgba(128,0,128,0.1)","rgba(165,42,42,0.1)"];
        let mapChartDataColorsBorder = ["rgba(202, 230, 115,1","rgba(255,65,105,1)","rgba(0,123,255,1)","rgba(27, 79, 114,1)","rgba(205, 92, 92,1)","rgba(64, 224, 208,1)","rgba(169, 204, 227,1)","rgba(204, 204, 255,1)","rgba(0, 128, 0,1)","rgba(128, 128, 128,1)","rgba(250,128,114,1)","rgba(178,34,34,1)","rgba(255,99,71,1)","rgba(255,140,0,1)","rgba(124,252,0,1)","rgba(34,139,34,1)","rgba(0,250,154,1)","rgba(128,128,0,1)","rgba(0,255,255,1)","rgba(0,128,128,1)","rgba(0,0,139,1)","rgba(128,0,128,1)","rgba(165,42,42,1)"];	
        /* Avg carrier data */
        axios.post(apiUrl + '/avgCarrierData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgCarrierData_data: response.data, fetchingsAvgCarrierData:false});

            let setsData = [];
			Object.entries(response.data.data).map(([key,value],i) =>
			setsData.push(
				{
					label: key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColorsBorder[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			) 

			new Chart(avgCarrierDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: setsData
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					},
					legend: {
						onClick: function(e, legendItem) {
						  var index = legendItem.datasetIndex;
						  var ci = this.chart;
						  var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
						  var anyOthersAlreadyHidden = false;
						  var allOthersHidden = true;
				  
						  ci.data.datasets.forEach(function(e, i) {
							var meta = ci.getDatasetMeta(i);
				  
							if (i !== index) {
							  if (meta.hidden) {
								anyOthersAlreadyHidden = true;
							  } else {
								allOthersHidden = false;
							  }
							}
						  });
				  
						  if (alreadyHidden) {
							ci.getDatasetMeta(index).hidden = null;
						  } else {
							ci.data.datasets.forEach(function(e, i) {
							  var meta = ci.getDatasetMeta(i);
				  
							  if (i !== index) {
								if (anyOthersAlreadyHidden && !allOthersHidden) {
								  meta.hidden = true;
								} else {
								  meta.hidden = meta.hidden === null ? !meta.hidden : null;
								}
							  } else {
								meta.hidden = null;
							  }
							});
						  }
				  
						  ci.update();
						},
					  }
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    
    }

	changeCurrentYear(e) {
		let currentYear = this.state.currentYear;
		if(e === 1) {
			currentYear = currentYear+1;
		} else {
			currentYear = currentYear-1;
		}
		this.setState({currentYear: currentYear}, () => {
			this.changeMonthlyChart()
		});
	}

	changeMonthlyChart() {
		let self = this;
		/* Avg carrier data */

		let mapChartDataColors = ["rgba(202, 230, 115,0.1)","rgba(255,65,105,0.1)","rgba(0,123,255,0.1)","rgba(27, 79, 114,0.1)","rgba(205, 92, 92,0.1)","rgba(64, 224, 208,0.1)","rgba(169, 204, 227,0.1)","rgba(204, 204, 255,0.1)","rgba(0, 128, 0,0.1)","rgba(128, 128, 128,0.1)","rgba(250,128,114,0.1)","rgba(178,34,34,0.1)","rgba(255,99,71,0.1)","rgba(255,140,0,0.1)","rgba(124,252,0,0.1)","rgba(34,139,34,0.1)","rgba(0,250,154,0.1)","rgba(128,128,0,0.1)","rgba(0,255,255,0.1)","rgba(0,128,128,0.1)","rgba(0,0,139,0.1)","rgba(128,0,128,0.1)","rgba(165,42,42,0.1)"];
        let mapChartDataColorsBorder = ["rgba(202, 230, 115,1","rgba(255,65,105,1)","rgba(0,123,255,1)","rgba(27, 79, 114,1)","rgba(205, 92, 92,1)","rgba(64, 224, 208,1)","rgba(169, 204, 227,1)","rgba(204, 204, 255,1)","rgba(0, 128, 0,1)","rgba(128, 128, 128,1)","rgba(250,128,114,1)","rgba(178,34,34,1)","rgba(255,99,71,1)","rgba(255,140,0,1)","rgba(124,252,0,1)","rgba(34,139,34,1)","rgba(0,250,154,1)","rgba(128,128,0,1)","rgba(0,255,255,1)","rgba(0,128,128,1)","rgba(0,0,139,1)","rgba(128,0,128,1)","rgba(165,42,42,1)"];	
        /* Avg order data */
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
		
		/* Avg carrier data */
        axios.post(apiUrl + '/avgCarrierMonthlyData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			current_year: self.state.currentYear,
		}).then(function (response) {
			self.setState({avgCarrierMonthData_data: response.data, fetchingsAvgCarrierMonthData:false});
            const avgCarrierDataChart = document.getElementById("order-chart").getContext("2d");

            let setsData = [];
			Object.entries(response.data.data).map(([key,value],i) =>
			setsData.push(
				{
					label: key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColorsBorder[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			) 

			new Chart(avgCarrierDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: setsData
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					},
					legend: {
						onClick: function(e, legendItem) {
						  var index = legendItem.datasetIndex;
						  var ci = this.chart;
						  var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
						  var anyOthersAlreadyHidden = false;
						  var allOthersHidden = true;
				  
						  ci.data.datasets.forEach(function(e, i) {
							var meta = ci.getDatasetMeta(i);
				  
							if (i !== index) {
							  if (meta.hidden) {
								anyOthersAlreadyHidden = true;
							  } else {
								allOthersHidden = false;
							  }
							}
						  });
				  
						  if (alreadyHidden) {
							ci.getDatasetMeta(index).hidden = null;
						  } else {
							ci.data.datasets.forEach(function(e, i) {
							  var meta = ci.getDatasetMeta(i);
				  
							  if (i !== index) {
								if (anyOthersAlreadyHidden && !allOthersHidden) {
								  meta.hidden = true;
								} else {
								  meta.hidden = meta.hidden === null ? !meta.hidden : null;
								}
							  } else {
								meta.hidden = null;
							  }
							});
						  }
				  
						  ci.update();
						},
					  }
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	changeDisplay(e) {
        if(e.target.value === '1') {
			this.setState({displayType: 1})
		} else {
			this.setState({displayType: 0})
		}
    }

	changeChartType(e) {
		if(e.target.value === '1') {
			this.setState({chartType: 1})
			this.changeMonthlyChart()
		
		} else {
			this.setState({chartType: 0})
			this.updateDashboardData()
		}
	}

    updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({dateFilter: e.target.value, customDateFilter: true});
		} else {
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: "", dateFilterTo: ""}, this.updateDashboardData);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({dateFilterFrom: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		} else {
			this.setState({dateFilterTo: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		}
	}

    updateDashboardData() {		
		/* Start fetching data */
		this.setState({avgCarrierData_data: null, fetchingsAvgCarrierData:true,});	
		
		/* Self object */
		let self = this;
		
        let mapChartDataColors = ["rgba(202, 230, 115,0.1)","rgba(255,65,105,0.1)","rgba(0,123,255,0.1)","rgba(27, 79, 114,0.1)","rgba(205, 92, 92,0.1)","rgba(64, 224, 208,0.1)","rgba(169, 204, 227,0.1)","rgba(204, 204, 255,0.1)","rgba(0, 128, 0,0.1)","rgba(128, 128, 128,0.1)","rgba(250,128,114,0.1)","rgba(178,34,34,0.1)","rgba(255,99,71,0.1)","rgba(255,140,0,0.1)","rgba(124,252,0,0.1)","rgba(34,139,34,0.1)","rgba(0,250,154,0.1)","rgba(128,128,0,0.1)","rgba(0,255,255,0.1)","rgba(0,128,128,0.1)","rgba(0,0,139,0.1)","rgba(128,0,128,0.1)","rgba(165,42,42,0.1)"];
        let mapChartDataColorsBorder = ["rgba(202, 230, 115,1","rgba(255,65,105,1)","rgba(0,123,255,1)","rgba(27, 79, 114,1)","rgba(205, 92, 92,1)","rgba(64, 224, 208,1)","rgba(169, 204, 227,1)","rgba(204, 204, 255,1)","rgba(0, 128, 0,1)","rgba(128, 128, 128,1)","rgba(250,128,114,1)","rgba(178,34,34,1)","rgba(255,99,71,1)","rgba(255,140,0,1)","rgba(124,252,0,1)","rgba(34,139,34,1)","rgba(0,250,154,1)","rgba(128,128,0,1)","rgba(0,255,255,1)","rgba(0,128,128,1)","rgba(0,0,139,1)","rgba(128,0,128,1)","rgba(165,42,42,1)"];	
        /* Avg order data */
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
		
		/* Avg carrier data */
        axios.post(apiUrl + '/avgCarrierData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgCarrierData_data: response.data, fetchingsAvgCarrierData:false});
            const avgCarrierDataChart = document.getElementById("order-chart").getContext("2d");

            let setsData = [];
			Object.entries(response.data.data).map(([key,value],i) =>
			setsData.push(
				{
					label: key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColorsBorder[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			) 

			new Chart(avgCarrierDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: setsData
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					},
					legend: {
						onClick: function(e, legendItem) {
						  var index = legendItem.datasetIndex;
						  var ci = this.chart;
						  var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
						  var anyOthersAlreadyHidden = false;
						  var allOthersHidden = true;
				  
						  ci.data.datasets.forEach(function(e, i) {
							var meta = ci.getDatasetMeta(i);
				  
							if (i !== index) {
							  if (meta.hidden) {
								anyOthersAlreadyHidden = true;
							  } else {
								allOthersHidden = false;
							  }
							}
						  });
				  
						  if (alreadyHidden) {
							ci.getDatasetMeta(index).hidden = null;
						  } else {
							ci.data.datasets.forEach(function(e, i) {
							  var meta = ci.getDatasetMeta(i);
				  
							  if (i !== index) {
								if (anyOthersAlreadyHidden && !allOthersHidden) {
								  meta.hidden = true;
								} else {
								  meta.hidden = meta.hidden === null ? !meta.hidden : null;
								}
							  } else {
								meta.hidden = null;
							  }
							});
						  }
				  
						  ci.update();
						},
					  }
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

	render() {	
		
			return (
				<Container fluid className="main-content-container px-4 sevice-carrier-report">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Carrier Service Quality" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                            <CardBody>
									<Row noGutters className="page-header pt-4 pb-0 px-2">
										<Col className="col-lg-1 pb-0 pl-2 switcher-custom-title">Display Type:</Col>
										<Col className="col-lg-4 switcher-custom">
											<div className="col-sm-4 px-1">	
												<FormSelect onChange={e => this.changeChartType(e)}>
                                                        <option value="0">Daily</option>
                                                        <option value="1">Monthly</option>
                                                    </FormSelect>
											</div>
											{this.state.chartType === 1 ? 
											<div className="col-sm-7 px-1 year-switcher"><span className="arrow-left" onClick={e=>this.changeCurrentYear(0)}><i className="material-icons">keyboard_arrow_left</i></span>Selected Year: {this.state.currentYear} <span className="arrow-right" onClick={e=>this.changeCurrentYear(1)}><i className="material-icons">keyboard_arrow_right</i></span></div> 
											: null }
										</Col>
									</Row>
									{this.state.chartType === 0 ? 
                                    <Row noGutters className="page-header py-4 px-2">
                                        <Col className="col-lg-4">
                                            <Row noGutters className="py-3">
                                                <Container fluid className="col-sm-4 px-1">
                                                    <FormSelect onChange={e => this.updateFilterRange(e)}>
                                                        <option value="30">Last 30 days</option>
                                                        <option value="7">Last 7 days</option>
                                                        <option value="0">Custom...</option>
                                                    </FormSelect>
                                                </Container>
                                                <Container fluid className="col-sm-4 px-1">
                                                    {((this.state.customDateFilter) ?
                                                        <DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                    :
                                                        <DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                    )}
                                                </Container>
                                                <Container fluid className="col-sm-4 px-1">
                                                    {((this.state.customDateFilter) ?
                                                        <DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                    :
                                                        <DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                    )}
                                                </Container>
                                            </Row>
                                        </Col>
                                    </Row>
									: null }
									<Row>
									<Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block service-quality-block-page">
										<Row>
										
                                            <Container className="col-sm-9 px-2 service-quality-block-page" style={{marginLeft: 0}}>
												{((this.state.fetchingsAvgCarrierData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
												<Container id="chart-orders" className="carrier-graph col-sm-12 px-12" style={{marginLeft: 0}}>
													<canvas height="120" ref={this.avgCarrierDataChart} style={{ maxWidth: "100% !important" }} />
												</Container>
												{this.state.chartType === 0 ? 
													<Container>
												{((this.state.fetchingsAvgCarrierData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
													<Container fluid className="col-sm-11 carrier-crosstable pl-1 ml-4 mt-5">
														<Container className="qty-percent-switcher">
															<div className="qty-percent-switcher-title">Qty (or percent) of orders</div>
															<div className="col-sm-4 px-1">	
																<FormSelect onChange={e => this.changeDisplay(e)}>
																	<option value="0">Qty</option>
																	<option value="1">Percent</option>
																</FormSelect>
															</div>
														</Container>
														<table width="100%">
															<thead>
																<tr>
																	<th>carrier</th>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8+</th>
																	<th className="avg-table">average</th>
																	<th>sum</th>
																</tr>
															</thead>
															<tbody>
																{
																Object.entries(this.state.avgCarrierData_data.tabledata).map(([key,value],i) =>
																	<tr>
																		<td>{key}</td>
																		<td>{this.state.displayType === 0 ? value['d1'] : parseFloat((value['p1'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d2'] : parseFloat((value['p2'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d3'] : parseFloat((value['p3'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d4'] : parseFloat((value['p4'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d5'] : parseFloat((value['p5'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d6'] : parseFloat((value['p6'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d7'] : parseFloat((value['p7'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['dmore'] : parseFloat((value['pmore'])).toFixed(2)}</td>
																		<td className="avg-table">{parseFloat((this.state.avgCarrierData_data.avg_time_days_right[key])).toFixed(2)}</td>
																		<td>{value['orders']}</td>
																	</tr>
																) 
																}
															</tbody>
														</table>
													</Container>
												)}
												</Container>
												: 
												<Container>
												{((this.state.fetchingsAvgCarrierMonthData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
													<Container fluid className="col-sm-11 carrier-crosstable pl-1 ml-4 mt-5">
														<Container className="qty-percent-switcher">
															<div className="qty-percent-switcher-title">Qty (or percent) of orders</div>
															<div className="col-sm-4 px-1">	
																<FormSelect onChange={e => this.changeDisplay(e)}>
																	<option value="0">Qty</option>
																	<option value="1">Percent</option>
																</FormSelect>
															</div>
														</Container>
														<table width="100%">
															<thead>
																<tr>
																	<th>carrier</th>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8+</th>
																	<th className="avg-table">average</th>
																	<th>sum</th>
																</tr>
															</thead>
															<tbody>
																{
																Object.entries(this.state.avgCarrierMonthData_data.tabledata).map(([key,value],i) =>
																	<tr>
																		<td>{key}</td>
																		<td>{this.state.displayType === 0 ? value['d1'] : parseFloat((value['p1'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d2'] : parseFloat((value['p2'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d3'] : parseFloat((value['p3'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d4'] : parseFloat((value['p4'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d5'] : parseFloat((value['p5'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d6'] : parseFloat((value['p6'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['d7'] : parseFloat((value['p7'])).toFixed(2)}</td>
																		<td>{this.state.displayType === 0 ? value['dmore'] : parseFloat((value['pmore'])).toFixed(2)}</td>
																		<td className="avg-table">{parseFloat((this.state.avgCarrierMonthData_data.avg_time_days_right[key])).toFixed(2)}</td>
																		<td>{value['orders']}</td>
																	</tr>
																) 
																}
															</tbody>
														</table>
													</Container>
												)}
												</Container>
											 }
											</Container>
											
											<Container fluid className="col-sm-3">
											{((this.state.fetchingsAvgCarrierData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
												<div className="dashboard-filter-totals service-quality">
													<div className="dashboard-filter-map-totals-title dashboard-filter-map-totals-title-center">
                                                        The average time (days)
													</div>
													<div className="dashboard-filter-totals-content">
														<div className="row">
															<div className="dashboard-filter-totals-content-label col">
																
																{
                                                                    Object.entries(this.state.avgCarrierData_data.avg_time_days_right).map(([key,value],i) =>
                                                                        <div className="carrier-avg">
                                                                            <div className="carrier-avg-title">{key}</div>
                                                                            <Button outline theme="success">
                                                                                <div>{parseFloat((value)).toFixed(2)}</div>
                                                                            </Button>
                                                                        </div>
                                                                    ) 
                                                                    }
                                                                    
																</div>
															</div>
														</div>
													</div>
												)}
											</Container>
										</Row>
									</Container>
									</Row>
									
                                </CardBody>
                            
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCarrier);