import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import PageTitle from "../common/PageTitle";
import TableGrid from "../table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Chart from "chart.js";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class AvgPositionqtyPerorder extends Component {
	
    state = {
        avgpositionsperorder_data: null, 
        avgqtyperorder_data: null, 
        avgpositionsqtyperorder_data: null, 
		fetchingsAvgpositionsperorder:true,
        fetchingsAvgqtyperorder:true,
        fetchingsAvvgpositionsqtyperorder:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
	}

    avgpositionsperorderChart = React.createRef();
    avgqtyperorderChart = React.createRef();

    componentDidMount() {
        const avgpositionsperorderChart = this.avgpositionsperorderChart.current.getContext("2d");
        const avgqtyperorderChart = this.avgqtyperorderChart.current.getContext("2d");
        /* Self object */
        let self = this;
        axios.post(apiUrl + '/avgPositionsPerOrder', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgpositionsperorder_data: response.data, fetchingsAvgpositionsperorder:false});
            new Chart(avgpositionsperorderChart, {
				type: "line",
				data: {
					labels: response.data.avgPositionPerOrderLabels,
					datasets: [
						{
							label: "Average positions per order",
							fill: "start",
							data: response.data.avgPositionPerOrderData,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
        axios.post(apiUrl + '/avgQtyPerOrder', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgqtyperorder_data: response.data, fetchingsAvgqtyperorder:false});
            new Chart(avgqtyperorderChart, {
				type: "line",
				data: {
					labels: response.data.avgQtyPerOrderLabels,
					datasets: [
						{
							label: "Average qty per order",
							fill: "start",
							data: response.data.avgQtyPerOrderData,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
		axios.post(apiUrl + '/avgPositionQtyPerOrder', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgpositionsqtyperorder_data: response.data, fetchingsAvvgpositionsqtyperorder:false});
            
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({dateFilter: e.target.value, customDateFilter: true});
		} else {
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: "", dateFilterTo: ""}, this.updateDashboardData);
		}
	}
    

    updateDashboardData() {		
		/* Start fetching data */
		this.setState({avgpositionsperorder_data: null, fetchingsAvgpositionsperorder:true,avgqtyperorder_data: null, fetchingsAvgqtyperorder:true});	
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
		document.getElementById("chart-orders2").innerHTML = '';
		document.getElementById("chart-orders2").innerHTML = '<canvas height="120" id="order-chart2" style={{ maxWidth: "100% !important" }} />';
		/* Self object */
		let self = this;
		/* Avg carrier data */
        axios.post(apiUrl + '/avgPositionsPerOrder', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgpositionsperorder_data: response.data, fetchingsAvgpositionsperorder:false});
            const avgpositionsperorderChart = document.getElementById("order-chart").getContext("2d");
			new Chart(avgpositionsperorderChart, {
				type: "line",
				data: {
					labels: response.data.avgPositionPerOrderLabels,
					datasets: [
						{
							label: "Average positions per order",
							fill: "start",
							data: response.data.avgPositionPerOrderData,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
		axios.post(apiUrl + '/avgQtyPerOrder', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgqtyperorder_data: response.data, fetchingsAvgqtyperorder:false});
			const avgqtyperorderChart = document.getElementById("order-chart2").getContext("2d");
            new Chart(avgqtyperorderChart, {
				type: "line",
				data: {
					labels: response.data.avgQtyPerOrderLabels,
					datasets: [
						{
							label: "Average qty per order",
							fill: "start",
							data: response.data.avgQtyPerOrderData,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

	render() {	
		
			return (
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Average Positions & qty per order" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                                <CardBody>
                                        <Row noGutters className="page-header py-4 px-2 flex items-center">
                                                <Col className="col-lg-4">
                                                    <Row noGutters className="py-3">
                                                        <Container fluid className="col-sm-4 px-1">
                                                            <FormSelect onChange={e => this.updateFilterRange(e)}>
                                                                <option value="30">Last 30 days</option>
                                                                <option value="7">Last 7 days</option>
                                                                <option value="0">Custom...</option>
                                                            </FormSelect>
                                                        </Container>
                                                        <Container fluid className="col-sm-4 px-1">
                                                            {((this.state.customDateFilter) ?
                                                                <DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                            :
                                                                <DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                            )}
                                                        </Container>
                                                        <Container fluid className="col-sm-4 px-1">
                                                            {((this.state.customDateFilter) ?
                                                                <DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                            :
                                                                <DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                            )}
                                                        </Container>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        <Row>
									        <Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block service-quality-block-page">
										        <Row>
                                                    <Container className="col-sm-9 px-2 service-quality-block-page" style={{marginLeft: 0}}>
                                                        {((this.state.fetchingsAvgpositionsperorder) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
                                                        <Container id="chart-orders" className="col-sm-12 px-12" style={{marginLeft: 0}}>
                                                            <canvas height="120" ref={this.avgpositionsperorderChart} style={{ maxWidth: "100% !important" }} />
                                                        </Container>
                                                    </Container>
                                                    <Container className="col-sm-9 px-2" style={{marginLeft: 0}}>
                                                        {((this.state.fetchingsAvgqtyperorder) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
                                                        <Container id="chart-orders2" className="col-sm-12 px-12" style={{marginLeft: 0}}>
                                                            <canvas height="120" ref={this.avgqtyperorderChart} style={{ maxWidth: "100% !important" }} />
                                                        </Container>
                                                    </Container>
                                                </Row>
                                            </Container>
                                        </Row>
                                </CardBody>
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvgPositionqtyPerorder);