export const SHOW_LOGIN_MENU = 'SHOW_LOGIN_MENU';

export const SHOW_LOGIN_ERROR = 'SHOW_LOGIN_ERROR';
export const HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const LOGIN = 'LOGIN';
export const PROCESS_LOGIN = 'PROCESS_LOGIN';
export const LOGOUT = 'LOGOUT';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const LOAD_ORDERS = 'LOAD_ORDERS';

export const LOAD_USERS_ROLES = 'LOAD_USERS_ROLES';

export const LOAD_GRID_DATA = 'LOAD_GRID_DATA';

export const SET_MANDANT = 'SET_MANDANT';