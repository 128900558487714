import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Chart from "chart.js";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class ServiceReturns extends Component {
	
    state = {
        avgReturnsData_data: null, 
		fetchingsAvgReturnsData:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
        currentYear: new Date().getFullYear(),
        chartType: 1,
	}

    avgReturnsDataChart = React.createRef();

    componentDidMount() {
        /* Charts configuration */
        const avgReturnsDataChart = this.avgReturnsDataChart.current.getContext("2d");
        /* Self object */
		let self = this;
        axios.post(apiUrl + '/avgReturnsMonthlyData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo,
            current_year:self.state.currentYear,
		}).then(function (response) {
			self.setState({avgReturnsData_data: response.data, fetchingsAvgReturnsData:false});
            
			new Chart(avgReturnsDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: [
						{
							label: "Service quality: returns in hours",
							fill: "start",
							data: response.data.chart_data_returns,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    changeChartType(e) {
		if(e.target.value === '1') {
			this.setState({chartType: 1})
			this.changeMonthlyChart()
		
		} else {
			this.setState({chartType: 0})
			this.changeWeeklyChart()
		}
	}

    changeCurrentYear(e) {
		let currentYear = this.state.currentYear;
		if(e === 1) {
			currentYear = currentYear+1;
		} else {
			currentYear = currentYear-1;
		}
        if(this.state.chartType == 1) {
            this.setState({currentYear: currentYear}, () => {
                this.changeMonthlyChart()
            });
        } else {
            this.setState({currentYear: currentYear}, () => {
                this.changeWeeklyChart()
            }); 
        }
	}

    changeWeeklyChart() {
        this.setState({avgReturnsData_data: null, fetchingsAvgReturnsData:true,});	
        let self = this;
        /* Avg carrier data */
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
        axios.post(apiUrl + '/avgReturnsWeeklyData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			current_year: self.state.currentYear,
		}).then(function (response) {
			self.setState({avgReturnsData_data: response.data, fetchingsAvgReturnsData:false});
            const avgReturnsDataChart = document.getElementById("order-chart").getContext("2d");

			new Chart(avgReturnsDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: [
						{
							label: "Service quality: returns in hours",
							fill: "start",
							data: response.data.chart_data_returns,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    changeMonthlyChart() {
        /* Start fetching data */
		this.setState({avgReturnsData_data: null, fetchingsAvgReturnsData:true,});	
		let self = this;
		/* Avg carrier data */
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
		
		/* Avg carrier data */
        axios.post(apiUrl + '/avgReturnsMonthlyData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			current_year: self.state.currentYear,
		}).then(function (response) {
			self.setState({avgReturnsData_data: response.data, fetchingsAvgReturnsData:false});

            const avgReturnsDataChart = document.getElementById("order-chart").getContext("2d");

			new Chart(avgReturnsDataChart, {
				type: "line",
				data: {
					labels: response.data.chart_labels,
					datasets: [
						{
							label: "Service quality: returns in hours",
							fill: "start",
							data: response.data.chart_data_returns,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}


	render() {	
		
			return (
				<Container fluid className="main-content-container px-4 service-returns-report">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Returns Service Quality" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                                <CardBody>
                                    <Row noGutters className="page-header pt-4 pb-0 px-2">
                                        <Col className="col-lg-1 pb-0 pl-2 switcher-custom-title">Display Type:</Col>
                                        <Col className="col-lg-4 switcher-custom">
											<div className="col-sm-4 px-1">	
												<FormSelect value={this.state.chartType} onChange={e => this.changeChartType(e)}>
                                                        <option value="0">Weekly</option>
                                                        <option value="1">Monthly</option>
                                                    </FormSelect>
											</div>
											<div className="col-sm-7 px-1 year-switcher"><span className="arrow-left" onClick={e=>this.changeCurrentYear(0)}><i className="material-icons">keyboard_arrow_left</i></span>Selected Year: {this.state.currentYear} <span className="arrow-right" onClick={e=>this.changeCurrentYear(1)}><i className="material-icons">keyboard_arrow_right</i></span></div> 
										</Col>
                                    </Row>
                                        <Row>
									        <Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block service-quality-block-page">
										        <Row>
                                                    <Container className="col-sm-9 px-2 service-quality-block-page" style={{marginLeft: 0}}>
												        {((this.state.fetchingsAvgReturnsData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
                                                        <Container id="chart-orders" className="col-sm-12 px-12" style={{marginLeft: 0}}>
                                                            <canvas height="120" ref={this.avgReturnsDataChart} style={{ maxWidth: "100% !important" }} />
                                                        </Container>
                                                    </Container>
                                                </Row>
                                            </Container>
                                        </Row>
                                </CardBody>
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceReturns);