import React, {Component} from "react";
import { Container, Col, Card, CardHeader, CardBody } from "shards-react";
import TableGrid from "../../table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Expected extends Component {
	
	render() {	
		return (
			<Container fluid className="main-content-container detail-content px-4">
				<div className="detail-title">
					Expected Products
				</div>
				<TableGrid 
						table_name="item_stock_incoming" 
						key_column="sku"
						table_title="Expected Products" 
						detail_title="Expected Products details" 
						table_subtitle="Expected Products items" 
						table_columns={
							{
								sku: 'SKU',
								item_description: 'Product'
							}
						} 
						no_title
						order_columns={true} 
						column_filters={true} 
						action_columns={["V"]} 
						allow_create_new={false} 
						csv_export={true}
						user_mandants={this.props.user_mandants}
						per_page={30} 
						table_theme="bg-dark" 
					/>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expected);
