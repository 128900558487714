import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Button, ListGroup, ListGroupItem, Progress, CardFooter } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

class UserDetails extends Component {
		
	render() {
		
		let user_data = this.props.user_data.user_data;
		let user_roles = this.props.user_data.user_roles;
		let user_roles_string = [];
		
		user_roles.forEach(function (arrayItem) {
			user_roles_string.push(arrayItem.name);
		});
		
		return (
			<Card small className="mb-4 pt-3">
				<CardHeader className="text-center">
					<div className="mb-3 mx-auto">
						<img className="rounded-circle" src={require("./../../../assets/images/avatars/0.jpg")} alt={user_data.firstname + " " + user_data.lastname} width="110" />
					</div>
					<h4 className="mb-0">{user_data.firstname + " " + user_data.lastname}</h4>
					<span className="text-muted d-block mb-2">{ user_roles_string.join() }</span>
				</CardHeader>
				<CardFooter></CardFooter>
			</Card>
		);
	}
	
}

const mapStateToProps = state => {
    return {
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

