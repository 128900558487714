import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Chart from "chart.js";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class ServiceQuality extends Component {
	
    state = {
        avgOrderData_data: null, 
		fetchingsAvgOrderData:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
	}

    avgOrderDataChart = React.createRef();

    componentDidMount() {
        /* Charts configuration */
        const avgOrderDataChart = this.avgOrderDataChart.current.getContext("2d");
        /* Self object */
		let self = this;
		
		/* Collect mandants names */
		let userData = this.props.user_data;

		
		/* Avg order data */
		axios.post(apiUrl + '/avgOrderData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgOrderData_data: response.data, fetchingsAvgOrderData:false});
			new Chart(avgOrderDataChart, {
				type: "line",
				data: {
					labels: response.data.orders_day,
					datasets: [
						{
							label: "Avg order fulfillment time in hours",
							fill: "start",
							data: response.data.avg_orders_day,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({dateFilter: e.target.value, customDateFilter: true});
		} else {
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: "", dateFilterTo: ""}, this.updateDashboardData);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({dateFilterFrom: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		} else {
			this.setState({dateFilterTo: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		}
	}
    updateDashboardData() {		
		/* Start fetching data */
		this.setState({dashboard_data: null, fetchingData: true,orders_data:null,fetchingOrderData:true,openshipments_data: null, fetchingOpenShipmentsData:true,shipments_data: null, fetchingShipmentsData:true,returns_data: null, fetchingReturnsData:true,avgOrderData_data: null, fetchingsAvgOrderData:true});	
		
		/* Self object */
		let self = this;
		
		/* Collect mandants names */
		let userData = this.props.user_data;

        /* Avg order data */
		document.getElementById("chart-orders").innerHTML = '';
		document.getElementById("chart-orders").innerHTML = '<canvas height="120" id="order-chart" style={{ maxWidth: "100% !important" }} />';
		
		axios.post(apiUrl + '/avgOrderData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({avgOrderData_data: response.data, fetchingsAvgOrderData:false});
			const avgOrderDataChart = document.getElementById("order-chart").getContext("2d");
			new Chart(avgOrderDataChart, {
				type: "line",
				data: {
					labels: response.data.orders_day,
					datasets: [
						{
							label: "Avg order fulfillment time in hours",
							fill: "start",
							data: response.data.avg_orders_day,
							backgroundColor: "rgba(0,123,255,0.1)",
							borderColor: "rgba(0,123,255,1)",
							pointBackgroundColor: "#ffffff",
							pointHoverBackgroundColor: "rgb(0,123,255)",
							borderWidth: 1.5,
							borderWidth: 2,
							pointRadius: 5
							
						},
					]
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: false,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		}); 
    }

	render() {	
		
			return (
				<Container fluid className="main-content-container px-4 service-quality-report">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Service Quality" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
							    <CardBody>
                                    <Row noGutters className="page-header py-4 px-2">
                                        <Col className="col-lg-4">
                                            <Row noGutters className="py-3">
                                                <Container fluid className="col-sm-4 px-1">
                                                    <FormSelect onChange={e => this.updateFilterRange(e)}>
                                                        <option value="30">Last 30 days</option>
                                                        <option value="7">Last 7 days</option>
                                                        <option value="365">Last year</option>
                                                        <option value="0">Custom...</option>
                                                    </FormSelect>
                                                </Container>
                                                <Container fluid className="col-sm-4 px-1">
                                                    {((this.state.customDateFilter) ?
                                                        <DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                    :
                                                        <DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                    )}
                                                </Container>
                                                <Container fluid className="col-sm-4 px-1">
                                                    {((this.state.customDateFilter) ?
                                                        <DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                    :
                                                        <DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                    )}
                                                </Container>
                                            </Row>
                                        </Col>
                                    </Row>
									<Row>
									<Container fluid className="col-sm-12 px-2 dashboard-filter-blocks dashboard-totals-block service-quality-block-page">
										<Row>
											<Container className="col-sm-9 px-2 service-quality-block-page" style={{marginLeft: 0}}>
												{((this.state.fetchingsAvgOrderData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
												<Container id="chart-orders" className="col-sm-12 px-12" style={{marginLeft: 0}}>
													<canvas height="120" ref={this.avgOrderDataChart} style={{ maxWidth: "100% !important" }} />
												</Container>
											</Container>
											<Container fluid className="col-sm-3">
											{((this.state.fetchingsAvgOrderData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
												<div class="dashboard-filter-totals service-quality">
													<div class="dashboard-filter-map-totals-title dashboard-filter-map-totals-title-center">
														Average dispatch time
													</div>
													<div class="dashboard-filter-totals-content">
														<div class="row">
															<div class="dashboard-filter-totals-content-label col">
																<div class="average-dispatch">Per order</div>
																{((this.state.avgOrderData_data) ?
																	<Button outline theme="success">
																		{this.state.avgOrderData_data.average_dispatch_time} hour(s)
																	</Button>
																: null)}
																</div>
															</div>
														</div>
													</div>
												)}
											</Container>
										</Row>
									</Container>
									</Row>
                                </CardBody>
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceQuality);