import React, {Component} from "react";
import {connect} from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import { Container, Card, CardHeader, CardBody, CardFooter, ButtonGroup, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, FormSelect, FormTextarea, Button, Alert, NavLink } from "shards-react";
import * as actionCreators from '../../../store/actions/index';

class Contact extends Component {
	
	render() {		
	
        return (
			<Container fluid className="main-content-container container px-4">
				<Row>
					<Col className="container p-0">
						
						<div className="cms-page">
							
							<Card small className="mb-4">
								<CardHeader>
									<h3 className="m-0">Contact</h3>
								</CardHeader>
								
								<ListGroup flush>
								  <ListGroupItem className="p-3">
								
									<Row>
										<Col sm="12" md="6" lg="6">
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
											<br />
											<br />										
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
											<br />
											<br />										
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
											<br />
											<br />										
										</Col>
										<Col sm="12" md="6" lg="6">
											<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.258893918038!2d9.722267715940276!3d47.484869679176754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b12f20f4ca47b%3A0x88840242c29fc342!2sReitschulstra%C3%9Fe%207%2C%206923%20Lauterach!5e0!3m2!1sen!2sat!4v1589327035187!5m2!1sen!2sat" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
										</Col>
									</Row>
									<Row><Col>&nbsp;</Col></Row>
									<Row><Col>&nbsp;</Col></Row>
									
									<Row>
									  <Col>
										<h3 className="m-0">Contact Us</h3>
										<br />	
										<Form>
										  <Row form>
											{/* First Name */}
											<Col md="6" className="form-group">
											  <label htmlFor="feFirstName">First Name</label>
											  <FormInput
												id="feFirstName"
												placeholder="First Name"
												value="Sierra"
												onChange={() => {}}
											  />
											</Col>
											{/* Last Name */}
											<Col md="6" className="form-group">
											  <label htmlFor="feLastName">Last Name</label>
											  <FormInput
												id="feLastName"
												placeholder="Last Name"
												value="Brooks"
												onChange={() => {}}
											  />
											</Col>
										  </Row>
										  <Row form>
											{/* Email */}
											<Col md="6" className="form-group">
											  <label htmlFor="feEmail">Email</label>
											  <FormInput
												type="email"
												id="feEmail"
												placeholder="Email Address"
												value="sierra@example.com"
												onChange={() => {}}
												autoComplete="email"
											  />
											</Col>
											{/* Password */}
											<Col md="6" className="form-group">
											  <label htmlFor="fePassword">Password</label>
											  <FormInput
												type="password"
												id="fePassword"
												placeholder="Password"
												value="EX@MPL#P@$$w0RD"
												onChange={() => {}}
												autoComplete="current-password"
											  />
											</Col>
										  </Row>
										  
										  <Row form>
											{/* Description */}
											<Col md="12" className="form-group">
											  <label htmlFor="feDescription">Description</label>
											  <FormTextarea id="feDescription" rows="5" />
											</Col>
										  </Row>
										  <Button theme="accent">Submit Form</Button>
										</Form>
									  </Col>
									</Row>
								  </ListGroupItem>
								</ListGroup>
								
								
							</Card>
						</div>
					</Col>  
				</Row>
			</Container>
		);
	}
	
};

export default Contact;
