import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../common/PageTitle";
import TableGrid from "../table-grid/TableGrid";
import ReceiveGoodsForm from "./ReceiveGoodsForm";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Receivegoods extends Component {
    state = {
		user_mandants: this.props.user_mandants,
        receive_data: null,
        show_form: false,
    }

    componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadReceivingGoods', {
			user_mandants: self.state.user_mandants,
		}).then(function (response) {
			self.setState({receive_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
    
    showForm = () => {
		this.setState({show_form: true});
	}
	hideForm = () => {
		this.setState({show_form: false});
	}

    render() {	
        return(
            <div className="table-grid-detail">
            {(!(this.state.show_form) ? 
                <div>
                <Container fluid className="main-content-container px-4">
                    <Row noGutters className="page-header py-4 px-2">
                        <PageTitle title="Receive Goods" subtitle="Buyings" />
                    </Row>
                        <Row noGutters className="px-2">
                            <div>
                                <Button onClick={() => this.showForm()} theme="btn btn-outline-success"> [+] Create New</Button>
                            </div>
                        </Row>
                </Container>
                </div>
                :
                <div>
                    <Container fluid className="main-content-container px-4">
                        <Row noGutters className="page-header py-4 px-2">
                            <PageTitle title="Receive Goods" subtitle="Buyings" />
                        </Row>
                    </Container>
                    <div className="table-grid-detail-actions">
                        <a href="#" onClick={() => this.hideForm()}><i className="material-icons">clear</i></a>
                    </div>
                            <Row>
                            <Col>
                                <Card small>
                                    <CardBody>
                                        <ReceiveGoodsForm user_mandants={this.state.user_mandants} />
                                    </CardBody>
                                </Card>
                            </Col>
                            </Row>
                            <div className="table-grid-detail-footer">
                                <a href="#" onClick={() => this.hideDetail()}>&larr; Back to list</a>
                            </div>
                        </div>
                
                )}
                {(this.state.receive_data === null) ? 
                    <Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
                </Container> :
                <Container fluid className="main-content-container inventory-page">
                <Row noGutters className="inventory-header py-3 px-2">
                    <Container fluid className="col-sm-12">
                        
                        <TableGrid 
                            table_name="t_receiving_goods" 
                            key_column="id"
                            table_title="Receive Goods" 
                            detail_title="Receive Goods details" 
                            table_subtitle="Receive Goods items" 
                            table_columns={
                                {
                                    supplier: 'Supplier',
                                    skus: 'Ordered SKUs',
                                    receiving_date: 'Receiving date',
                                    created_date: 'Created date',
                                }
                            } 
                            no_title
                            order_columns={true} 
                            column_filters={true} 
                            action_columns={["V"]} 
                            allow_create_new={false} 
                            csv_export={true}
                            user_mandants={this.props.user_mandants}
                            per_page={30} 
                            table_theme="bg-dark" 
                        />
                        
                    </Container>
                </Row>
            </Container>
            }
                </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Receivegoods);