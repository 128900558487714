import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { Redirect } from "react-router-dom";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Return extends Component {

	state = {
		loading_data: false,
		detail_data: null,
		detail_id: this.props.id
	}
	
	componentDidMount() {
		// Load data
		if(this.state.detail_data === null) {	
			let self = this;
			axios.post(apiUrl + '/loadReturnData', {
				table_name: 't_return_data',
				return_id: this.state.detail_id
			}).then(function (response) {
				self.setState({detail_data: response.data});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}
	
	render() {	
		if(this.state.detail_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					Loading...
				</Container>
			)
			
		} else {
			
			// Create order items output
			let items = this.state.detail_data.return_items.map((row, index) => {
				return (
					<tr>
						<td>
							{row.name}
						</td>
						<td>
							{row.sku}
						</td>
						<td>
							{row.return_qty}
						</td>
						<td>
							{row.price_per_item + " " + row.currency}
						</td>
					</tr>
				);
			});
			if(this.state.detail_data.order_id) {
				var orderId = this.state.detail_data.order_id;
				if(orderId.includes('_')) {
					var order = orderId.split("_"); 
					orderId = order[1];
				}
			}
			return (
				<Container fluid className="main-content-container detail-content px-4">
					
					<div className="detail-title">
						Return # {this.state.detail_data.return_id}
                    </div>		
						
					<div className="detail-table">
						<table className="detail-table-primary" width="49%">
							<tbody>
								<tr>
									<th>Order ID</th>
									<td>{orderId}</td>
								</tr>
								<tr>
									<th>Customer Name</th>
									<td>{this.state.detail_data.customer_name}</td>
								</tr>
								<tr>
									<th>Return Date</th>
									<td>{this.state.detail_data.return_date}</td>
								</tr>
								<tr>
									<th>Return Amount</th>
									<td>{this.state.detail_data.return_amount + " " + this.state.detail_data.currency}</td>
								</tr>
								<tr>
									<th>Company</th>
									<td>{this.state.detail_data.company}</td>
								</tr>
							</tbody>
						</table>
						<table className="detail-table-secondary" width="49%">
							<tbody>
								<tr>
									<th>Return Reason</th>
									<td>{this.state.detail_data.return_reason_desc}</td>
								</tr>
								<tr>
									<th>Tracking ID</th>
									<td>{this.state.detail_data.tracking_id}</td>
								</tr>
								<tr>
									<th>Tracking URL</th>
									<td><a href={this.state.detail_data.tracking_url} target="_blank">{this.state.detail_data.tracking_url}</a></td>
								</tr>
							</tbody>
						</table>
					</div>
						
					<div className="items-title">
						Ordered Items
                    </div>		
					
					<div className="items-table">
						<table className="item-table-secondary" width="100%">
							<thead>
								<tr>
									<th>Product</th>
									<th>SKU</th>
									<th>Qty</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								{items}
							</tbody>
						</table>
					</div>
					
					
				</Container>
			)
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Return);
