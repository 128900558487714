import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
const axios = require("axios");

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Roles extends Component {
	
	state = {
		permission_options: {}
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadDataAsOptions', {
			table_name: '[dbo].[t_dashboard_rights]'
		}).then(function (response) {
			self.setState({permission_options: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {	
		return (
			<Container fluid className="main-content-container px-4">
				
				<TableGrid 
					table_name="t_dashboard_roles"
					key_column="id"
					table_title="Roles" 
					detail_title="Show Detail" 
					table_subtitle="Roles Management" 
					table_columns={
						{
							id: 'ID',
							name: 'Name'
						}
					}  
					allow_create_new={true}
					new_form_fields={
						{
							name: {
								type: 'varchar',
								label: 'Name',
								value: ''
							},
							permissions: {
								type: 'checkboxes',
								label: 'Permissions',
								options: this.state.permission_options,
								value: []
							}
						}
					}
					edit_form_fields={
						{
							id: {
								type: 'static',
								label: 'ID',
								value: ''
							},
							name: {
								type: 'varchar',
								label: 'Name',
								value: ''
							},
							permissions: {
								type: 'checkboxes',
								label: 'Permissions',
								options: this.state.permission_options,
								value: []
							}
						}
					}
					order_columns={true} 
					column_filters={true} 
					action_columns={["V","E","D"]} 
					per_page={30} 
					table_theme="bg-dark" 
				/>
								
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
