import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import Chart from "chart.js";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Deliveries extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		openshipments_data: null,
        url_filter: null,
		access_token: '',
	}

    componentWillMount(){
        const query = new URLSearchParams(this.props.location.search);
        const filter_param = query.get('filter');
        if(filter_param) {
            if(filter_param === 'no-label'){
                this.setState({url_filter:{column: 'no-label',value: '1'}});
            } else if(filter_param === 'not-printed') {
                this.setState({url_filter:{column: 'not-printed',value: '1'}});
            } else if(filter_param === 'not-shipped-yet') {
                this.setState({url_filter:{column: 'not-shipped-yet',value: '1'}});
            } else if(filter_param === 'no-progress-in-tracking') {
                this.setState({url_filter:{column: 'no-progress-in-tracking',value: '1'}});
            }
        }

		/* Self object */
		if(this.props.user_id === '29' || this.props.user_id === '1') {
			let self = this;
			console.log('test134')
			axios.post('dashboard_new/dashboard/apicalls/access_token.php', {
			}).then(function (response) {
				self.setState({access_token: response.data.access_token});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});

		}
    }

	componentDidMount() {
        
        
	}
	
	render() {	
						
		return (
			<Container fluid className="main-content-container inventory-page">
					
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Deliveries" subtitle="Deliveries" />
				</Row>
				
				<Row noGutters className="inventory-header py-3 px-2">
					<Container fluid className="col-sm-12">
						<TableGrid 
							table_name="deliveries" 
							key_column="order_id"
							table_title="Deliveries" 
							detail_title="Deliveries" 
							table_subtitle="Deliveries" 
							table_columns={
								{
									order_id: 'ID', 
									delivery_id: 'Delivery ID',	
									customer_name: 'Customer Name',
									created_datetime_order: 'Created At',
									ship_date: 'Shipment Date',
									customer_country_code: 'Country',
									order_amount_with_taxes_order_currency: 'Price',
									sum_ek_price: 'EK cost',
									sum_es_price: 'Cost price',
									currency: 'Currency',
									payment_type: 'Payment Type',
									tracking_url: 'Tracking url',
									note_on_delivery: 'Note on delivery',
									action_comment: 'Customer comment'
								}
							} 
                            special_filters={
                                [
                                    {
                                        color: '#c7f0d2',
                                        textColor: '#000',
                                        title: 'No Label',
                                        column: 'no-label',
                                        value: '1'
                                    },
                                    {
                                        color: '#e4f0c3',
                                        textColor: '#000',
                                        title: 'Not Printed',
                                        column: 'not-printed',
                                        value: '1'
                                    },
                                    {
                                        color: '#f5dfbd',
                                        textColor: '#000',
                                        title: 'Not shipped yet',
                                        column: 'not-shipped-yet',
                                        value: '1'
                                    },
									{
                                        color: 'rgba(243, 217, 73,0.4)',
                                        textColor: '#000',
                                        title: 'No progress in tracking',
                                        column: 'no-progress-in-tracking',
                                        value: '1'
                                    }
                                ]
                            }
							order_column="delivery_created_at"
							order_dir="desc"
							no_title
							order_columns={true} 
							column_filters={true} 
							action_columns={["V","AN","ED"]}
							allow_create_new={false} 
							csv_export={true}
							user_mandants={this.props.user_mandants}
							per_page={20} 
							table_theme="bg-dark"
                            active_filter={this.state.url_filter}
                            access_token={this.state.access_token}
							user_id_info={this.props.user_id}
						/>
					
					</Container>
				</Row>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliveries);
