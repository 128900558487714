import React, {Component} from "react";
import { Container, Row } from "shards-react";
import Chart from "chart.js";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class ReturnItem extends Component {

    state = {
		inventory_data: null,
        open_orders_data: null,
		returns_data: null,
		fetchingsReturnsData: true,
		selected_year: new Date().getFullYear(),
		current_year: new Date().getFullYear(),
		show_numbers_percentage: 0,
		display: 'table'
	}

	returnsDataChart = React.createRef();
	returnsDataChartPercent = React.createRef();

    componentDidMount() {
		/* Charts configuration */
        const returnsDataChart = this.returnsDataChart.current.getContext("2d");
		const returnsDataChartPercent = this.returnsDataChartPercent.current.getContext("2d");
		let mapChartDataColors = ["#1b4f72","#CD5C5C","#40E0D0","#a9cce3","#CCCCFF","#008000","#808080"];

		/* Self object */
		let self = this;
		
		/* Collect mandants names */
		let userData = this.props.user_data;

		/* Return monthly overview */
		axios.post(apiUrl + '/returnMonthlyData', {
			user_mandants: userData,
			item_id: this.props.id,
			selected_year: this.state.selected_year
		}).then(function (response) {
			self.setState({returns_data: response.data, fetchingsReturnsData:false});
			let setsData = [];
			let setsDataPercent = [];
			Object.entries(response.data.returns_month).map(([key,value],i) =>
			setsData.push(
				{
					label: "Returns - " + key,
					fill: "start",
					data: Object.entries(value).map(([key,value],i) => value['count']),
					backgroundColor: mapChartDataColors[i],
					borderColor: mapChartDataColors[i],
					borderWidth: 1.5,
					borderWidth: 2,
					pointRadius: 5
					}
				)
			)
			Object.entries(response.data.returns_month).map(([key,value],i) =>
				setsDataPercent.push(
					{
						label: "Returns - " + key,
						fill: "start",
						data: Object.entries(value).map(([key,value],i) => value['percent']),
						backgroundColor: mapChartDataColors[i],
						borderColor: mapChartDataColors[i],
						borderWidth: 1.5,
						borderWidth: 2,
						pointRadius: 5
						}
					)
			)
			
			new Chart(returnsDataChart, {
				type: "bar",
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'],
					datasets: setsData
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
			new Chart(returnsDataChartPercent, {
				type: "bar",
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'],
					datasets: setsDataPercent
				},
				options: {
				  responsive: true,
				  maintainAspectRatio: true,
				  layout: {
					  padding: {
						  top: 15,
						  right: 15,
						  left: 15,
						  bottom: 15
					  }
				  },
					tooltips: {
					  enabled: true,
					  mode: "index",
					  position: "nearest"
					}
				}
			});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	handleChangeDisplayData(display) {
		this.setState({display: display})
	}

	changeDisplayDataInReturnsTable(display) {
		this.setState({show_numbers_percentage: display})
	}
	
    render() {
        return(
			   <div className="page-detail-block">
					<Row className="row-title">
						<Container className="col-sm-12 title-icons-block">
							<h5>Returns</h5> 
						</Container>
					</Row>
					<Row>
						<Container className="col-sm-12 display-return-switcher returns-change-year">
							<div className="page-item">
								<span className={'' + ((this.state.show_numbers_percentage === 0) ? 'active-show' : '')} onClick={() => this.changeDisplayDataInReturnsTable(0)}>Absolute numbers</span> / <span className={'' + ((this.state.show_numbers_percentage === 1) ? 'active-show' : '')} onClick={() => this.changeDisplayDataInReturnsTable(1)}>Percent</span>
							</div>
						</Container>
					</Row>
					<div className="detail-table-changer-block">
						<div className={this.state.display ==='table' ? "show-icons show-table" : 'show-icons show-chart'}>
							<i onClick={() => this.handleChangeDisplayData('table')} className="material-icons table-show">table_chart</i>
							<i onClick={() => this.handleChangeDisplayData('chart')} className="material-icons chart-show">bar_chart</i>
						</div>
					<Row className={this.state.display ==='table' ? "show-display" : 'not-show-display'}>
					{((this.state.fetchingsReturnsData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
						
						<Container className="detail-info-table return-info-block">
							<Row>
							{this.state.returns_data ? 
								(Object.keys(this.state.returns_data.returns_month).length !== 0 ? 
								<Container className="col-sm-12">
									<div className="dashboard-totals-content table-with-sum">
										<table className={'' + ((this.state.show_numbers_percentage === 0) ? 'active-show-total' : 'noactive-show-total')}>
											<thead>
												<tr>
													<th>Year</th>
													<th>January</th>
													<th>February</th>
													<th>March</th>
													<th>April</th>
													<th>May</th>
													<th>June</th>
													<th>July</th>
													<th>August</th>
													<th>September</th>
													<th>October</th>
													<th>November</th>
													<th>December</th>
													<th>Sum</th>
												</tr>
											</thead>
											<tbody>
											{this.state.returns_data ? 
												Object.entries(this.state.returns_data.returns_month).map(([key,value],i) =>
												<tr>
												<td>{key}</td>
													{Object.entries(value).map(([key,value],i) =>
														<td key={key}>{value['count']}</td>
														)}
													{Object.entries(this.state.returns_data.return_sum).map(([keySum,valueSum],i) =>
														(key === keySum ? <td key={key}>{valueSum['count']}</td> : null )
													)}
												</tr>
												)  
												: null }
											</tbody>
										</table>
										</div>
										<div className="dashboard-totals-content table-with-sum">
										<table className={'' + ((this.state.show_numbers_percentage === 1) ? 'active-show-percent' : 'noactive-show-percent')}>
											<thead>
												<tr>
													<th>Year</th>
													<th>January</th>
													<th>February</th>
													<th>March</th>
													<th>April</th>
													<th>May</th>
													<th>June</th>
													<th>July</th>
													<th>August</th>
													<th>September</th>
													<th>October</th>
													<th>November</th>
													<th>December</th>
													<th>Total of percent</th>
												</tr>
											</thead>
											<tbody>
											{this.state.returns_data ? 
												Object.entries(this.state.returns_data.returns_month).map(([key,value],i) =>
												<tr>
												<td>{key}</td>
													{Object.entries(value).map(([key,value],i) =>
														<td key={key}>{value['percent']}%</td>
														)}
													{Object.entries(this.state.returns_data.return_sum).map(([keySum,valueSum],i) =>
														(key === keySum ? <td key={key}>{valueSum['percent']}%</td> : null )
													)}
												</tr>
												)  
												: null }
											</tbody>
										</table>
									</div>
									
								</Container>: 'No data') 
								: null}
							</Row>
						</Container>
					)}
					</Row>
					</div>
					<Row className={this.state.display ==='chart' ? "show-display" : 'not-show-display'}>
						<Container className={'' + ((this.state.show_numbers_percentage === 0) ? 'col-sm-10 active-show-total' : 'col-sm-10 noactive-show-total')}>
							{((this.state.fetchingsReturnsData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
							<Container id="chart_returns" className="col-sm-12 px-12" style={{marginLeft: 0}}>
								<canvas height="120" ref={this.returnsDataChart} style={{ maxWidth: "100% !important" }} />
							</Container>
						</Container>
						<Container className={'' + ((this.state.show_numbers_percentage === 1) ? 'col-sm-10 active-show-percent' : 'col-sm-10 noactive-show-percent')}>
							{((this.state.fetchingsReturnsData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : null )}
							<Container id="chart_returns" className="col-sm-12 px-12" style={{marginLeft: 0}}>
								<canvas height="120" ref={this.returnsDataChartPercent} style={{ maxWidth: "100% !important" }} />
							</Container>
						</Container>
					</Row>
			   </div>
        )
    }

}

export default ReturnItem;