
import React, { Component } from "react";
import { Container, Row, FormTextarea, Button } from "shards-react";
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class AddCommentsCustomer extends Component {

    state = {
        user_mandants: this.props.user_mandants,
        delivery_id: this.props.delivery_id,
        customer_comment: '',
        info_message: '',
    }

    componentDidMount() {
        let self = this;
        axios.post(apiUrl + '/selectCustomerComment', {
			user_mandants: this.props.user_mandants,
            delivery_id: this.props.delivery_id
		}).then(function (response) {
            self.setState({customer_comment: response.data.comment});
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    submitForm(e) {
        let self = this;
        console.log(this.state.customer_comment);
        axios.post(apiUrl + '/updateCustomerComment', {
			user_mandants: this.props.user_mandants,
            delivery_id: this.props.delivery_id,
            customer_comment: this.state.customer_comment
		}).then(function (response) {
            self.setState({info_message:response.data.info_message})
            axios.post(apiUrl + '/selectAllCustomerComment', {
                user_mandants: self.props.user_mandants,
            }).then(function (response) { 
                self.props.refreshAddNoteForm(response.data.comment);
                self.props.refreshAddNoteFormComment(response.data.customer_comment);
            }).catch(function (error) {
                console.log('ERROR:');
                console.log(error);
            });
        }).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }

    render() {

        return (
            <tr>
                <td colspan="999">
                    <Row className="new-item-form">
                        <Container className="px-4 ml-4">
                            <div className="table-grid-detail-actions"><span className="close-edit-form" onClick={this.props.onClickFun}><i className="material-icons">clear</i></span></div>
                            <div className="success-message-comments">{this.state.info_message}</div>
                            <form method="post" action="#" id="new-item-form">
                                <Row noGutters className="py-2">
                                    <Container fluid className="col-sm-12 px-1">
                                    <div class="py-2">
                                    Customer Comment
                                    </div>
                                    </Container>
                                    <Container fluid className="col-sm-6 px-1 ml-0">
                                        <FormTextarea className="col-sm-12 px-1"name="customer_comment" onChange={this.handleChange} value={this.state.customer_comment === '' ? '' : this.state.customer_comment} placeholder="Comment" />
                                    </Container>
                                </Row>
                                
                                <Row noGutters className="py-2">
                                    <Container fluid className="col-sm-12 px-1">
                                        <Button onClick={e => this.submitForm(e)} outline>Submit</Button>
                                    </Container>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </td>
            </tr>
        )
    }

};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
        user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentsCustomer);