import React from "react";

class NavbarToggle extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    document.body.classList.remove('modal-open');
  }

  handleClick() {
    if(document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
	/*
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
	*/
  }

  render() {
    return (
      <nav className="nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    )
  }
}

export default NavbarToggle;
