import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import DatePicker from "react-datepicker";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Returns extends Component {

	state = {
		custom_date_filter: false,
		returns_filter: 30,
		returns_filter_from: "",
		returns_filter_to: "",
		user_mandants: this.props.user_mandants,
		return_data: null,
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadReturnHeaderData', {
			user_mandants: self.state.user_mandants,
			returns_filter: self.state.returns_filter,
			returns_filter_from: self.state.returns_filter_from,
			returns_filter_to: self.state.returns_filter_to
		}).then(function (response) {
			self.setState({return_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateReturns = () => {
		let self = this;
		axios.post(apiUrl + '/loadReturnHeaderData', {
			user_mandants: self.state.user_mandants,
			returns_filter: self.state.returns_filter,
			returns_filter_from: self.state.returns_filter_from,
			returns_filter_to: self.state.returns_filter_to
		}).then(function (response) {
			self.setState({return_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({returns_filter: e.target.value, custom_date_filter: true}, this.updateReturns);
		} else if(e.target.value === 'today') {
			const today = new Date()
			this.setState({returns_filter: e.target.value, custom_date_filter: false, returns_filter_from: today, returns_filter_to: today}, this.updateReturns);
		} else if(e.target.value == 'yesterday') {
			const today = new Date()
			const yesterday = new Date(today)
			yesterday.setDate(yesterday.getDate() - 1)
			this.setState({returns_filter: e.target.value, custom_date_filter: false, returns_filter_from: yesterday, returns_filter_to: yesterday}, this.updateReturns);
		} else {
			this.setState({returns_filter: e.target.value, custom_date_filter: false, returns_filter_from: "", returns_filter_to: ""}, this.updateReturns);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({returns_filter_from: dateFormat}, this.updateReturns);
		} else {
			this.setState({returns_filter_to: dateFormat}, this.updateReturns);
		}
	}
	
	render() {	
	
		if(this.state.return_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<PageTitle title="Returns" subtitle="Returned items" />
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
				</Container>
			)
			
		} else {
			
			let topReturned = this.state.return_data.returned_items.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
			
			let topReasons = this.state.return_data.returned_reasons.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.label}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.count}</Container>
					</Row>
				</Container>;
			});
			
			return (
				<Container fluid className="main-content-container inventory-page dashboard-page return-page">
					
					<Row noGutters className="page-header py-4 px-2">
						<Col className="col-lg-8">
							<PageTitle title="Returns" subtitle="Returned items" />
						</Col>
						<Col className="col-lg-4">
							<Row noGutters className="py-3">
								<Container fluid className="col-sm-4 px-1">
									<FormSelect onChange={e => this.updateFilterRange(e)}>
										<option value="30">Last 30 days</option>
										<option value="today">Today</option>
										<option value="yesterday">Yesterday</option>
										<option value="7">Last week</option>
										<option value="365">Last year</option>
										<option value="0">Custom...</option>
									</FormSelect>
								</Container>
								<Container fluid className="col-sm-4 px-1">
									{((this.state.custom_date_filter) ?
										<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.returns_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
									:
										<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.returns_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
									)}
								</Container>
								<Container fluid className="col-sm-4 px-1">
									{((this.state.custom_date_filter) ?
										<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.returns_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
									:
										<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.returns_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
									)}
								</Container>
							</Row>
						</Col>
					</Row>
					
					{((this.state.return_data !== null) ? 
						<Row noGutters className="inventory-header">
							<Container fluid className="col-sm-12 px-2">
								<Row noGutters className="inventory-header-block">
									
									<Row fluid className="col-sm-12 px-2 return-top-counts">
										<Container className="col-sm-4 px-2">
											<h4>Total Returns Amount:</h4>
											<Row noGutters className="total-inventory-value py-2">
												<Button outline theme="danger">
													{this.state.return_data.total_returned_amount} &euro;
												</Button>
											</Row>
										</Container>
										<Container className="col-sm-4 px-2">
											<h4>Total Returns Quantity:</h4>
											<Row noGutters className="total-inventory-value py-2">
												<Button outline theme="warning">
													{this.state.return_data.total_returned_qty}
												</Button>
											</Row>
										</Container>
										<Container className="col-sm-4 px-2">
											<h4>Return rate:</h4>
											<Row noGutters className="total-inventory-value py-2">
												<Button outline theme="success">
													{this.state.return_data.rate_returns_percent}%
												</Button>
											</Row>
										</Container>
									</Row>
									
									<Row fluid className="col-sm-12 px-2">
										<Container fluid className="col-sm-5 px-2">
											<Row noGutters>
												<Container fluid className="col-sm-12">
													<h4>Top Returned Items:</h4>
												</Container>
											</Row>
											
											<Row noGutters className="top-5-products">
												{topReturned}
											</Row>
										</Container>
										
										<Container fluid className="col-sm-5 px-2">
											<Row noGutters>
												<Container fluid className="col-sm-12">
													<h4>Top Return Reasons:</h4>
												</Container>
											</Row>
											
											<Row noGutters className="top-5-products">
												{topReasons}
											</Row>
										</Container>
									</Row>
								</Row>
							</Container>
						</Row>
					: null)}
					
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							<TableGrid 
								table_name="t_return_data_grid" 
								key_column="return_id"
								table_title="Returns" 
								detail_title="Return detail" 
								table_subtitle="Returned items" 
								table_columns={
									{
										return_id: 'ID',
										order_id: 'Order ID',
										customer_name: 'Customer Name',
										return_reason_desc: 'Return Info',
										created_date: 'Created At',
										price: 'Price',
										currency: 'Currency',
										action_refund: 'Refunded'
									}
								} 
								order_column="created_date"
								order_dir="desc"
								no_title
								order_columns={true} 
								column_filters={true} 
								action_columns={["V"]} 
								allow_create_new={false} 
								csv_export={true}
								user_mandants={this.props.user_mandants}
								per_page={20} 
								table_theme="bg-dark" 
							/>
						
						</Container>
					</Row>
					
				</Container>
			)

		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Returns);
