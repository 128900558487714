import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class ItemsBasketReport extends Component {
	
	render() {	
		return (
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Items Basket" subtitle="Items Basket data" />
				</Row>
				<TableGrid 
					table_name="t_basket_analysis" 
					key_column="master_item"
					table_columns={
						{
							master_item: 'Main Item',
							count_masteritem: 'Number of orders with main item',
							contained_item: 'Item which was ordered together with main item ',
							confidence: 'Confidence',
							lift_item: 'Lift Item',
                            lift: 'Lift'
						}
					} 
                    order_column="count_masteritem"
					order_dir="desc"
					order_columns={true} 
					column_filters={true} 
					action_columns={[]} 
					csv_export={true}
					allow_create_new={false} 
					user_mandants={this.props.user_mandants}
					per_page={30} 
					table_theme="bg-dark" 
				/>
				
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsBasketReport);
