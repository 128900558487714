import React, {Component} from "react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';
import {Container,FormSelect,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class UserActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      all_mandants: null,
		  fetch_all_mandants: true,
      selectedMandants: this.props.user_mandants.includes("20") ? '01' : this.props.user_mandants,
      last_update: null
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount() {	
    let self = this;
    axios.post(apiUrl + '/getAllMandants', {
			user_mandants: this.props.user_mandants,
		}).then(function (response) {
			self.setState({all_mandants: response.data,fetch_all_mandants:false})
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
  }

  changeMandants = (e) => {
		if(e.target.value === "01") {
			// this.props.setMandant(["01","20"]);
			console.log('set');
			this.props.setMandant(["01","20"], function() {
				console.log(this.props.user_mandants);
			  });
		} else {
			this.props.setMandant([e.target.value], function() {
				console.log(this.props.user_mandants);
			  });
		}
		setTimeout(()=>{
			window.location.reload(false);
		}, 500);
	}

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
	
    return (
      <div className="flex">
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../assets/images/avatars/0.jpg")}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{this.props.user_data.user_data.firstname + " " + this.props.user_data.user_data.lastname}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="profile">
            <i className="material-icons">&#xE7FD;</i> View Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/logout" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
        {((this.props.user_id === '25') || (this.props.user_data.user_roles[0]['id'] == 1)) ? 
									<Container fluid className="col-sm-3 px-1 mandant-selector">
											{this.state.fetch_all_mandants ? '' : 
											<FormSelect value={this.state.selectedMandants} onChange={e => this.changeMandants(e)}>
												<option value="0">Select mandant</option>
											{Object.entries(this.state.all_mandants.mandants_names).map(([key,value],i) =>
													<option value={value['cpy']}>{value['name']}</option>
												)}
										</FormSelect>
									}
									</Container>
									: null}
                  {this.state.fetch_all_mandants ? '' : 
                  <div className="last-update-info">
                    <div>Data last updated:</div>
                    <div>{this.state.all_mandants.last_update}</div>
                  </div>
                }
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        user_data: state.glb.user_data,
        user_mandants: state.glb.user_mandants,
        login_failed: state.glb.login_failed
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout()),
    setMandant: (user_mandants) =>  dispatch(actionCreators.setMandant(user_mandants)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);


