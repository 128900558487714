import React, {Component} from "react";
import { Container, Row } from "shards-react";

import {connect} from 'react-redux';
import * as actionCreators from '../../../../../store/actions/index';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class ItemDetail extends Component {

    state = {
		itemdetail_data: null,
		fetchingsItemdetailData: true,
	}


    componentDidMount() {
		/* Self object */
		let self = this;

		/* Return monthly overview */
		axios.post(apiUrl + '/showDetailItem', {
			user_mandants: this.props.user_mandants,
			item_id: this.props.id
		}).then(function (response) {
			self.setState({itemdetail_data: response.data, fetchingsItemdetailData:false});
            
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

    render() {
        if(this.props.id){
            var sku = this.props.id;
            if(sku.includes('_')) {
                var skuItem = sku.split("_"); 
                sku = skuItem[1];
            }
        }
        return(
			   <div className="page-detail-block">
					<Row className="row-title">
						<Container className="col-sm-12">
								<h5>Detail</h5>  
						</Container>
                        <Container className="col-sm-12 detail-info">
                            {this.state.itemdetail_data ?
                            <Container className="col-sm-12 detail-info-block">
                                <div className="detail-sku">
                                    <span>{sku}</span>
                                </div>
                                <div className="detail-info-item-order">
                                    <div className="detail-info-item">
                                        <div className="detail-info-row">
                                            <span className="detail-info-column-title">Name</span>
                                            <span className="detail-info-column-title">EP</span>
                                            <span className="detail-info-column-title">Stock qty</span>
                                            <span className="detail-info-column-title">Total Amount</span>
                                        </div>
                                        <div className="detail-info-row">
                                            <span className="detail-info-column-value">{this.state.itemdetail_data.name_item}</span>
                                            <span className="detail-info-column-value">{this.state.itemdetail_data.ep_price}</span>
                                            <span className="detail-info-column-value">{this.state.itemdetail_data.stock_qty}</span>
                                            <span className="detail-info-column-value">{this.state.itemdetail_data.totalAmount}</span>
                                        </div>
                                    </div>
                                    <div className="detail-info-order">
                                        <div className="detail-info-row">
                                            <span className="detail-info-column-title">Supplier Name</span>
                                            <span className="detail-info-column-title">Purchase Net Price</span>
                                            <span className="detail-info-column-title">Purchase Currency</span>
                                            <span className="detail-info-column-title">Purchase Order Date</span>
                                        </div>
                                        {Object.entries(this.state.itemdetail_data.supplier).map(([key,value],i) =>
                                            <div className="detail-info-row">
                                                {Object.entries(value).map(([key,value],i) =>
                                                    <span className="detail-info-column-value">{value}</span>
                                                )}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </Container>
                            : null
                        }
                        </Container>
					</Row>
                    <Row className="open-purchase">
                        <Container className="col-sm-12">
							<h5>Open Purchase orders</h5>  
						</Container>
                    <Container className="detail-info-table open-orders">
                    {
                        this.state.itemdetail_data ?
                        
                        (this.state.itemdetail_data.open_orders) ?
                            <table>
                                <thead>
                                    <th>Order ID</th>
                                    <th>Order date</th>
                                    <th>QTY</th>
                                    <th>Gross price</th>
                                    <th>Net price</th>
                                    <th>Expected Delivery</th>
                                    <th>Currency</th>
                                    <th>Open</th>
                                </thead>
                                <tbody>
                                    {
                                    Object.entries(this.state.itemdetail_data.open_orders).map(([key,value],i) => <tr>
                                    <td>{key}</td>
                                    <td>{value['order_date']}</td>
                                    <td>{value['qty']}</td>
                                    <td>{value['gross_price']}</td>
                                    <td>{value['net_price']}</td>
                                    <td>{value['expected_delivery']}</td>
                                    <td>{value['currency']}</td>
                                    <td>{value['opens']}</td>
                                    </tr>)  
                                    }   
                                </tbody>
                            </table>
                            : <div>No Open Orders</div>
                        : null
                        }
                        </Container>
                    </Row>
			   </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetail);