import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import LowstockDetail from "./details/Lowstock"
import ExpectedDetail from "./details/Expected"
import OutofstockkDetail from "./details/Outofstock"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Inventory extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		inventory_data: null,
		custom_date_filter: false,
		bestsellers_filter: 30,
		bestsellers_filter_from: "",
		bestsellers_filter_to: "",
		show_detail: false,
		show_type_detail: "",
		show_inventory_header: true
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadInventoryData', {
			user_mandants: self.state.user_mandants,
			
		}).then(function (response) {
			self.setState({inventory_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateBestsellers = () => {
		let self = this;
		axios.post(apiUrl + '/loadInventoryData', {
			user_mandants: self.state.user_mandants,
			bestsellers_filter: self.state.bestsellers_filter,
			bestsellers_filter_from: self.state.bestsellers_filter_from,
			bestsellers_filter_to: self.state.bestsellers_filter_to,
			
		}).then(function (response) {
			self.setState({inventory_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({bestsellers_filter: e.target.value, custom_date_filter: true}, this.updateBestsellers);
		} else {
			this.setState({bestsellers_filter: e.target.value, custom_date_filter: false, bestsellers_filter_from: "", bestsellers_filter_to: ""}, this.updateBestsellers);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({bestsellers_filter_from: dateFormat}, this.updateBestsellers);
		} else {
			this.setState({bestsellers_filter_to: dateFormat}, this.updateBestsellers);
		}
	}

	showDetail = (type) => {
		this.setState({show_detail: true, show_type_detail: type});
	}
	hideDetail = (type) => {
		this.setState({show_detail: false, show_type_detail: ''});
	}

	changeShowHeader = (visibility) => {
		this.setState({show_inventory_header:visibility})
	}
	
	render() {	
	
		if(this.state.inventory_data === null) {
			
			return (
				
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<PageTitle title="Inventory" subtitle="Product data" />
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
				</Container>
			)
			
		} else {
			
			/* Process bestsellers */
			let self = this;
			
			/*
			let bestsellers = this.state.inventory_data.bestsellers.map(function(item, i){
								  return <Container noGutters className="top-5-products-item">
											<Row noGutters>
												<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
												<Container fluid className="col-sm-6 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
												<Container fluid className="col-sm-2 top-5-products-item-qty px-2">{item.qty}</Container>
											</Row>
										 </Container>;
								  });
			*/
			
			let topStock = this.state.inventory_data.top_stock.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.qty}</Container>
					</Row>
				</Container>;
			});
			
			let productsWeight = this.state.inventory_data.products_weights.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-5 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
						<Container fluid className="col-sm-3 top-5-products-item-qty px-2">{item.percent + "%"}</Container>
					</Row>
				</Container>;
			});

			
			
			return (
				<div>
				{((this.state.show_detail) ? 
					<div className="table-grid-detail">
						{((this.state.show_type_detail == 'lowstock') ?
							<div>
								<div className="table-grid-detail-actions">
									<a href="javascript://" onClick={() => this.hideDetail()}><i className="material-icons">clear</i></a>
								</div>
								<Row>
									<Col>
										<Card small>
											<CardBody>
												<LowstockDetail user_mandants={this.state.user_mandants} count_items={this.state.inventory_data.low_stock} />
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="table-grid-detail-footer">
									<a href="#" onClick={() => this.hideDetail()}>&larr; Back to list</a>
								</div>
							</div>
						: <div>
							{((this.state.show_type_detail == 'outofstock') ?
							<div>
								<div className="table-grid-detail-actions">
									<a href="javascript://" onClick={() => this.hideDetail()}><i className="material-icons">clear</i></a>
								</div>
								<Row>
									<Col>
										<Card small>
											<CardBody>
												<OutofstockkDetail user_mandants={this.state.user_mandants} count_items={this.state.inventory_data.out_of_stock} />
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="table-grid-detail-footer">
									<a href="#" onClick={() => this.hideDetail()}>&larr; Back to list</a>
								</div>
							</div> : <div>
							{((this.state.show_type_detail == 'expected') ? 
							<div>
								<div className="table-grid-detail-actions">
									<a href="javascript://" onClick={() => this.hideDetail()}><i className="material-icons">clear</i></a>
								</div>
								<Row>
									<Col>
										<Card small>
											<CardBody>
												<ExpectedDetail user_mandants={this.state.user_mandants} count_items={this.state.inventory_data.expected} />
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="table-grid-detail-footer">
									<a href="#" onClick={() => this.hideDetail()}>&larr; Back to list</a>
								</div>
							</div>
							 : <div></div> )}
							</div> 
							)}
						</div>
						 )}
					</div>
				:
				<Container fluid className="main-content-container inventory-page">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Inventory" subtitle="Products data" />
					</Row>
					
					{this.state.show_inventory_header ? 
					<Row noGutters className="inventory-header">
						<Container fluid className="col-sm-7 px-2">
							<Row noGutters className="inventory-header-block">
								
								<Container fluid className="col-sm-3 px-2">
									<h4>Total Inventory Value:</h4>
									<Row noGutters className="total-inventory-value py-2">
										<Button outline theme="success">
											{this.state.inventory_data.total_inventory_value} &euro;
										</Button>
									</Row>
									{this.state.inventory_data.stock_on_hand ? 
									<div>
									<h4>Total Inventory Value (waterdrop):</h4>
									<Row noGutters className="total-inventory-value py-2">
										<Button outline theme="success">
											{this.state.inventory_data.stock_on_hand} &euro;
										</Button>
									</Row></div>
									: ''
								}
								</Container>
								
								<Container fluid className="col-sm-5 px-2">
									<Row noGutters>
										<Container fluid className="col-sm-12">
											<h4>Top Stock:</h4>
										</Container>
										
										{/*
										<Container fluid className="col-sm-9">
											<Row noGutters>
												<Container fluid className="col-sm-4 px-1">
													<FormSelect onChange={e => this.updateFilterRange(e)}>
													  <option value="30">Last 30 days</option>
													  <option value="7">Last week</option>
													  <option value="365">Last year</option>
													  <option value="0">Custom...</option>
													</FormSelect>
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.bestsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
													:
														<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.bestsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
													)}
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.bestsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
													:
														<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.bestsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
													)}
												</Container>
											</Row>
										</Container>
										*/}
									</Row>
									
									<Row noGutters className="top-5-products">
										{topStock}
									</Row>
								</Container>
								
								<Container fluid className="col-sm-4 px-2">
									<Row noGutters>
										<Container fluid className="col-sm-12">
											<h4>Products weights:</h4>
										</Container>
									</Row>
									
									<Row noGutters className="top-5-products">
										{productsWeight}
									</Row>
								</Container>
							</Row>
						</Container>
						
						<Container fluid className="col-sm-5 px-2">
							<Row noGutters className="inventory-header-block">
								<h4>Total of Products:</h4>
								
								<Container noGutters className="total-products">
									<Row noGutters>
										<Container fluid className="col-sm-4 total-products-item">
											Expected Products
											<Row noGutters className="total-inventory-value py-4">
												<Button outline theme="info" onClick={() => this.showDetail("expected")}>
													{this.state.inventory_data.expected}
												</Button>
											</Row>
										</Container>
										<Container fluid className="col-sm-4 total-products-item">
											Low Stock Products
											<Row noGutters className="total-inventory-value py-4">
												<Button outline theme="warning" onClick={() => this.showDetail("lowstock")}>
													{this.state.inventory_data.low_stock}
												</Button>
											</Row>
										</Container>
										<Container fluid className="col-sm-4 total-products-item">
											Out of Stock Products
											<Row noGutters className="total-inventory-value py-4">
												<Button outline theme="danger" onClick={() => this.showDetail("outofstock")}>
													{this.state.inventory_data.out_of_stock}
												</Button>
											</Row>
										</Container>
									</Row>
								</Container>
								
							</Row>
						</Container>
					</Row>
					: null }
					
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							<TableGrid 
								table_name="t_inventory_data" 
								key_column="sku"
								table_title="Items" 
								detail_title="Item data" 
								table_subtitle="Items data" 
								table_columns={
									{
										sku: 'SKU',
										description: 'Name',
										ean: 'EAN',
										zolltarifnumber: 'Customs tariff number',
										weight: 'Weight',
										// cost_price: 'Cost',
										// ek_cost: 'Purchase price',
										stock_amount_total: 'Total value',
										stk_available_quantity: 'Sellable qty',
										allocated_qty: 'Reserved Qty',
										available_stock: 'Total Qty',
										expected_stock: 'Expected Qty',
										location_type_desc: 'Stock label',
										location_type: 'Stock type',
										LANMES_0: 'ABC Class'
									}
								} 
								table_columns_extra={
									{
										available_stock: {'class': 'align-center'},
										expected_stock: {'class': 'align-center'}
									}
								}
								special_filters={
									[
										{
											color: '#3bc265',
											textColor: '#000',
											title: 'Selling stock',
											column: 'location_type',
											value: 'KS,NS,MK'
										},
										{
											color: '#f1cb31',
											textColor: '#000',
											title: 'Return stock',
											column: 'location_type',
											value: 'RT'
										},
										{
											color: '#ff575b',
											textColor: '#000',
											title: 'Defective stock',
											column: 'location_type',
											value: 'DL,VL,DE'
										}
									]
								}
								order_columns={true} 
								no_title
								column_filters={true} 
								csv_export={true}
								allow_create_new={false} 
								user_mandants={this.props.user_mandants}
								action_columns={["BV"]} 
								per_page={30} 
								table_theme="bg-dark" 
								show_header_detail={this.changeShowHeader}
							/>
						</Container>
					</Row>
					
				</Container>
				)}
				</div>
			)
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
