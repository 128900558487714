import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import {connect} from 'react-redux';
import * as actionCreators from '../store/actions/index';
import routes from "../routes";
import withTracker from "../withTracker";

import "../assets/bootstrap.min.css";
import "../assets/shards-dashboards.1.1.0.min.css";
import "../assets/main.scss";
import "../assets/custom.css";
  
class Dashboard extends Component {
	
	render() {		
	
        return (
			<Router basename={process.env.REACT_APP_BASENAME || ""}>
				<div>
					
					{((this.props.show_loading) ?
						<div className="loading">
							<div className="overlay"></div>
							<div className="spinner"></div>
						</div>
					: null)}
					
					
					{routes.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={withTracker(props => {
									return (
										<route.layout {...props}>
											<route.component {...props} />
										</route.layout>
									);
								})}
							/>
						);
					})}
				</div>
			</Router>
		)
	
	}
}

const mapStateToProps = state => {
    return {
        show_loading: state.glb.show_loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
		showLoading: () =>  dispatch(actionCreators.showLoading()),
		hideLoading: () =>  dispatch(actionCreators.hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);