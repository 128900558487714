import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import DatePicker from "react-datepicker";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class Bestsellers extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		bestseller_data: null,
		custom_date_filter: false,
		bestsellers_filter: 30,
		bestsellers_filter_from: "",
		bestsellers_filter_to: "",
		slowsellers_filter: 30,
		slowsellers_filter_from: "",
		slowsellers_filter_to: ""
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadBestsellerHeaderData', {
			user_mandants: self.state.user_mandants,
		}).then(function (response) {
			self.setState({bestseller_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateBestsellers = () => {
		let self = this;
		axios.post(apiUrl + '/loadBestsellerHeaderData', {
			user_mandants: self.state.user_mandants,
			bestsellers_filter: self.state.bestsellers_filter,
			bestsellers_filter_from: self.state.bestsellers_filter_from,
			bestsellers_filter_to: self.state.bestsellers_filter_to,
			slowsellers_filter: self.state.slowsellers_filter,
			slowsellers_filter_from: self.state.slowsellers_filter_from,
			slowsellers_filter_to: self.state.slowsellers_filter_to
		}).then(function (response) {
			self.setState({bestseller_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateSlowsellers = () => {
		let self = this;
		axios.post(apiUrl + '/loadBestsellerHeaderData', {
			user_mandants: self.state.user_mandants,
			slowsellers_filter: self.state.slowsellers_filter,
			slowsellers_filter_from: self.state.slowsellers_filter_from,
			slowsellers_filter_to: self.state.slowsellers_filter_to,
			bestsellers_filter: self.state.bestsellers_filter,
			bestsellers_filter_from: self.state.bestsellers_filter_from,
			bestsellers_filter_to: self.state.bestsellers_filter_to
		}).then(function (response) {
			self.setState({bestseller_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({bestsellers_filter: e.target.value, custom_date_filter: true}, this.updateBestsellers);
		} else {
			this.setState({bestsellers_filter: e.target.value, custom_date_filter: false, bestsellers_filter_from: "", bestsellers_filter_to: ""}, this.updateBestsellers);
		}
	}
	
	updateSlowFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({slowsellers_filter: e.target.value, custom_date_filter: true}, this.updateSlowsellers);
		} else {
			this.setState({slowsellers_filter: e.target.value, custom_date_filter: false, bestsellers_filter_from: "", bestsellers_filter_to: ""}, this.updateSlowsellers);
		}
	}
	
	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({bestsellers_filter_from: dateFormat}, this.updateBestsellers);
		} else {
			this.setState({bestsellers_filter_to: dateFormat}, this.updateBestsellers);
		}
	}
	
	updateSlowFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({slowsellers_filter_from: dateFormat}, this.updateSlowsellers);
		} else {
			this.setState({slowsellers_filter_to: dateFormat}, this.updateSlowsellers);
		}
	}
	
	
	render() {	
	
		if(this.state.bestseller_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<PageTitle title="Bestsellers" subtitle="Bestselling products" />
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
				</Container>
			)
			
		} else {
			
			/* Process slow / bestsellers */
			let self = this;
			let bestsellers = this.state.bestseller_data.bestsellers.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
							<Row noGutters>
								<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
								<Container fluid className="col-sm-7 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
								<Container fluid className="col-sm-1 top-5-products-item-qty px-2">{item.qty}</Container>
							</Row>
						</Container>;
				}
			);
			let slowsellers = this.state.bestseller_data.slowsellers.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
							<Row noGutters>
								<Container fluid className="col-sm-4 top-5-products-item-name px-2">{item.name}</Container>
								<Container fluid className="col-sm-7 top-5-products-item-progress px-2"><span className="top-5-progress-bar"><span className="top-5-progress-bar-fill" style={{width: item.percent + "%"}}></span></span></Container>
								<Container fluid className="col-sm-1 top-5-products-item-qty px-2">{item.qty}</Container>
							</Row>
						</Container>;
				}
			);
			if(this.state.bestseller_data.bestseller_product){
				var best_sku = this.state.bestseller_data.bestseller_product;
				if(best_sku.includes('_')) {
					var skuItem = best_sku.split("_"); 
					best_sku = skuItem[1];
				}
			}
			if(this.state.bestseller_data.slowseller_product){
				var slow_sku = this.state.bestseller_data.slowseller_product;
				if(slow_sku.includes('_')) {
					var skuItemSlow = slow_sku.split("_"); 
					slow_sku = skuItemSlow[1];
				}
			}
			return (
			
				<Container fluid className="main-content-container inventory-page">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Bestsellers" subtitle="Bestselling products" />
					</Row>
					
					<Row noGutters className="inventory-header">
						<Container fluid className="col-sm-6 px-2">
							<Row noGutters className="inventory-header-block">
								
								<Container fluid className="col-sm-4">
									<h4>Bestselling Products:</h4>
									<Row noGutters className="total-inventory-value py-2">
										<Button outline theme="success" title={best_sku}>
											{best_sku}
										</Button>
									</Row>
								</Container>
								
								<Container fluid className="col-sm-8">
									<Row noGutters>
										<Container fluid className="col-sm-12">
											<Row noGutters>
												<Container fluid className="col-sm-4 px-1">
													<FormSelect onChange={e => this.updateFilterRange(e)}>
													  <option value="30">Last 30 days</option>
													  <option value="7">Last week</option>
													  <option value="365">Last year</option>
													  <option value="0">Custom...</option>
													</FormSelect>
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.bestsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
													:
														<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.bestsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
													)}
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.bestsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
													:
														<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.bestsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
													)}
												</Container>
											</Row>
										</Container>
									</Row>
									
									<Row noGutters className="top-5-products">
										{bestsellers}
									</Row>
								</Container>
							</Row>
						</Container>
						<Container fluid className="col-sm-6 px-2">
							<Row noGutters className="inventory-header-block">
								
								<Container fluid className="col-sm-4">
									<h4>Slow Sellers:</h4>
									<Row noGutters className="total-inventory-value py-2">
										<Button outline theme="success" title={slow_sku}>
											{slow_sku}
										</Button>
									</Row>
								</Container>
								
								<Container fluid className="col-sm-8">
									<Row noGutters>
										<Container fluid className="col-sm-12">
											<Row noGutters>
												<Container fluid className="col-sm-4 px-1">
													<FormSelect onChange={e => this.updateSlowFilterRange(e)}>
													  <option value="30">Last 30 days</option>
													  <option value="7">Last week</option>
													  <option value="365">Last year</option>
													  <option value="0">Custom...</option>
													</FormSelect>
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.slowsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateSlowFilterDate(e, 'filter-from')} />
													:
														<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.slowsellers_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateSlowFilterDate(e, 'filter-from')} />
													)}
												</Container>
												<Container fluid className="col-sm-4 px-1">
													{((this.state.custom_date_filter) ?
														<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.slowsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateSlowFilterDate(e, 'filter-to')} /> 
													:
														<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.slowsellers_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateSlowFilterDate(e, 'filter-to')} /> 
													)}
												</Container>
											</Row>
										</Container>
									</Row>
									
									<Row noGutters className="top-5-products">
										{slowsellers}
									</Row>
								</Container>
							</Row>
						</Container>
					</Row>
					
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
							<TableGrid 
								table_name="item_bestseller" 
								key_column="sku"
								table_title="Bestsellers" 
								detail_title="Bestsellers" 
								table_subtitle="Bestsellers" 
								table_columns={
									{ 
										sku: 'SKU',
										cpy: 'cpy',
										STOFCY_0: 'stock facility',
										name: 'Name',
										ean: 'EAN',
										date: 'Date',
										qty_ordered: 'Ordered Quantity',
										LANMES_0: 'ABC Class'
									}
								} 
								order_column="date"
								order_dir="desc"
								no_title
								order_columns={true} 
								column_filters={true} 
								action_columns={[]} 
								csv_export={true}
								allow_create_new={false} 
								user_mandants={this.props.user_mandants}
								per_page={30} 
								table_theme="bg-dark" 
							/>
						</Container>
					</Row>
					
				</Container>
				
			)			
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bestsellers);
