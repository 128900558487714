import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import mainLogo from '../../../assets/images/logo.png';
import * as actionCreators from '../../../store/actions/index';

import {connect} from 'react-redux';
import { Container, Navbar, Row, Col, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

class LoginNavbar extends Component {
  
	showLoginMenu = (e) => {
		this.props.showLoginMenu({});		
	}
  
	render() {

		let menuClasses = ((this.props.show_login_menu) ? "col-lg-6 col-sm-6 col-md-8 login-navigation show-navigation" : "col-lg-6 col-sm-6 col-md-8 navbar-menu login-navigation");
	
        return (
			<div className="main-navbar bg-white">
			  <Container className="p-0 login-navbar">
				<Row className="login-navbar p-0">
					<Col className="col-lg-6 col-sm-6 col-md-4 login-logo-container">
						<button onClick={this.showLoginMenu} type="button" className="login-navbar-toggle">
							<span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span>
						</button>
						
						<NavLink tag={Link} to={"/"}>
							<img
								className="login-logo"
								src={mainLogo}
								alt="MH Dashboard"
							/>
						</NavLink>
					</Col>
					
					{ /*
					<Col className={menuClasses}>
						<Nav>
							<NavItem>
								<NavLink tag={Link} to={"/"}>
									Home
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to={"/about-project"}>
									About Project
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to={"/services"}>
									Services
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to={"/contact"}>
									Contact
								</NavLink>
							</NavItem>
						</Nav>
					</Col>
					*/ }
					
				</Row>
			  </Container>
			</div>
		)
	}	
}

const mapStateToProps = state => {
    return {
        show_login_menu: state.glb.show_login_menu
	};
};

const mapDispatchToProps = dispatch => {
    return {
		showLoginMenu: () =>  dispatch(actionCreators.showLoginMenu())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginNavbar);
