import React, {Component} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../common/PageTitle";
import UserDetails from "./profile-components/UserDetails";
import UserAccountDetails from "./profile-components/UserAccountDetails";

class Profile extends Component {
	
	render() {	
	
		return (
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4">
					<PageTitle title="Your Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
				</Row>
				<Row>
					<Col lg="4">
						<UserDetails />
					</Col>
					<Col lg="8">
						<UserAccountDetails />
					</Col>
				</Row>
			</Container>
		)
	}
	
};

export default Profile;
