import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
const axios = require("axios");

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Users extends Component {
	
	state = {
		mandants_options: {},
		roles_options: {}
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadUserDataAsOptions', {
			table_name: 'total_overview',
			table_name2: 't_dashboard_roles'
		}).then(function (response) {
			self.setState({mandants_options: response.data.total_overview, roles_options: response.data.t_dashboard_roles});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {	
		return (
			<Container fluid className="main-content-container px-4">
				
				<TableGrid 
					table_name="t_dashboard_users" 
					key_column="id"
					table_title="Users" 
					detail_title="Show Detail" 
					table_subtitle="Users Management" 
					table_columns={
						{
							id: 'ID',
							username: 'Username',
							email: 'Email',
							id_role: 'Role',
							firstname: 'Firstname',
							lastname: 'Lastname'
						}
					}  
					detail_skip_fields={[
						'password'
					]}
					allow_create_new={true}
					new_form_fields={
						{
							username: {
								type: 'varchar',
								label: 'Username',
								value: ''
							},
							firstname: {
								type: 'varchar',
								label: 'Firstname',
								value: ''
							},
							lastname: {
								type: 'varchar',
								label: 'Lastname',
								value: ''
							},
							email: {
								type: 'varchar',
								label: 'Email',
								value: ''
							},
							password: {
								type: 'varchar',
								label: 'Password',
								value: ''
							},
							id_role: {
								type: 'select',
								label: 'User Role',
								options: this.state.roles_options,
								value: 0
							},
							mandants: {
								type: 'checkboxes',
								label: 'Associate mandants',
								options: this.state.mandants_options,
								value: []
							}
						}
					}
					edit_form_fields={
						{
							id: {
								type: 'static',
								label: 'ID',
								value: ''
							},
							username: {
								type: 'varchar',
								label: 'Username',
								value: ''
							},
							firstname: {
								type: 'varchar',
								label: 'Firstname',
								value: ''
							},
							lastname: {
								type: 'varchar',
								label: 'Lastname',
								value: ''
							},
							email: {
								type: 'varchar',
								label: 'Email',
								value: ''
							},
							password: {
								type: 'varchar',
								label: 'Password',
								value: ''
							},
							id_role: {
								type: 'select',
								label: 'User Role',
								options: this.state.roles_options,
								value: 0
							},
							mandants: {
								type: 'checkboxes',
								label: 'Associate mandants',
								options: this.state.mandants_options,
								value: []
							}
						}
					}
					order_columns={true} 
					column_filters={true} 
					action_columns={["V","E","D"]} 
					per_page={30} 
					table_theme="bg-dark" 
				/>
								
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
