import React, {Component} from "react";
import { Container, Row, Card, Col, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class NewOrder extends Component {
	
	state = {
		user_mandants: this.props.user_mandants,
		products: [],
		qtys: [],
		loading_data: false,
		items_data: null,
		shipping: '',
		new_form_fields:{
			firstname: {
				type: 'varchar',
				label: 'Firstname',
				value: ''
			},
			lastname: {
				type: 'varchar',
				label: 'Lastname',
				value: ''
			},
			company: {
				type: 'varchar',
				label: 'Company',
				value: ''
			},
			street: {
				type: 'varchar',
				label: 'Street',
				value: ''
			},
			number: {
				type: 'varchar',
				label: 'Number',
				value: ''
			},
			zip: {
				type: 'varchar',
				label: 'Zip',
				value: ''
			},
			country: {
				type: 'varchar',
				label: 'Country',
				value: ''
			}
		}
	}

	receivedData() {
        // Load data
		if(this.state.items_data === null) {	
			let self = this;
			axios.post(apiUrl + '/loadItemsNewOrder', {
                user_mandants: self.state.user_mandants
			}).then(function (response) {
				self.setState({items_data: response.data, loading_data: true});
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}

	componentDidMount() {
		// Load data
		this.receivedData()
	}
	
	addProduct() {
		this.setState({
			products: [...this.state.products, ""],
			qtys: [...this.state.qtys, ""]
		}
		);
	}

	handleChange(e, index) {
		if(e.target.attributes.type && e.target.attributes.type.nodeValue === 'text'){
			this.state.qtys[index] = e.target.value;
		} else {
			this.state.products[index] = e.target.value;
		}
		
		this.setState({
			qtys: this.state.qtys,
			products: this.state.products
		})
	}

	handleRemove(index) {
		this.state.products.splice(index, 1);
		this.state.qtys.splice(index, 1);
		//console.log(this.state.products);
		this.setState({
			products: this.state.products,
			qtys: this.state.qtys
		})
		console.log(this.state.products);
		console.log(this.state.qtys);
	}

	saveItem = () => {
		// Send save request
		let self = this;
		axios.post(apiUrl + '/saveNewOrder', {
			table_name: 't_new_orders',
			data: JSON.stringify(this.state.new_form_fields),
			products: this.state.products,
			qtys: this.state.qtys,
			shipping: this.state.shipping
		}).then(function (response) {
			// window.location.reload(false);
			console.log('Success');
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	updateNewForm = (e) => {
		if(e.target.attributes.type.nodeValue === 'text' || e.target.attributes.type.nodeValue === 'password' || e.target.attributes.type.nodeValue === 'select') {
			let new_form_fields = this.state.new_form_fields;
			new_form_fields[e.target.attributes.name.nodeValue]['value'] = e.target.value;
			this.setState({
				new_form_fields: new_form_fields
			});
		}
		
	}

	updateShipping = (e) => {
		if(e.target.attributes.type.nodeValue === 'select') {
			let shipping = this.state.shipping
			shipping = e.target.value;
			this.setState({
				shipping: shipping
			},() => console.log(this.state.shipping),);
		}
	}

	render() {	
		let newFormFields = [];
		let i = 1; 
		for (var prop in this.state.new_form_fields) {
			if (Object.prototype.hasOwnProperty.call(this.state.new_form_fields, prop)) {
				let field = this.state.new_form_fields[prop];
				newFormFields.push(
					<tr key={"row-"+i++}>
						<th className="form-label">
							{field.label}:
						</th>
						<td className="form-value">
							{((field.type === 'varchar') ?
								<input type="text" name={prop} value={this.state.new_form_fields[prop]['value']} onChange={e => this.updateNewForm(e)} placeholder={field.label} />
							: null)}
							
						</td>
					</tr>
				)
			}
		}
		return (
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4">
					<PageTitle title="New Order" subtitle="New order form widget" />
				</Row>
				<Row>
					<Col>
						<Card small>
							<CardBody>
							<form method="post" action="#" id="new-item-form">
								<table id="" className="new-form">
									<tbody>	
										{newFormFields}
									</tbody>
								</table>
								<table>
									<tbody>
										<tr>
											<th>Products</th>
										</tr>
										{this.state.products.map((product, index)=> {
											return (
												<tr className={"product-" + index} key={index}>
													<td>
														<FormSelect onChange={e => this.handleChange(e,index)}>
															<option value="">-- Please select option --</option>	
																{this.state.items_data.map((row, index) => {
																	return (
																		<option key={"sku-"+index} value={row.itmref}>
																				{row.sku} - {row.name}
																		</option>
																	);
																})}
														</FormSelect>
													</td>
													<td>
														<FormInput type="text" name="qty" onChange={e => this.handleChange(e,index)} placeholder="Qty" />
													</td>
													<td>
														<span onClick={(e) => this.handleRemove(index)} className="delete-icon"><i className="material-icons">delete</i></span>
													</td>
												</tr>
											)
										})}  
										<tr>
											<td>
												<br />
												<span className="btn btn-outline-success" onClick={(e)=>this.addProduct(e)}>[+] Add product</span>
											</td>
										</tr>	
									</tbody>
								</table>
								<table>
									<tbody>
										<tr>
											<th>
											<br />
											Shipping method
											</th>
										</tr>
										<tr className="" key="">
											<td>
												<FormSelect className="select-option" onChange={e => this.updateShipping(e)} type="select">
													<option value="">-- Please select option --</option>
													<option value="default_carrier">Default carrier</option>
													<option value="pickup">pickup</option>
												</FormSelect>
											</td>
										</tr>
										<tr>
											<td colSpan="2">
												<br />
												<Button onClick={() => this.saveItem()} theme="success">Save</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		)
	}
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
