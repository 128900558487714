import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import Chart from "chart.js";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class OpenShipments extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		openshipments_data: null,
	}
	
	componentDidMount() {
	}
	
	render() {	
						
		return (
			<Container fluid className="main-content-container inventory-page">
					
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Open Orders" subtitle="Open Orders" />
				</Row>
				
				<Row noGutters className="inventory-header py-3 px-2">
					<Container fluid className="col-sm-12">
						<TableGrid 
							table_name="t_open_orders_grid" 
							key_column="order_id"
							table_title="Open Orders" 
							detail_title="Open Orders" 
							table_subtitle="Open Orders" 
							table_columns={
								{
									order_id: 'ID', 
									delivery_id: 'Delivery ID',
									status: 'Status',	
									customer_name: 'Customer Name',
									created_datetime_order: 'Created At',
									order_amount_with_taxes_order_currency: 'Price',
									sum_ek_price: 'EK cost',
									currency: 'Currency',
									note_on_delivery: 'Note on delivery'
								}
							} 
							order_column="created_datetime_order"
							order_dir="asc"
							no_title
							order_columns={true} 
							column_filters={true} 
							action_columns={["V"]} 
							allow_create_new={false} 
							csv_export={true}
							user_mandants={this.props.user_mandants}
							per_page={20} 
							table_theme="bg-dark" 
						/>
					
					</Container>
				</Row>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenShipments);
