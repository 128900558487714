import React, {Component} from "react";
import { Container, Row, Card, Col, CardBody } from "shards-react";
import PageTitle from "../common/PageTitle";
import TableGrid from "../table-grid/TableGrid";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import DatePicker from "react-datepicker";


class SkuRevenueReport extends Component {
	handleChange (e) {
		let dateFormat =  e.getFullYear() + '-' + (("0"+(e.getMonth()+1)).slice(-2));
		console.log(dateFormat);
	}
	render() {	
		var dateObj = new Date();
		let test = dateObj.getFullYear() + '-' + (("0"+(dateObj.getMonth()+1)).slice(-2));
		console.log(test);

		var newdate = test;
		return (
			
			<Container fluid className="main-content-container px-4">
				<TableGrid 
					table_name="invoice_revenue_bypositions_range_f"
					special_param={new Date().getFullYear() + '-' + new Date().getMonth()}
					special_param2={new Date().getFullYear() + '-' + new Date().getMonth()+1}
					key_column="SKU"
					table_title="SKU revenue report" 
					detail_title="SKU revenue report" 
					table_subtitle="SKU revenue report" 
					table_columns={
						{
							SKU: 'SKU',
							Name: 'Name',
							Month: 'Month',
							customer_name: 'Customer Name',
							order_number: 'Order Number',
							NumSales: 'Num Sales',
							Final_Revenue: 'Revenue'
						}
					} 
					order_column="NumSales"
					order_dir="desc"
					column_filters={true} 
					action_columns={[]} 
					csv_export={true}
					allow_create_new={false} 
					user_mandants={this.props.user_mandants}
					per_page={30} 
					table_theme="bg-dark" 
				/>
				
			</Container>
			
        )
	}
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuRevenueReport);