import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Orders extends Component {

	state = {
		active_tab: "orders"
	}
	
	changeView = (e, tab) => {
		this.setState({
			active_tab: tab
		});
	}
	
	render() {	
		return (
			<Container fluid className="main-content-container inventory-page">
				<Row noGutters className="page-header py-4 px-2">
					<PageTitle title="Orders" subtitle="Shop orders" />
				</Row>
			
				<Row noGutters className="page-header py-0 px-2 orders-table">
					<table className="table mb-0">
						<thead className={this.props.table_theme}>
							<tr>
								<td className="table-cell-noborder" colspan="99999">
									{((this.state.active_tab == 'orders') ?
									
										<TableGrid 
											id="delivery_data"
											key="delivery_data"
											table_name="orders" 
											key_column="order_id"
											table_title="Orders" 
											detail_title="Order detail" 
											table_subtitle="Customer orders" 
											table_columns={
												{
													order_id: 'ID', 
													customer_name: 'Customer Name',
													order_date_created: 'Created At',
													order_amount_with_taxes_order_currency: 'Price',
													order_currency: 'Currency'
												}
											} 
											default_filters={[]}
											order_column="order_date_created"
											order_dir="desc"
											no_title
											group={true}
											group_by="order_id"
											order_columns={true} 
											csv_export={true}
											column_filters={true} 
											action_columns={["V"]} 
											allow_create_new={false} 
											per_page={20} 
											user_mandants={this.props.user_mandants}
											table_theme="bg-dark"
										/>
										
									: ((this.state.active_tab == 'returns') ?
										
									
										<TableGrid 
											id="t_return_data_grid"
											key="t_return_data_grid"
											table_name="t_return_data_grid" 
											key_column="return_id"
											table_title="Returns" 
											detail_title="Return detail" 
											table_subtitle="Returned items" 
											table_columns={
												{
													return_id: 'ID',
													order_id: 'Order ID',
													return_reason_desc: 'Return Info',
													created_date: 'Created At',
													price: 'Price',
													currency: 'Currency'
												}
											} 
											no_title
											order_columns={true} 
											column_filters={true} 
											action_columns={["V"]} 
											allow_create_new={false} 
											csv_export={true}
											user_mandants={this.props.user_mandants}
											per_page={20} 
											table_theme="bg-dark" 
										/>
										
									: ((this.state.active_tab == 'backorders') ?
				
										<TableGrid 
											id="t_sperr_status_grid"
											key="t_sperr_status_grid"
											table_name="t_sperr_status_grid" 
											key_column="order_id"
											table_title="Backorders" 
											detail_title="Backorder detail" 
											table_subtitle="Backorders items" 
											table_columns={
												{
													order_id: 'ID',
													order_zsperrsta_info: 'Info',
													order_date_created: 'Created At'
												}
											} 
											no_title
											order_columns={true} 
											column_filters={true} 
											action_columns={["V"]} 
											allow_create_new={false} 
											csv_export={true}
											user_mandants={this.props.user_mandants}
											per_page={20} 
											table_theme="bg-dark" 
										/>
										
									: ((this.state.active_tab == 'buyings') ?
				
										<TableGrid 
											id="t_purchase_order_grid"
											key="t_purchase_order_grid"
											table_name="t_purchase_order_grid" 
											key_column="order_id"
											table_title="Buyings" 
											detail_title="Buying detail" 
											table_subtitle="Ordered items" 
											table_columns={
												{
													order_id: 'Order ID',
													order_date: 'Order Date',
													supplier_name: 'Supplier',
													skus: 'Ordered SKUs'
												}
											} 
											no_title
											order_columns={true} 
											column_filters={true} 
											action_columns={["V"]} 
											allow_create_new={false} 
											csv_export={true}
											user_mandants={this.props.user_mandants}
											per_page={30} 
											table_theme="bg-dark" 
										/>
										
									: ''))))}
									
								</td>
							</tr>
						</thead>
					</table>
				</Row>		
					
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
