import React, {Component} from "react";
import {Container, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Button, Row, Col, Form, FormGroup, FormInput, FormTextarea, Alert, NavLink} from "shards-react";
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Login extends Component {
	
	state = {
		username: '',
        password: '',
		usernameValidation: true,
		passwordValidation: true,
		showLoginErrorMessage: false,
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
	}
	
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  this.loginAction(e);
		}
	}
		
	loginAction = (e) => {		
		/* Fields validation */
		if(this.state.username === '' || this.state.password === '') {
			let usenameFill = true;
			if(this.state.username === '') {
				usenameFill = false;
			}
			
			let passwordFill = true;
			if(this.state.password === '') {
				passwordFill = false;
			}
			this.setState({usernameValidation: usenameFill, passwordValidation: passwordFill});
		} else {
			
			this.props.showLoading();
			e.preventDefault();
			let self = this;
			
			axios.post(apiUrl + '/login', {
					username: self.state.username,
					password: self.state.password
				}
			).then(function (response) {				
				if(response.data.error !== undefined) {
					self.props.showLoginError();
					self.props.hideLoading();
				} else {
					self.props.processLogin(response.data, false);
					self.props.hideLoginError();
					self.props.hideLoading();
				}
			}).catch(function (error) {
				console.log('ERROR:');
				console.log(error);
			});
		}
	}
	
	render() {		
		
        return (
			((this.props.user_id !== 0) ?
				<Redirect to="/dashboard" />
			: 
				<Container fluid className="main-content-container px-4">
					
					{((this.props.show_login_error) ?
						<Row>
							<Col lg="12" md="12" sm="12" className="mb-12">
								&nbsp;
							</Col>
							<Col lg="12" md="12" sm="12" className="mb-12">
								<Alert theme="danger">
									Wrong username or password.
								</Alert>
							</Col>
						</Row>
					: null)}
					
					<Row>
						<Col lg={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} sm={{ size: 6, offset: 3 }} className="mb-4">
							<div className="login-container">
								<Container className="container-logos">
									<div className="performance-logo"></div>
									<div className="digital-logo"></div>
									<div className="knowhow-logo"></div>
								</Container>
								<Card small className="login-form">
									<CardHeader>
										<h3 className="m-0">Login</h3>
									</CardHeader>
								
									<CardBody className="d-flex flex-column">
										<div className="quick-post-form">
											<FormGroup>
												<label htmlFor="#username">Username:</label>
												{((this.state.usernameValidation) ?
													<FormInput id="#username" onChange={e => this.handleChange(e)} name="username" value={this.state.username} />
												: 
													<FormInput id="#username" invalid onChange={e => this.handleChange(e)} name="username" value={this.state.username} />
												)}
											</FormGroup>

											<FormGroup>
												<label htmlFor="#password">Password:</label>
												{((this.state.passwordValidation) ?
													<FormInput id="#password" type="password" onChange={e => this.handleChange(e)} name="password" value={this.state.password} onKeyDown={this._handleKeyDown} />
												:
													<FormInput id="#password" invalid type="password" onChange={e => this.handleChange(e)} name="password" value={this.state.password} onKeyDown={this._handleKeyDown} />
												)}
											</FormGroup>
											<Container className="px-0">														
												<Button theme="accent" type="submit" onClick={this.loginAction}>
													Login
												</Button>
												
												<NavLink className="forgot-password" tag={Link} to={"/forgot-password"}>
													Forgot password
												</NavLink>
												
											</Container>
										</div>
									</CardBody>
								
								</Card>
							</div>
						</Col>  
					</Row>
				
				</Container>
			)
		);
	}
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        show_login_error: state.glb.show_login_error
    };
};

const mapDispatchToProps = dispatch => {
    return {
		login: (loginData) =>  dispatch(actionCreators.login(loginData)),
		processLogin: (response) =>  dispatch(actionCreators.processLogin(response, false)),
		showLoading: () =>  dispatch(actionCreators.showLoading()),
		showLoginError: () =>  dispatch(actionCreators.showLoginError()),
		hideLoading: () =>  dispatch(actionCreators.hideLoading()),
		hideLoginError: () =>  dispatch(actionCreators.hideLoginError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
