import * as actionTypes from './actionTypes';
const axios = require("axios");

/* Show login menu */

export const showLoginMenu = () => {
	return {
		type: actionTypes.SHOW_LOGIN_MENU,
	}
}

/* Show loading */

export const showLoading = () => {
	return {
		type: actionTypes.SHOW_LOADING,
	}
}


/* Hide loading */

export const hideLoading = () => {
	return {
		type: actionTypes.HIDE_LOADING,
	}
}


/* Show login error */

export const showLoginError = () => {
	return {
		type: actionTypes.SHOW_LOGIN_ERROR,
	}
}


/* Hide login error */

export const hideLoginError = () => {
	return {
		type: actionTypes.HIDE_LOGIN_ERROR,
	}
}

/* Login */

export const login = (params) => {
    return dispatch => {
        axios.post(params.apiUrl + '/login', {
				username: params.username,
				password: params.password
			}
		).then(function (response) {
			if(response.data.error !== undefined) {
				dispatch(hideLoading());
			} else {
				dispatch(processLogin(response.data, false));
			}
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}	
}

export const setMandant = (user_mandants) => {
	console.log("test");
	console.log(user_mandants);
	return {
		type: actionTypes.SET_MANDANT,
		user_mandants: user_mandants,
	}
}

export const processLogin = (response, login_failed) => {
	return {
		type: actionTypes.LOGIN,
		user_data: response,
		user_mandants: response.user_mandants,
		login_failed: login_failed
	}
}

/* Logout */

export const logout = () => {
	return {
		type: actionTypes.LOGOUT
	}
}

/* Update account */

export const updateAccount = (params) => {
    return dispatch => {
        axios.post(params.apiUrl + '/update-account', {
				username: params.username,
				firstname: params.firstname,
				lastname: params.lastname,
				email: params.email,
				user_id: params.user_id,
				password: params.password,
			}
		).then(function (response) {
			if(response.data.error !== undefined) {
				dispatch(processUpdate());
			} else {
				dispatch(processUpdate(response.data));
			}
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}	
}

export const processUpdate = (user_data) => {
	return {
		type: actionTypes.UPDATE_ACCOUNT,
		user_data: user_data
	}
}

/* Load orders */

export const loadOrders = (params) => {
    return dispatch => {
        axios.post(params.apiUrl + '/orders', {
				company: params.user_data.company
			}
		).then(function (response) {
			console.log(response);
			dispatch(processOrders(response.data));
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}	
}

export const processOrders = (orders) => {
	return {
		type: actionTypes.LOAD_ORDERS,
		orders: orders
	}
}

/* Load Users & Roles */

export const loadUsersRoles = (params) => {
    return dispatch => {
        axios.post(params.apiUrl + '/usersRoles', {}
		).then(function (response) {
			dispatch(processUsersRoles(response.data));
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}	
}

export const processUsersRoles = (data) => {
	return {
		type: actionTypes.LOAD_USERS_ROLES,
		data: data
	}
}

/* Load grid data */
export const loadGridData = (params) => {
    return dispatch => {
        axios.post(params.apiUrl + '/loadData', {
			table_name: params.tableName
		}).then(function (response) {
			dispatch(processGridData(response.data));
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}	
}

export const processGridData = (data) => {
	return {
		type: actionTypes.LOAD_GRID_DATA,
		data: data
	}
}
