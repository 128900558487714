import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user_id: 0,
	user_data: null,
	user_mandants: null,
	login_failed: false, 
	usersRolesData: null,
	grid_data: null,
	users: null,
	roles: null,
	orders: null,
	show_loading: false,
	show_login_menu: false,
	show_login_error: false,
	show_inventory_header: false
};

const globals = (state = initialState, action) => {
    let date = new Date();
    
	switch (action.type) {
		
		case actionTypes.SHOW_LOGIN_MENU:
			return {
				...state,
				show_login_menu: !state.show_login_menu				
			}
		
		case actionTypes.SHOW_LOADING:
			return {
				...state,
				show_loading: true				
			}
		
		case actionTypes.SHOW_LOGIN_ERROR:
			return {
				...state,
				show_login_error: true				
			}
		
		case actionTypes.HIDE_LOGIN_ERROR:
			return {
				...state,
				show_login_error: false				
			}
		
		case actionTypes.HIDE_LOADING:
			return {
				...state,
				show_loading: false				
			}
		
		case actionTypes.LOGIN:
		
			let user_id = 0;
			if(!action.login_failed) {
				user_id = action.user_data.user_data.id;
			}
		
			return {
				...state,
				user_id: user_id,
				user_data: action.user_data,
				user_mandants: action.user_mandants,
				login_failed: action.login_failed,
				show_loading: false
			}
		
		case actionTypes.SET_MANDANT:
			return {
				...state,
				user_mandants: action.user_mandants
			}
			
		case actionTypes.LOGOUT:
			return {
				...state,
				user_id: 0,
				user_data: null,
				login_failed: false				
			}
		
		case actionTypes.UPDATE_ACCOUNT:
			return {
				...state,
				user_id: action.user_data.id,
				user_data: action.user_data,
				show_loading: false			
			}
			
		case actionTypes.LOAD_ORDERS:
			console.log('actionTypes.LOAD_ORDERS');
			console.log(action);
			return {
				...state,
				orders: action.orders
			}
			
		case actionTypes.LOAD_USERS_ROLES:
			return {
				...state,
				usersRolesData: action.data,
				show_loading: false
			}
			
		case actionTypes.LOAD_GRID_DATA:
			return {
				...state,
				grid_data: action.data,
				show_loading: false
			}
			
        default:
            return {
                ...state
            }
    }
}

export default globals;