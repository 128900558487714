import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, FormSelect, FormTextarea, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class UserAccountDetails extends Component {
	state = {
		username: this.props.user_data.user_data.username,
		firstname: this.props.user_data.user_data.firstname,
		lastname: this.props.user_data.user_data.lastname,
		email: this.props.user_data.user_data.email,
		password: ''
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
	
	updateAccount = (e) => {
		// Show loading
		this.props.showLoading();
		
		// Do account update in BigQuery
		e.preventDefault();
		this.props.updateAccount({
			apiUrl: apiUrl,
			username: this.state.username,
			firstname: this.state.firstname,
			lastname: this.state.lastname,
			email: this.state.email,
			user_id: this.props.user_data.user_data.id,
			password: this.state.password,
		});
	}
		
	render() {
		
		let user_data = this.props.user_data.user_data;
		
		return (
			
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">Account Details</h6>
				</CardHeader>
				
				<ListGroup flush>
					<ListGroupItem className="p-3">
						<Row>
							<Col>
								<Form>
									<Row form>
									
										{/* Username */}
										<Col md="12" className="form-group">
											<label htmlFor="feUsername">Username</label>
											<FormInput id="feUsername" name="username" value={this.state.username} onChange={e => this.handleChange(e)} />
										</Col>
									</Row>
										
									<Row form>
									
										{/* First Name */}
										<Col md="6" className="form-group">
											<label htmlFor="feFirstName">First Name</label>
											<FormInput id="feFirstName" name="firstname" placeholder="First Name" value={this.state.firstname} onChange={e => this.handleChange(e)} />
										</Col>
									
										{/* Last Name */}
										<Col md="6" className="form-group">
											<label htmlFor="feLastName">Last Name</label>
											<FormInput id="feLastName" name="lastname" placeholder="Last Name" value={this.state.lastname}  onChange={e => this.handleChange(e)} />
										</Col>
									</Row>
									
									<Row form>
									
										{/* Email */}
										<Col md="6" className="form-group">
											<label htmlFor="feEmail">Email</label>
											<FormInput id="feEmail" name="email" placeholder="Email" value={this.state.email}  onChange={e => this.handleChange(e)} />
										</Col>
									
										{/* Password */}
										<Col md="6" className="form-group">
											<label htmlFor="fePassword">Password</label>
											<FormInput id="fePassword" name="password" type="password" placeholder="Password" value={this.state.password} onChange={e => this.handleChange(e)} />
										</Col>
									</Row>
									
									<Button theme="success" onClick={this.updateAccount}>Update Account</Button>
								</Form>
							</Col>
						</Row>
					</ListGroupItem>
				</ListGroup>
			</Card>
		);
	}

}

const mapStateToProps = state => {
    return {
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		updateAccount: (accountData) =>  dispatch(actionCreators.updateAccount(accountData)),
		showLoading: () =>  dispatch(actionCreators.showLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountDetails);