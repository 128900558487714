import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect, FormInput } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class ReturnForm extends Component {

	state = {
		user_mandants: this.props.user_mandants
	}
	
	render() {	
		
		return (
			
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4">
					<PageTitle title="Return Forms" subtitle="Return form widget" />
				</Row>
								
				<TableGrid 
					table_name="t_return_forms" 
					key_column="id" 
					no_title
					table_columns={
						{
							id: 'ID',
							name: 'Name',
							title: 'Title',
							subtitle: 'Subtitle',
							days: 'Days'
						}
					}  
					detail_skip_fields={[
						'code',
						'id_user'
					]}
					allow_create_new={true}
					new_form_fields={
						{
							name: {
								type: 'varchar',
								label: 'Form name',
								comment: '(for your easier identification)',
								value: ''
							},
							title: {
								type: 'varchar',
								label: 'Form title',
								value: ''
							},
							subtitle: {
								type: 'varchar',
								label: 'Form subtitle',
								value: ''
							},
							days: {
								type: 'varchar',
								label: 'Return days',
								comment: '(number of days for which is possible return order)',
								value: ''
							},
							form_style: {
								type: 'custom',
								label: 'Form style',
								value: ''
							},
							form_preview: {
								type: 'custom',
								label: 'Form preview',
								value: ''
							}
						}
					}
					edit_form_fields={
						{
							id: {
								type: 'static',
								label: 'ID',
								value: ''
							},
							name: {
								type: 'varchar',
								label: 'Name',
								value: ''
							},
							title: {
								type: 'varchar',
								label: 'Firstname',
								value: ''
							},
							subtitle: {
								type: 'varchar',
								label: 'Subtitle',
								value: ''
							},
							days: {
								type: 'varchar',
								label: 'Days',
								value: ''
							}
						}
					}
					order_columns={true} 
					column_filters={true} 
					action_columns={["V","E","D"]} 
					id_user={this.props.user_id}
					per_page={30} 
					table_theme="bg-dark" 
				/>
				
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnForm);
