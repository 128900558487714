import React, {Component} from "react";
import PropTypes from "prop-types";
import { Container, Card, CardBody, CardFooter, CardHeader, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, FormSelect, FormTextarea, Button, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import queryString from 'query-string'
import * as actionCreators from '../../../store/actions/index';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class ForgotPassword extends Component {
	state = {
		email: ''
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
	
	forgotPassword = () => {
		// Send request
		let self = this;
		axios.post(apiUrl + '/forgotPassword', {
			email: this.state.email
		}).then(function (response) {
			window.location = '?request=sent';
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {		
		let params = queryString.parse(this.props.location.search);
		
        return (
			<Container fluid className="main-content-container px-4">
								
				<Row>
					<Col lg={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} sm={{ size: 6, offset: 3 }} className="mb-4">
						<div className="login-container forgot-password-page">
						<Container className="container-logos">
							<div className="performance-logo"></div>
							<div className="digital-logo"></div>
							<div className="knowhow-logo"></div>
						</Container>
							<Card small className="login-form">
																	
								<CardBody className="d-flex flex-column">
									<h3 className="m-0 py-4">Forgot Password</h3>
							
									{((params.request) ?
										<div className="success-message py-2">
											<Button theme="success">Request sent. If given email exist in our system, you will get message with new password</Button>
											<br />
											<br />
										</div>
									: null)}
									
									<Form className="quick-post-form">
										<FormGroup>
											<label htmlFor="#email">Email:</label>
											<FormInput id="#email" onChange={e => this.handleChange(e)} name="email" value={this.state.email} />
										</FormGroup>
									</Form>
								</CardBody>
							
								<CardFooter small>
									<Button theme="accent" type="submit" onClick={this.forgotPassword}>
										Submit
									</Button>
									<NavLink className="forgot-password" tag={Link} to={"/"}>
										Back to login
									</NavLink>
									<br />
									<br />
								</CardFooter>
							</Card>
						</div>
					</Col>  
				</Row>
			
			</Container>
		);
	}
};

const mapStateToProps = state => {
    return {
        login_failed: state.glb.login_failed
    };
};

const mapDispatchToProps = dispatch => {
    return {
		
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
