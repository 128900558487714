import React, {Component} from "react";
import { Container, Row, Card, Col, CardBody, FormSelect } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import DatePicker from "react-datepicker";
import Pagination from "../components-overview/Pagination";

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;


class RevenueReport extends Component {
	
    state = {
        revenueReportData_data: null, 
		fetchingsRevenueReportData:true,
        dateFilter: 30,
		dateFilterFrom: '',
		dateFilterTo: '',
		customDateFilter: false,
        showdetail: false,
        fetchingDetail: true,
        revenueOrdersData_data: null,
        per_page: 20,
		page: 1,
		current_page: 1,
        type_detail: '',
        vat_type: '',
	}

    componentDidMount() {
        /* Self object */
		let self = this;
        if(self.state.revenueReportData_data === null) {
            axios.post(apiUrl + '/revenueReportData', {
                user_mandants: this.props.user_mandants,
                date_filter: self.state.dateFilter,
                date_filter_from: self.state.dateFilterFrom,
                date_filter_to: self.state.dateFilterTo,
                current_year:self.state.currentYear,
            }).then(function (response) {
                self.setState({revenueReportData_data: response.data, fetchingsRevenueReportData:false});
            }).catch(function (error) {
                console.log('ERROR:');
                console.log(error);
            });
        }
    }

    exportExcel() {
        console.log('test13');
        let self = this;
        axios.post(apiUrl + '/revenueReportData', {
            user_mandants: this.props.user_mandants,
            date_filter: self.state.dateFilter,
            date_filter_from: self.state.dateFilterFrom,
            date_filter_to: self.state.dateFilterTo,
            current_year:self.state.currentYear,
            csv_export: 1
        }).then(function (response) {
            console.log(response);
           window.location.replace(apiUrl + '/' + response.data);
        }).catch(function (error) {
            console.log('ERROR:');
            console.log(error);
        });
    }    

    updateFilterRange(e) {
		if(e.target.value == '0') {
			this.setState({dateFilter: e.target.value, customDateFilter: true});
		} else {
			this.setState({dateFilter: e.target.value, customDateFilter: false, dateFilterFrom: "", dateFilterTo: ""}, this.updateDashboardData);
		}
	}

    updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({dateFilterFrom: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		} else {
			this.setState({dateFilterTo: dateFormat},() => {
				if(this.state.dateFilterFrom !== '' && this.state.dateFilterTo !== '') {
					this.updateDashboardData()
				}
			});
		}
	}

    updateDashboardData() {		
		/* Start fetching data */
		this.setState({revenueReportData_data: null, fetchingsRevenueReportData:true,});	
		
		/* Self object */
		let self = this;
		/* Avg carrier data */
        axios.post(apiUrl + '/revenueReportData', {
			user_mandants: this.props.user_mandants,
			date_filter: self.state.dateFilter,
			date_filter_from: self.state.dateFilterFrom,
			date_filter_to: self.state.dateFilterTo
		}).then(function (response) {
			self.setState({revenueReportData_data: response.data, fetchingsRevenueReportData:false});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
    }
    hideDetail() {
        let self = this;
        self.setState({type_detail:'',showdetail: false,revenueOrdersData_data:null, current_page: 1,page: 1, fetchingDetail:true,vat_type: '' });
    }
    showOrdersDetail(type, vat) {
        let self = this;
        self.setState({showdetail: true, type_detail: 'orders', vat_type:vat['vat_id_total']});
        console.log(vat);
        axios.post(apiUrl + '/revenueOrdersReportData', {
            user_mandants: this.props.user_mandants,
            date_filter: self.state.dateFilter,
            date_filter_from: self.state.dateFilterFrom,
            date_filter_to: self.state.dateFilterTo,
            vat: vat['vat_id_total'],
            type: type,
            current_page: self.state.current_page,
            per_page: self.state.per_page,
        }).then(function (response) {
            self.setState({revenueOrdersData_data: response.data, fetchingDetail:false});
        }).catch(function (error) {
            console.log('ERROR:');
            console.log(error);
        });
    }
    exportDetailExcel(vat) {
        console.log('test13');
        let self = this;
        axios.post(apiUrl + '/revenueOrdersReportData', {
            user_mandants: this.props.user_mandants,
            date_filter: self.state.dateFilter,
            date_filter_from: self.state.dateFilterFrom,
            date_filter_to: self.state.dateFilterTo,
            vat: vat,
            current_page: self.state.current_page,
            per_page: self.state.per_page,
            csv_export: 1
        }).then(function (response) {
            console.log(response);
           window.location.replace(apiUrl + '/' + response.data);
        }).catch(function (error) {
            console.log('ERROR:');
            console.log(error);
        });
    } 
    onPageOrdersChanged(data,type, vat) {
        console.log(data.currentPage);
		const { currentPage } = data.currentPage;
			this.setState({page: currentPage, revenueOrdersData_data: null, fetchingDetail:true}, () => {
				// Send request
				let self = this;
                console.log(data.currentPage);
				axios.post(apiUrl + '/revenueOrdersReportData', {
					user_mandants: this.props.user_mandants,
                    date_filter: self.state.dateFilter,
                    date_filter_from: self.state.dateFilterFrom,
                    date_filter_to: self.state.dateFilterTo,
                    vat: vat,
                    type: type,
                    current_page: data.currentPage,
                    per_page: self.state.per_page,
				}).then(function (response) {
					self.setState({page:data.currentPage,revenueOrdersData_data: response.data,fetchingDetail:false});
				}).catch(function (error) {
					console.log('ERROR:');
					console.log(error);
				});
			})
	  }

	render() {	
		
			return (
                this.state.showdetail ? 
                <Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Revenue report detail" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                                <CardBody>
                                    <Row>
                                        <Container fluid className="col-sm-12 px-2 pb-4 container revenue-reports revenue-reports-detail">
                                            <Row>
                                            <Container className="col-sm-11 carrier-crosstable pl-1 ml-4 mt-2 container-fluid" style={{marginLeft: 0}}>
                                            <Col class="col-lg-3">
                                                 <a href="#" title="Export to Excel" onClick={() => this.exportDetailExcel(this.state.vat_type)} className="clear-filters export-excel"><i className="material-icons">table_chart</i> Export to Excel</a>	
                                                </Col>
                                            <div className="table-grid-detail-actions">
                                                <a href="javascript://" onClick={() => this.hideDetail()}><i className="material-icons">clear</i></a>
                                            </div>
                                            {((this.state.fetchingDetail && this.state.revenueOrdersData_data == null) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
                                            <table width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Order ID</th>
                                                        <th>Invoice ID</th>
                                                        <th>Invoice date</th>
                                                        <th>Tax level</th>
                                                        <th>Tax amount</th>
                                                        <th>Gross invoice amount</th>
                                                        <th>Net invoice amount</th>
                                                        <th>Return revenue</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    Object.entries(this.state.revenueOrdersData_data.data).map(([key,value],i) =>
                                                    <tr>
                                                        <td>{value['orderid']}</td>
                                                        <td>{value['invoiceid']}</td>
                                                        <td>{value['invoice_date']}</td>
                                                        <td>{value['tax']}</td>
                                                        <td>{value['tax_amount']}</td>
                                                        <td>{value['gross_invoice_amount']}</td>
                                                        <td>{value['net_invoice_amount']}</td>
                                                        <td>{value['return_revenue_net']}</td>
                                                    </tr> 
                                                    ) 
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan="1000">
                                                            <div>Total rows: <strong>{this.state.revenueOrdersData_data.total_count}</strong> / Page: <strong>{this.state.page}</strong> </div>
                                                            <div className="d-flex flex-row py-4 align-items-center table-grid-pagination-right">
                                                                <Pagination currentPage={this.state.page} totalRecords={this.state.revenueOrdersData_data.total_count} pageLimit='20' pageNeighbours={1} onPageChanged={e => this.onPageOrdersChanged(e,'order_revenue_gross',this.state.vat_type)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            )}
                                        </Container>
                                            </Row>
                                        </Container>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Revenue report" />
                    </Row>
                    <Row>
                        <Col>
                            <Card small>
                                <CardBody>
                                        <Row>
									        <Container fluid className="col-sm-12 px-2 pb-4 container revenue-reports">
                                            <Row noGutters className="page-header py-4 px-2 flex items-center">
                                                <Col className="col-lg-4">
                                                    <Row noGutters className="py-3">
                                                        <Container fluid className="col-sm-4 px-1">
                                                            <FormSelect onChange={e => this.updateFilterRange(e)}>
                                                                <option value="30">Last 30 days</option>
                                                                <option value="7">Last 7 days</option>
                                                                <option value="0">Custom...</option>
                                                            </FormSelect>
                                                        </Container>
                                                        <Container fluid className="col-sm-4 px-1">
                                                            {((this.state.customDateFilter) ?
                                                                <DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                            :
                                                                <DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.dateFilterFrom} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
                                                            )}
                                                        </Container>
                                                        <Container fluid className="col-sm-4 px-1">
                                                            {((this.state.customDateFilter) ?
                                                                <DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                            :
                                                                <DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.dateFilterTo} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
                                                            )}
                                                        </Container>
                                                    </Row>
                                                </Col>
                                                <Col class="col-lg-3">
                                                 <a href="#" title="Export to Excel" onClick={() => this.exportExcel()} className="clear-filters export-excel"><i className="material-icons">table_chart</i> Export to Excel</a>	
                                                </Col>
                                            </Row>
										        <Row>
                                                    <Container className="col-sm-11 carrier-crosstable pl-1 ml-4 mt-2 container-fluid" style={{marginLeft: 0}}>
                                                    {((this.state.fetchingsRevenueReportData) ? <div className="dashboard-filter-totals lightui1-shimmer"><div className="grid-spinner"></div></div> : 
												        <table width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Facility</th>
                                                                    <th>Revenue net</th>
                                                                    <th>Revenue gross</th>
                                                                    <th>Tax revenue</th>
                                                                    <th>Currency</th>
                                                                    <th>Country</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
	
	
																Object.entries(this.state.revenueReportData_data.data).map(([key,value],i) =>
																	<tr>
                                                                        <td className="search-icon" onClick={e => this.showOrdersDetail('order_revenue_net',value)}><i class="material-icons">search</i></td>
																		<td>{value['facility_name']}</td>
                                                                        <td>{value['revenue_net']}</td>
                                                                        <td>{value['revenue_gross']}</td>
                                                                        <td>{value['tax_revenue']}</td>
                                                                        <td>{value['currency']}</td>
                                                                        <td>{value['country']}</td>
																	</tr>
																) 
	
																}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                    </Container>
                                                </Row>
                                            </Container>
                                        </Row>
                                </CardBody>
							</Card>					
						</Col>
					</Row>
				</Container>
            )
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueReport);