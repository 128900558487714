import React, {Component} from "react";
import { Container, Row, Col, Button, FormInput, DatePicker } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
const axios = require("axios");

const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class ReceiveGoodsForm extends Component {
	
	state = {
		user_mandants: this.props.user_mandants,
		new_form_fields:{
			supplier: {
				type: 'varchar',
				label: 'Supplier',
				value: ''
			},
			orderedskus: {
				type: 'varchar',
				label: 'Order Skus',
				value: ''
			},
			orderedqty: {
				type: 'varchar',
				label: 'Ordered Qty',
				value: ''
			},
			expectedreceiving: {
				type: 'varchar',
				label: 'Expected Receiving',
				value: ''
			}
		}
    }
    
    updateNewForm = (e) => {
		if(e.target.attributes.type.nodeValue === 'text' || e.target.attributes.type.nodeValue === 'password' || e.target.attributes.type.nodeValue === 'select') {
			let new_form_fields = this.state.new_form_fields;
			new_form_fields[e.target.attributes.name.nodeValue]['value'] = e.target.value;
			this.setState({
				new_form_fields: new_form_fields
			});
		}
		
    }
    
    updateFilterDate = (date, e) => {
        let new_form_fields = this.state.new_form_fields;
		new_form_fields[e] = date;
        this.setState({
            new_form_fields: new_form_fields
        },() => console.log(this.state.new_form_fields),);
    }

    focusElement = (e) => {
		document.getElementById(e).click()
	}

	saveInfo() {
		// Send save request
		let self = this;
		axios.post(apiUrl + '/saveReceivingGoods', {
			table_name: 't_receiving_goods',
			data: JSON.stringify(this.state.new_form_fields),
			user_mandants: self.state.user_mandants
		}).then(function (response) {
			window.location.reload(false);
			console.log('Success');
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}
	
	render() {	
        let newFormFields = [];
		let i = 1; 
		for (var prop in this.state.new_form_fields) {
			if (Object.prototype.hasOwnProperty.call(this.state.new_form_fields, prop)) {
				let field = this.state.new_form_fields[prop];
				newFormFields.push(
                    (field.label === "Expected Receiving") ?
                    <tr key={"row-"+i++}>
                        <th className="form-label">
							{field.label}
						</th>
							<td>
								<div className="column-filter receivegoods-form">
								<DatePicker id="receiving" data_column={"receiving"} data_column_type="datetime" dateFormat="dd.MM.yyyy" className="filter-from" placeholderText={field.label} selected={this.state.new_form_fields["receiving"]} onChange={e => this.updateFilterDate(e, "receiving")} />
                            	<a href="javascript://" onClick={e => this.focusElement("receiving")} className="datetime-icon"><i className="material-icons">date_range</i></a>
								</div>
							</td>
					</tr> :
					(field.type === 'varchar') ? 
					<tr key={"row-"+i++}>
						<th className="form-label">
							{field.label}:
						</th>
						<td className="form-value">
							{((field.type === 'varchar') ?
								<FormInput type="text" name={prop} value={this.state.new_form_fields[prop]['value']} onChange={e => this.updateNewForm(e)} placeholder={field.label} />
							: null)}
							
						</td>
					</tr>
					: null
				)
			}
        }
		return (
			<Container fluid className="main-content-container px-4">
                <table>
                    <tbody>
						{newFormFields}
						<tr>
							<td colSpan="2">
								<br />
								<Button onClick={() => this.saveInfo()} theme="success">Save</Button>
							</td>
						</tr>
                    </tbody>
                </table>
			</Container>
		)
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveGoodsForm);
