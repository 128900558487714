import React, {Component} from "react";
import { Container, Row, Col, Card, CardHeader, CardFooter, CardBody, Button } from "shards-react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PageTitle from "../../components/common/PageTitle";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';

const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;
const superadminPassword = process.env.REACT_APP_SUPERADMIN_PASSWORD;

class Settings extends Component {
	
	recreateTables = (e) => {
		
		// Run update
		confirmAlert({
			title: 'Are you sure?',
			message: 'After click on YES you will not be able to stop this operation. Do you really want to do this?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						// Show loading
						this.props.showLoading();
						let self = this;
						
						axios.post(apiUrl + '/setupTables', {
							password: superadminPassword,
						}).then(function (response) {
							// Hide loading
							self.props.hideLoading();
						}).catch(function (error) {
							console.log(error);
						});
					}
				},
				{
				  label: 'No',
				  onClick: () => {}
				}
			]
		});
	}
	
	render() {		
	
        return (
		
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
				  <PageTitle sm="4" title="Setup tables" subtitle="Re-create tables" className="text-sm-left" />
				</Row>

				{/* Default Light Table */}
				<Row>
					<Col>
						<Card small className="mb-4">
							<CardHeader className="border-bottom">
								<h6 className="m-0">
									Re-create core tables and fill them with test data 
								</h6>
							</CardHeader>
							<CardBody className="p-0 pb-3">
								<div className="card-body">
									Please make sure you really want to do it. All tables with be re-create again and data in them deleted.
									<br />
									<br />
									<Button theme="danger" onClick={this.recreateTables}>Reset core tables</Button>
								</div>
							</CardBody>
							<CardFooter></CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		)
		
	}	
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
		showLoading: () =>  dispatch(actionCreators.showLoading()),
		hideLoading: () =>  dispatch(actionCreators.hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
