import React, {Component} from "react";
import { Container, Row, FormSelect, DatePicker, CardHeader, CardBody, Button } from "shards-react";
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import TableGrid from "../../components/table-grid/TableGrid";
const axios = require("axios");
const apiUrl = process.env.REACT_APP_DASHBOARD_API_URL;

class Stock extends Component {

	state = {
		user_mandants: this.props.user_mandants,
		movements_data: null,
		movements_mins_filter_data: null,
		movements_plus_filter_data: null,
		movements_minus_filter: 30,
		movements_minus_filter_from: "",
		movements_minus_filter_to: "",
		movements_plus_filter: 30,
		movements_plus_filter_from: "",
		movements_plus_filter_to: "",
		custom_date_filter: false,
	}
	
	componentDidMount() {
		let self = this;
		axios.post(apiUrl + '/loadStockMovementsHeaderData', {
			user_mandants: self.state.user_mandants,
		}).then(function (response) {
			self.setState({movements_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	updateMovementsMinus = () => {
		let self = this;
		axios.post(apiUrl + '/loadStockMovementsMinusHeaderData', {
			user_mandants: self.state.user_mandants,
			movements_minus_filter: self.state.movements_minus_filter,
			movements_minus_filter_from: self.state.movements_minus_filter_from,
			movements_minus_filter_to: self.state.movements_minus_filter_to,
		}).then(function (response) {
			self.setState({movements_mins_filter_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	updateMovementsPlus = () => {
		let self = this;
		axios.post(apiUrl + '/loadStockMovementsPlusHeaderData', {
			user_mandants: self.state.user_mandants,
			movements_plus_filter: self.state.movements_plus_filter,
			movements_plus_filter_from: self.state.movements_plus_filter_from,
			movements_plus_filter_to: self.state.movements_plus_filter_to,
		}).then(function (response) {
			self.setState({movements_plus_filter_data: response.data});
		}).catch(function (error) {
			console.log('ERROR:');
			console.log(error);
		});
	}

	updateFilterRange(e) {
		if(e.target.value === '0') {
			this.setState({movements_minus_filter: e.target.value, custom_date_filter: true}, this.updateMovementsMinus);
		} else {
			this.setState({movements_minus_filter: e.target.value, custom_date_filter: false, movements_minus_filter_from: "", movements_minus_filter_to: ""}, this.updateMovementsMinus);
		}
	}

	updateFilterPlusRange(e) {
		if(e.target.value === '0') {
			this.setState({movements_plus_filter: e.target.value, custom_date_filter: true}, this.updateMovementsPlus);
		} else {
			this.setState({movements_plus_filter: e.target.value, custom_date_filter: false, movements_plus_filter_from: "", movements_plus_filter_to: ""}, this.updateMovementsPlus);
		}
	}

	updateFilterDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({movements_minus_filter_from: dateFormat}, this.updateMovementsMinus);
		} else {
			this.setState({movements_minus_filter_to: dateFormat}, this.updateMovementsMinus);
		}
	}

	updateFilterPlusDate(e, elem) {
		let dateFormat = e.getDate() + "." + (e.getMonth() + 1) + "." + e.getFullYear();
		if(elem === 'filter-from') {
			this.setState({movements_plus_filter_from: dateFormat}, this.updateMovementsPlus);
		} else {
			this.setState({movements_plus_filter_to: dateFormat}, this.updateMovementsPlus);
		}
	}
	
	render() {	
	
		if(this.state.movements_data === null) {
			
			return (
				<Container fluid className="main-content-container px-4">
					<Row noGutters className="page-header py-4">
						<PageTitle title="Stock Movements" subtitle="Products stock history" />
						<div className="clearfix"></div>
						<div className="grid-spinner"></div>
					</Row>
				</Container>
			)
			
		} else {
			
			let topMovementsMinus = this.state.movements_mins_filter_data ? ((this.state.movements_mins_filter_data.movement_items_minus) ? this.state.movements_mins_filter_data.movement_items_minus.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-8 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-4 top-5-products-item-qty px-2 minus-label">-{item.minus}</Container>
					</Row>
				</Container>;
			})  : <div className="nodata">No Data</div>)  : this.state.movements_data.movement_items_minus.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-8 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-4 top-5-products-item-qty px-2 minus-label">-{item.minus}</Container>
					</Row>
				</Container>;
			});
			
			let topMovementsPlus = this.state.movements_plus_filter_data ? ((this.state.movements_plus_filter_data.movement_items_plus) ? this.state.movements_plus_filter_data.movement_items_plus.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
				<Row noGutters>
					<Container fluid className="col-sm-8 top-5-products-item-name px-2">{item.name}</Container>
					<Container fluid className="col-sm-4 top-5-products-item-qty px-2">+{item.plus}</Container>
				</Row>
			</Container>;
			})  : <div className="nodata">No Data</div>)  : this.state.movements_data.movement_items_plus.map(function(item, i){
				return <Container noGutters className="top-5-products-item">
					<Row noGutters>
						<Container fluid className="col-sm-8 top-5-products-item-name px-2">{item.name}</Container>
						<Container fluid className="col-sm-4 top-5-products-item-qty px-2">+{item.plus}</Container>
					</Row>
				</Container>;
			});
			
			return (
				<Container fluid className="main-content-container inventory-page">
					
					<Row noGutters className="page-header py-4 px-2">
						<PageTitle title="Stock Movements" subtitle="Products stock history" />
					</Row>
					
					{((this.state.movements_data !== null) ? 
						<Row noGutters className="inventory-header stock-movements-header">
							<Container fluid className="col-sm-12 px-2">
								<Row noGutters className="inventory-header-block">
									
									<Container fluid className="col-sm-4 px-4">
										<h4>Top outgoing item:</h4>
										<Row noGutters className="total-inventory-value py-2">
											{((this.state.movements_mins_filter_data)?<Button outline theme="danger">
											{this.state.movements_mins_filter_data.movement_minus_item}
											<br />
											-{this.state.movements_mins_filter_data.movement_minus_item_qty}
										</Button> : <Button outline theme="danger">
											{this.state.movements_data.movement_minus_item}
											<br />
											-{this.state.movements_data.movement_minus_item_qty}
										</Button>)}
											
										</Row>
										
										<br />
										
										<h4>Top incoming item:</h4>
										<Row noGutters className="total-inventory-value py-2">
										{((this.state.movements_plus_filter_data)?
											<Button outline theme="success">
												{this.state.movements_plus_filter_data.movement_plus_item}
												<br />
												+{this.state.movements_plus_filter_data.movement_plus_item_qty}
											</Button> : <Button outline theme="success">
											{this.state.movements_data.movement_plus_item}
											<br />
											+{this.state.movements_data.movement_plus_item_qty}
										</Button> )}
										</Row>
									</Container>
									
									<Container fluid className="col-sm-4 px-4">
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<h4>Top outgoing goods:</h4>
											</Container>
										</Row>
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<Row noGutters>
													<Container fluid className="col-sm-4 px-1">
														<FormSelect onChange={e => this.updateFilterRange(e)}>
														<option value="30">Last 30 days</option>
														<option value="7">Last week</option>
														<option value="365">Last year</option>
														<option value="0">Custom...</option>
														</FormSelect>
													</Container>
													<Container fluid className="col-sm-4 px-1">
														{((this.state.custom_date_filter) ?
															<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.movements_minus_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
														:
															<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.movements_minus_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterDate(e, 'filter-from')} />
														)}
													</Container>
													<Container fluid className="col-sm-4 px-1">
														{((this.state.custom_date_filter) ?
															<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.movements_minus_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
														:
															<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.movements_minus_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterDate(e, 'filter-to')} /> 
														)}
													</Container>
												</Row>
											</Container>
										</Row>
										<Row noGutters className="top-5-products">
											{topMovementsMinus}
										</Row>
									</Container>
									
									<Container fluid className="col-sm-4 px-4">
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<h4>Top incoming goods:</h4>
											</Container>
										</Row>
										<Row noGutters>
											<Container fluid className="col-sm-12">
												<Row noGutters>
													<Container fluid className="col-sm-4 px-1">
														<FormSelect onChange={e => this.updateFilterPlusRange(e)}>
														<option value="30">Last 30 days</option>
														<option value="7">Last week</option>
														<option value="365">Last year</option>
														<option value="0">Custom...</option>
														</FormSelect>
													</Container>
													<Container fluid className="col-sm-4 px-1">
														{((this.state.custom_date_filter) ?
															<DatePicker id="top-product-from" data_column="top-product-from" data_column_type="datetime" value={this.state.movements_plus_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterPlusDate(e, 'filter-from')} />
														:
															<DatePicker id="top-product-from" disabled data_column="top-product-from" data_column_type="datetime" value={this.state.movements_plus_filter_from} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="From" onChange={e => this.updateFilterPlusDate(e, 'filter-from')} />
														)}
													</Container>
													<Container fluid className="col-sm-4 px-1">
														{((this.state.custom_date_filter) ?
															<DatePicker id="top-product-to" data_column="top-product-to" data_column_type="datetime" value={this.state.movements_plus_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterPlusDate(e, 'filter-to')} /> 
														:
															<DatePicker id="top-product-to" disabled data_column="top-product-to" data_column_type="datetime" value={this.state.movements_plus_filter_to} dateFormat="dd.MM.yyyy" className="mb-2 form-control" placeholderText="To" onChange={e => this.updateFilterPlusDate(e, 'filter-to')} /> 
														)}
													</Container>
												</Row>
											</Container>
										</Row>
										<Row noGutters className="top-5-products">
											{topMovementsPlus}
										</Row>
									</Container>
								</Row>
							</Container>
						</Row>
					: null)}
					
					<Row noGutters className="inventory-header py-3 px-2">
						<Container fluid className="col-sm-12">
					
							<TableGrid 
								table_name="stock_movements" 
								key_column="date"
								table_title="Stock Movements" 
								detail_title="Stock Movements" 
								table_subtitle="Stock Movements" 
								table_columns={
									{
										date: 'Date',
										sku: 'SKU',
										item_description: 'Name',
										qty: 'Qty Move'
									}
								} 
								no_title
								order_columns={true} 
								column_filters={true} 
								action_columns={[]} 
								allow_create_new={false} 
								csv_export={true}
								user_mandants={this.props.user_mandants}
								per_page={30} 
								table_theme="bg-dark" 
							/>
						
						</Container>
					</Row>
					
				</Container>
			)
		
		}
	}
	
};

const mapStateToProps = state => {
    return {
        user_id: state.glb.user_id,
        login_failed: state.glb.login_failed,
        user_data: state.glb.user_data,
		user_mandants: state.glb.user_mandants
    };
};

const mapDispatchToProps = dispatch => {
    return {
		logout: () =>  dispatch(actionCreators.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
